import React from 'react';
import { Alert } from 'reactstrap';
import { DefaultPage as Page } from 'client/components/Pages';
import PageHeader from 'client/components/PageHeader';

export default class DashboardPage extends React.Component {

  render() {
    return (
      <Page>
        <PageHeader title="Dashboard" />
        <Alert color="info">
          <strong>Oops!</strong>{' '}
          There is nothing on this page yet.
        </Alert>
      </Page>
    );
  }

}
