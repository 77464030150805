import React from 'react';
import Helmet from 'react-helmet';
import { SlimMainPanel } from 'client/components/Slims';

export default class AppMainPanel extends React.Component {
  render() {
    return (
      <SlimMainPanel>
        <Helmet>
          <title>STV</title>
        </Helmet>
        <div className="container">
          {this.props.children}
        </div>
      </SlimMainPanel>
    );
  }
}
