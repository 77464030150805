import React from 'react';
import classNames from 'classnames';
import { getPersonPhotoUrl } from 'client/helpers/imageUrls';
import _ from 'lodash';
import ViewPortProgramUserbox from 'client/components/ViewPortProgram/ViewPortProgramUserbox';

export const QualificationBoardUpcomingList = ({
  className,
  valueUnit,
  numberOfPositions,
  table,
}) => (
  <div className={className}>
    {_.zip(_.range(numberOfPositions), table).map(([index, item]) => (
      <ViewPortProgramUserboxSlot
        color="dark"
        key={index}
        item={item}
        valueUnit={valueUnit}
        size="md"
      />
    ))}
  </div>
);

const ViewPortProgramUserboxSlot = ({item, valueUnit, size, color}) => {
  if(!item) return (
    <ViewPortProgramUserbox
      color={color || "dark"}
      size={size}
      displayName=""
      photoUrl={getPersonPhotoUrl()}
      value=""
      valueUnit={valueUnit}
    />
  );
  const { name, personId, personPhoto, value } = item;
  return (
    <ViewPortProgramUserbox
      color={color ? color : (size !== 'xs' ? 'orange' : 'dark')}
      size={size}
      displayName={name || personId}
      photoUrl={getPersonPhotoUrl(personPhoto)}
      value={value}
      valueUnit={valueUnit}
    />
  );
};

export const QualificationBoardGrid = ({
  maxQualifiedPersons,
  valueUnit,
  table = [],
}) => (
  <div className={classNames('ltv_userbox_container', getGridContainerSizeClass(maxQualifiedPersons))}>
    {_.range(maxQualifiedPersons).map(index => (
      <ViewPortProgramUserboxSlot
        key={index}
        item={table[index]}
        valueUnit={valueUnit}
        size={getGridContainerPersonSize(maxQualifiedPersons)}
      />
    ))}
  </div>
);

function getGridContainerPersonSize(maxPersons) {
  switch(true) {
    default: return 'md';
    case maxPersons === 1: return 'lg';
    case maxPersons === 2: return 'md';
    case maxPersons >= 3 && maxPersons <= 8: return 'sm';
    case maxPersons > 8: return 'xs';
  }
}

function getGridContainerSizeClass(maxPersons) {
  switch(true) {
    default: return 'sm';
    case maxPersons === 1: return 'one';
    case maxPersons === 2: return 'two';
    case maxPersons === 3: return 'three';
    case maxPersons > 3 && maxPersons <= 8: return 'md';
    case maxPersons > 8: return 'sm';
  }
}
