import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format } from 'url';
import moment from 'moment';

export default class ProgramEditorPreviewInDataBrowserLinkButton extends React.Component {

  static propTypes = {
    program: propTypes.object.isRequired,
    disabled: propTypes.bool.isRequired,
  }

  getUrl() {
    const { program: { type, settings } } = this.props;
    switch(type) {
      default: return null;
      case 'leaderboard': {
        const { dataType, groupId } = settings;
        switch(dataType) {
          default: return null;
          case 'timeSeries': {
            const { timeSeries = {} } = settings;
            const { range } = timeSeries;
            const pathname = '/data/timeSeries';
            const query = {groupId, group: 'personId'};
            switch(range) {
              default: return null;
              case 'date':
                query.dateStart = timeSeries.dateFrom || '';
                query.dateEnd = timeSeries.dateTo || '';
                break;
              case 'relative': {
                const { offset = 0, period } = timeSeries;
                query.dateStart = moment().subtract(offset, period).startOf(period).format('YYYY-MM-DD');
                query.dateEnd = moment().subtract(offset, period).endOf(period).format('YYYY-MM-DD');
              } break;
            }
            return format({pathname, query});
          }
          case 'board': {
            const pathname = '/data/board';
            const query = {groupId, group: 'personId'};
            return format({pathname, query});
          }
        }
      }
    }
  }

  render() {
    const { disabled } = this.props;
    const url = this.getUrl();
    if(!url) return null;
    return (
      <Link
        disabled={disabled || !url}
        className="btn btn-default"
        to={url}
      >
        Data Browser preview
      </Link>
    );
  }

}
