import React from 'react';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'client/components/Spinners';
import * as photoUploadActions from 'client/redux/accountSettings/photoUploadActions';
import { accountSettingsList } from 'client/redux/accountSettings/settingsActions';
import FileUploadPhotoCard from 'client/components/FileUpload/FileUploadPhotoCard';
import { getAccountPhotoUrl } from 'client/helpers/imageUrls';

class ConnectedSettingsPhotoUpload extends React.Component {

  componentDidMount() {
    return this.props.accountSettingsPhotoRead();
  }

  handleUploaded = (err, body) => {
    this.props.accountSettingsPhotoOnUpload(err, body);
    this.props.accountSettingsList(); // reload global settings
  }

  handleDelete = () => {
    this.props.accountSettingsPhotoDelete().then(() => {
      // reload global settings
      return this.props.accountSettingsList();
    });
  }

  getUploadUrl() {
    return process.env.REACT_APP_URL + '/api/account/settings/photo';
  }

  getPhotoUrl() {
    const { photo } = this.props;
    return photo ? getAccountPhotoUrl(photo) : null;
  }

  render() {
    const { error, isDeleting, isReading } = this.props;
    return (
      <div style={{maxWidth: '300px'}}>
        <SpinnerOverlay spinning={isDeleting || isReading} color="primary" showChildren centerX centerY>
          <FileUploadPhotoCard
            error={error}
            uploadUrl={this.getUploadUrl()}
            onUploaded={this.handleUploaded}
            onDelete={this.handleDelete}
            imageUrl={this.getPhotoUrl()}
            helpText="Please upload a PNG image with a square size"
          />
        </SpinnerOverlay>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountSettings.photoUpload,
});

const actions = {
  ...photoUploadActions,
  accountSettingsList,
};

export default connect(mapStateToProps, actions)(ConnectedSettingsPhotoUpload);
