import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DefaultPage as Page } from 'client/components/Pages';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { SpinnerOverlay } from 'client/components/Spinners';
import * as editActions from 'client/redux/accountProgram/editActions';
import PageHeader from 'client/components/PageHeader';
import { ucFirst } from 'client/helpers/strings';
import ProgramEditor from 'client/components/ProgramEditPage/ProgramEditor';

class ConnectedProgramEditPage extends React.Component {

  getProgramId() {
    return _.get(this.props, 'match.params.programId');
  }

  getPageTitle() {
    const programType = _.get(this.props, 'program.type');
    if(programType) return `${ucFirst(programType)} Program Editor`;
    return 'Program Editor';
  }

  componentDidMount() {
    this.props.accountProgramEditRead(this.getProgramId());
  }

  handleUpdate = (programUpdate) => {
    const programId = this.getProgramId();
    return this.props.accountProgramEditUpdate(programId, programUpdate);
  }

  render() {
    const {
      program,
      readError,
      error,
      isReading,
      isUpdating,
      isDeleting
    } = this.props;
    return (
      <Page>
        <PageHeader title={this.getPageTitle()} />
        <ErrorAlert error={readError} />
        <SpinnerOverlay
          spinning={isReading}
          centerX
          centerY
          color="primary"
          fadeIn="quarter"
        >
          {program && <ProgramEditor
            program={program}
            onUpdate={this.handleUpdate}
            error={error}
            isUpdating={isUpdating}
            isDeleting={isDeleting}
          />}
        </SpinnerOverlay>
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountProgram.edit,
});

export default connect(mapStateToProps, editActions)(ConnectedProgramEditPage);
