import React from 'react';
import { connect } from 'react-redux';
import { DefaultPage as Page } from 'client/components/Pages';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { SpinnerOverlay } from 'client/components/Spinners';
import PageHeader from 'client/components/PageHeader';
import * as indexActions from 'client/redux/accountSettings/indexActions';
import SettingsForm from 'client/components/SettingsIndexPage/SettingsForm';

class ConnectedSettingsIndexPage extends React.Component {

  componentDidMount() {
    this.props.accountSettingsIndexList();
  }

  handleUpdate = (settingsUpdate) => {
    return this.props.accountSettingsIndexUpdate(settingsUpdate);
  }

  render() {
    const {
      settings,
      isListing,
      isUpdating,
      listError,
      error,
    } = this.props;
    return (
      <Page>
        <PageHeader title="Settings" />
        <SpinnerOverlay spinning={isListing} color="primary">
          <ErrorAlert error={listError || error} />
          {settings && <SettingsForm
            initialValues={settings || {}}
            isSubmitting={isUpdating}
            onSubmit={this.handleUpdate}
          />}
        </SpinnerOverlay>
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountSettings.index,
});

const actions = {
  ...indexActions,
};

export default connect(mapStateToProps, actions)(ConnectedSettingsIndexPage);
