import React from 'react';
import AppNavbar from 'client/components/App/AppNavbar';
import ConnectedAppHeader from 'client/components/App/ConnectedAppHeader';
import AppMainPanel from 'client/components/App/AppMainPanel';
import AppFooter from 'client/components/App/AppFooter';
import 'client/vendor/stv-slim/css/slim.css';
import './Pages.css';

export class DefaultPage extends React.Component {
  render() {
    return (
      <div>
        <ConnectedAppHeader />
        <AppNavbar />
        <AppMainPanel>
          {this.props.children}
        </AppMainPanel>
        <AppFooter>
        </AppFooter>
      </div>
    );
  }
}

export class EmptyPage extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        {this.props.children}
      </div>
    );
  }
}

export class SigninPage extends React.Component {
  render() {
    return (
      <div className="signin-wrapper">
        {this.props.children}
      </div>
    );
  }
}
