import * as constants from 'client/redux/accountProgram/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultOneState = {
  isReading: false,
  error: null,
  programs: [],
};

function reduceOne(state = defaultOneState, action) {
  state = reduceByTypes(constants.ACCOUNT_PROGRAM_LIST, state, action, {
    requestProp: 'isReading',
    errorProp: 'error',
    successPickProps: ['programs'],
  });
  return state;
}

export default function(state = {}, action) {

  const reduceOneActions = [
    ...constants.ACCOUNT_PROGRAM_LIST,
  ];

  if(reduceOneActions.includes(action.type)) {
    const { listKey = 'all' } = action.meta;
    return {
      ...state,
      [listKey]: reduceOne(state[listKey], action),
    };
  }

  return state;
};
