import * as actionCreators from 'client/redux/accountChannel/actionCreators';
import * as constants from 'client/redux/accountChannel/constants';

export const scheduleCalendarQueueChannelIndex = channelScheduleCalendarId => dispatch => dispatch(actionCreators.scheduleQueueCalendarIndex(constants.ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CALENDAR_INDEX, channelScheduleCalendarId));

export const scheduleCalendarQueueCreate = scheduleQueueProps => dispatch => dispatch(actionCreators.scheduleQueueCreate(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_CREATE, scheduleQueueProps));

export const scheduleCalendarQueueUpdate = (channelScheduleQueueId, scheduleQueueProps) => dispatch => dispatch(actionCreators.scheduleQueueUpdate(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_UPDATE, channelScheduleQueueId, scheduleQueueProps));

export const scheduleCalendarQueueDelete = (channelScheduleQueueId) => dispatch => dispatch(actionCreators.scheduleQueueDelete(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_DELETE, channelScheduleQueueId));

export const scheduleCalendarQueueMove = (channelScheduleQueueId, direction) => dispatch => dispatch(actionCreators.scheduleQueueMove(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_MOVE, channelScheduleQueueId, direction));

