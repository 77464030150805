import React from 'react';
import moment from 'moment';
import { TimeInSettingsZone } from 'client/components/TimeHelpers';

export function ChannelIntervalDescription({date, interval}) {
  const m = moment(date);
  if(!m.isValid()) return `Every ${interval}`;
  switch(interval) {
    default: return `Every ${interval}`;
    case 'month': return `Every month on the ${m.format('Do')}`;
    case 'week': return `Every ${m.format('dddd')}`;
    case 'day': return `Every day`;
    case 'year': return `Every year on ${m.format('MMM Mo')}`;
  }
};

const FromTo = ({format, from, to}) => (
  <span>
    <TimeInSettingsZone
      time={from}
      format={format}
    />
    {' '}–{' '}
    <TimeInSettingsZone
      time={to}
      format={format}
    />
  </span>
);

export function ChannelCompleteIntervalDescription({from, to, interval}) {
  const mfrom = moment(from);
  const mto = moment(to);
  if(!mfrom.isValid() || !mto.isValid()) return '';
  switch(interval) {
    default: return (
      <FromTo from={from} to={to} format="YYYY-MM-DD HH:mm" />
    );
    case 'year':
    case 'day':
    case 'week':
    case 'month': return (
      <span>
        <ChannelIntervalDescription date={from} interval={interval} />{' '}
        <FromTo from={from} to={to} format="HH:mm" />
      </span>
    );
  }
}
