import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import ProgramNewModal from 'client/components/ProgramIndexPage/ProgramNewModal';

export default class ProgramNew extends React.Component {

  static propTypes = {
    onCreate: propTypes.func.isRequired,
  }

  state = {
    modalIsOpen: false,
  }

  handleToggleModal = ev => {
    this.setState(state => ({modalIsOpen: !state.modalIsOpen}));
  }

  render() {
    const { onCreate, className } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <div className={className}>
        <ProgramNewModal
          isOpen={modalIsOpen}
          toggle={this.handleToggleModal}
          onSubmit={onCreate}
        />
        <Button color="primary" onClick={this.handleToggleModal} size="sm">
          New Program
        </Button>
      </div>
    );
  }

}
