import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import 'ionicons/dist/css/ionicons.css';

export default class Icon extends React.Component {

  static propTypes = {
    icon: propTypes.string.isRequired,
    tag: propTypes.string,
  }

  static defaultProps = {
    style: {},
    tag: 'i',
  }

  render() {
    const { icon, className, style, tag } = this.props;
    const classes = classNames('icon', icon, className);
    return React.createElement(tag, {
      className: classes,
      style,
    });
  }

}
