import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { DefaultPage as Page } from 'client/components/Pages';
import PageHeader from 'client/components/PageHeader';

class ConnectedLogIndexPage extends React.Component {

  render() {
    return (
      <Page>
        <PageHeader title="Log" />
        <Alert color="info">
          <strong>Oops!</strong>{' '}
          There is nothing on this page yet.
        </Alert>
      </Page>
    );
  }

}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {})(ConnectedLogIndexPage);
