import { combineReducers } from 'redux';
import index from 'client/redux/accountSettings/indexReducer';
import settings from 'client/redux/accountSettings/settingsReducer';
import photoUpload from  'client/redux/accountSettings/photoUploadReducer';

const accountSettingsReducer = combineReducers({
  index,
  settings,
  photoUpload,
});

export default accountSettingsReducer;
