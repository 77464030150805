import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ConnectedChannelIndexPage from 'client/components/ChannelIndexPage/ConnectedChannelIndexPage';
import ConnectedProgramIndexPage from 'client/components/ProgramIndexPage/ConnectedProgramIndexPage';
import DashboardPage from 'client/components/DashboardPage/DashboardPage';
import ConnectedTvOutput from 'client/components/TvOutput/ConnectedTvOutput';
import ConnectedProgramPreview from 'client/components/ProgramPreview/ConnectedProgramPreview';
import ConnectedLoginPage from 'client/components/LoginPage/ConnectedLoginPage';
import AuthedRoute from 'client/components/Router/ConnectedAuthedRoute';
import NotFoundPage from 'client/components/ErrorPage/NotFoundPage';
import ConnectedDataPageTimeSeries from 'client/components/DataPage/ConnectedDataPageTimeSeries';
import ConnectedDataPageBoard from 'client/components/DataPage/ConnectedDataPageBoard';
import ConnectedDataPagePerson from 'client/components/DataPage/ConnectedDataPagePerson';
import ConnectedLogIndexPage from 'client/components/LogIndexPage/ConnectedLogIndexPage';
import ConnectedSettingsIndexPage from  'client/components/SettingsIndexPage/ConnectedSettingsIndexPage'
import ConnectedChannelEditPage from 'client/components/ChannelEditPage/ConnectedChannelEditPage';
import ConnectedProgramEditPage from 'client/components/ProgramEditPage/ConnectedProgramEditPage';

export default class RouterRoot extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path="/tv/:channelId" component={ConnectedTvOutput} />
        <Route exact path="/login" component={ConnectedLoginPage} />
        <AuthedRoute exact path="/programs/:programId/preview" component={ConnectedProgramPreview} />
        <AuthedRoute exact path="/" component={DashboardPage} />
        <AuthedRoute exact path="/channels" component={ConnectedChannelIndexPage} />
        <AuthedRoute exact path="/channels/:channelId/:schedulerTab?" component={ConnectedChannelEditPage} />
        <AuthedRoute exact path="/programs" component={ConnectedProgramIndexPage} />
        <AuthedRoute exact path="/programs/:programId" component={ConnectedProgramEditPage} />
        <AuthedRoute exact path="/log" component={ConnectedLogIndexPage} />
        <AuthedRoute exact path="/data/timeSeries" component={ConnectedDataPageTimeSeries} />
        <AuthedRoute exact path="/data/board" component={ConnectedDataPageBoard} />
        <AuthedRoute exact path="/data/persons" component={ConnectedDataPagePerson} />
        <AuthedRoute exact path="/settings" component={ConnectedSettingsIndexPage} />
        <Route exact path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}
