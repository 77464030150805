import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { SpinnerOverlay } from 'client/components/Spinners';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { accountProgramDataReadGroupIds } from 'client/redux/accountProgram/dataActions';

class ConnectedProgramSettingDataGroupIdField extends React.Component {

  static propTypes = {
    dataType: propTypes.string.isRequired,
  }

  getData(dataType) {
    if(!dataType) return;
    return this.props.accountProgramDataReadGroupIds(dataType);
  }

  componentDidMount() {
    const { dataType } = this.props;
    this.getData(dataType);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.dataType !== this.props.dataType) {
      return this.getData(this.props.dataType);
    }
  }

  render() {
    const { allGroupIds, isReading, value } = this.props;
    const inputProps = _.omit(this.props, [
      'value',
      'isReading',
      'dataType',
      'allGroupIds',
      'accountProgramDataReadGroupIds'
    ]);
    return (
      <SpinnerOverlay spinning={isReading} color="primary" showChildren>
        <Input
          type="select"
          value={value || ''}
          {...inputProps}
        >
          <option value="" disabled>Select a group ID</option>
          {(allGroupIds || []).map(groupId => (
            <option key={groupId} value={groupId}>{groupId}</option>
          ))}
        </Input>
      </SpinnerOverlay>
    );
  }

}

const mapStateToProps = state => ({
  ..._.pick(_.get(state, 'accountProgram.data.groupIds', {}), 'allGroupIds', 'error', 'isReading'),
});

const actions = {
  accountProgramDataReadGroupIds,
};

export default connect(mapStateToProps, actions)(ConnectedProgramSettingDataGroupIdField);
