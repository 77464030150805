import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { getProgramGalleryImageUrl } from 'client/helpers/imageUrls';
import './ViewPortProgramGallery.css';

export default class ViewPortProgramGallery extends React.Component {

  static propTypes = {
    image: propTypes.string,
    imageSize: propTypes.string,
    title: propTypes.string,
    showTitle: propTypes.bool,
    transitionState: propTypes.string,
  }

  render() {
    const {
      image,
      imageSize,
      showTitle,
      title,
      transitionState,
    } = this.props;

    const divStyle = {
      backgroundImage: `url(${getProgramGalleryImageUrl(image)})`,
      backgroundSize: imageSize,
    };
    return (
      <div className="viewport-program-gallery">
        {image && (
          <div className={classNames('viewport-program-gallery-image', transitionState)} style={divStyle}>
            {showTitle && title && (
              <header>
                <h1>{title}</h1>
              </header>
            )}
          </div>
        )}
      </div>
    );
  }
}

