import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { Formik, Form } from 'formik';
import { SaveButton } from 'client/components/Buttons';
import ProgramSettingsForm from 'client/components/ProgramSetting/ProgramSettingsForm';
import ProgramForm from 'client/components/ProgramSetting/ProgramForm';
import ProgramEditorPreviewInDataBrowserLinkButton from 'client/components/ProgramEditPage/ProgramEditorPreviewInDataBrowserLinkButton';

export default class ProgramEditor extends React.Component {

  static propTypes = {
    program: propTypes.object.isRequired,
    error: propTypes.object,
    onUpdate: propTypes.func.isRequired,
    isUpdating: propTypes.bool.isRequired,
    isDeleting: propTypes.bool.isRequired,
  }

  getProgramTitle() {
    const title = _.get(this.props, 'program.title');
    const subTitle = _.get(this.props, 'program.settings.subTitle');
    return `${title} - ${subTitle}`;
  }

  render() {
    const { error, program, onUpdate, isUpdating } = this.props;
    return (
      <Formik initialValues={program} onSubmit={onUpdate} enableReinitialize>
        {formikProps => (
          <Form>
            <Card>
              <CardHeader className="tx-medium bd-0 tx-white bg-primary">
                {program.title}
              </CardHeader>
              <CardBody>
                <ErrorAlert error={error} />
                <ProgramForm
                  formikProps={formikProps}
                />
                <hr />
                <ProgramSettingsForm
                  programType={program.type}
                  formikProps={formikProps}
                />
              </CardBody>
              <CardFooter className="bd-t d-flex justify-content-end">
                <div>
                  <Link className="btn btn-default" to={`/programs/${program.id}/preview`}>
                    Viewport preview
                  </Link>
                  <ProgramEditorPreviewInDataBrowserLinkButton
                    program={program}
                    disabled={Object.keys(formikProps.touched).length > 0}
                  />
                  <SaveButton
                    size="sm"
                    isSaving={isUpdating}
                    disabled={!formikProps.dirty}
                  />
                </div>
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}
