import { getApiActionTypes } from 'client/redux/apiHelpers';

export const ACCOUNT_CHANNEL_INDEX_DELETE = getApiActionTypes('ACCOUNT_CHANNEL_INDEX_DELETE');
export const ACCOUNT_CHANNEL_INDEX_CREATE = getApiActionTypes('ACCOUNT_CHANNEL_INDEX_CREATE');
export const ACCOUNT_CHANNEL_INDEX_LIST = getApiActionTypes('ACCOUNT_CHANNEL_INDEX_LIST');

export const ACCOUNT_CHANNEL_EDIT_READ = getApiActionTypes('ACCOUNT_CHANNEL_EDIT_READ');
export const ACCOUNT_CHANNEL_EDIT_UPDATE = getApiActionTypes('ACCOUNT_CHANNEL_EDIT_UPDATE');

export const ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CHANNEL_INDEX = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CHANNEL_INDEX');
export const ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CREATE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CREATE');
export const ACCOUNT_CHANNEL_SCHEDULE_QUEUE_UPDATE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_QUEUE_UPDATE');
export const ACCOUNT_CHANNEL_SCHEDULE_QUEUE_DELETE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_QUEUE_DELETE');
export const ACCOUNT_CHANNEL_SCHEDULE_QUEUE_MOVE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_QUEUE_MOVE');

export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_INDEX = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_INDEX');
export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_CREATE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_CREATE');
export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_UPDATE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_UPDATE');
export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_DELETE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_DELETE');

export const ACCOUNT_CHANNEL_SCHEDULE_PREVIEW_READ = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_PREVIEW_READ');
export const ACCOUNT_CHANNEL_SCHEDULE_PREVIEW_SET_FILTER = 'ACCOUNT_CHANNEL_SCHEDULE_PREVIEW_SET_FILTER';

export const ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CALENDAR_INDEX = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CALENDAR_INDEX');
export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_CREATE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_CREATE');
export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_UPDATE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_UPDATE');
export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_DELETE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_DELETE');
export const ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_MOVE = getApiActionTypes('ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_MOVE');
