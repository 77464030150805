import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as listActions from 'client/redux/accountData/listActions';
import { SpinnerOverlay } from 'client/components/Spinners';

const mapStateToPersonListProp = state => ({
  persons: _.get(state, 'accountData.list.personIds', []).map(id => ({id})),
});

class DataPersonListLoader extends React.Component {

  componentDidMount() {
    this.props.accountDataListPersonIds();
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const ConnectedDataPersonListLoader = connect(null, listActions)(DataPersonListLoader);

class DataPersonListLoaderSpinner extends React.Component {

  render() {
    const { isLoadingPersonIds, children } = this.props;
    return (
      <SpinnerOverlay spinning={isLoadingPersonIds} color="primary" showChildren>
        <ConnectedDataPersonListLoader>
          {children}
        </ConnectedDataPersonListLoader>
      </SpinnerOverlay>
    );
  }

}

export const ConnectedDataPersonListLoaderSpinner = connect(state => state.accountData.list)(DataPersonListLoaderSpinner);

class DataPersonListConsumer extends React.Component {

  static propTypes = {
    children: propTypes.func.isRequired,
    persons: propTypes.array,
  }

  static defaultProps = {
    persons: [],
  }

  render() {
    const { persons, children } = this.props;
    return children(persons);
  }

}

export const ConnectedDataPersonListConsumer = connect(mapStateToPersonListProp)(DataPersonListConsumer);
