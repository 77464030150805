export default function createWebsocketMiddleware(socket) {

  return store => {
    const { dispatch } = store;

    const emitOnConnect = {};

    socket.on('connect', () => {
      dispatch({type: 'WEBSOCKET_CONNECTED'});
      for(let emitId in emitOnConnect) {
        const { socketEventName, args, onAckEventName } = emitOnConnect[emitId];
        dispatch({type: 'WEBSOCKET_EMIT', socketEventName, args, onAckEventName});
      }
    });

    socket.on('error', error => {
      dispatch({type: 'WEBSOCKET_ERROR', error});
    });

    socket.on('connect_error', error => {
      dispatch({type: 'WEBSOCKET_CONNECT_ERROR', error});
    });

    socket.on('reconnect_attempt', () => {
      dispatch({type: 'WEBSOCKET_RECONNECT_ATTEMPT'});
    });

    // socket.on('reconnect', () => {
    //   dispatch({type: 'WEBSOCKET_RECONNECTED'});
    // });

    socket.on('disconnect', () => {
      dispatch({type: 'WEBSOCKET_DISCONNECTED'});
    });

    return next => action => {

      switch(action.type) {
        default: return next(action);

        case 'WEBSOCKET_ADD_EMIT_ON_CONNECT': {
          const { emitId, socketEventName, onAckEventName, args } = action;
          emitOnConnect[emitId] = { socketEventName, onAckEventName, args };
          if(socket.connected) dispatch({type: 'WEBSOCKET_EMIT', socketEventName, onAckEventName, args});
          return next(action);
        }

        case 'WEBSOCKET_REMOVE_EMIT_ON_CONNECT': {
          const { emitId } = action;
          delete emitOnConnect[emitId];
          return next(action);
        }

        case 'WEBSOCKET_EMIT': {
          const { socketEventName, onAckEventName, args } = action;
          const ackFn = (...args) => {
            dispatch({type: onAckEventName, socketEventName, args});
          };
          const emitArgs = onAckEventName ? [...args, ackFn] : [...args];
          socket.emit(socketEventName, ...emitArgs);
          return next(action);
        }

        // subscribe to a websocket event
        case 'WEBSOCKET_SUBSCRIBE': {
          const { socketEventName, onMessageEventName } = action;
          socket.on(socketEventName, (...args) => {
            dispatch({type: onMessageEventName, socketEventName, args});
          });
          return next(action);
        }

        // unsubscribe from a websocket event
        case 'WEBSOCKET_UNSUBSCRIBE': {
          const { socketEventName } = action;
          socket.off(socketEventName);
          return next(action);
        }
      }
    };
  };
}
