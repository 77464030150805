import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledAlert as Alert } from 'reactstrap';
import { flashSeen } from 'client/redux/flashAlert/actions';

class ConnectedFlashAlert extends Component {

  state = {
    alert: null
  }

  setAlertState(alert) {
    this.setState({alert}, () => {
      this.props.flashSeen();
    });
  }

  componentDidMount() {
    if(this.props.alert) {
      this.setAlertState(this.props.alert);
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.alert) {
      this.setAlertState(newProps.alert);
    }
  }

  render() {
    const alert = this.state.alert;
    if(!alert) return null;
    return (
      <div>
        <Alert color={alert.color}>
          {alert.strong && (<strong>{alert.strong}{' '}</strong>)}
          {alert.msg}
        </Alert>
      </div>
    );
  }

}

export default connect(state => state.flashAlert, { flashSeen })(ConnectedFlashAlert);
