import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CardImg, CardFooter, CardBody, Card, Table } from 'reactstrap';
import { DefaultPage as Page } from 'client/components/Pages';
import PageHeader from 'client/components/PageHeader';
import { SpinnerOverlay } from 'client/components/Spinners';
import * as personsActions from 'client/redux/accountData/personsActions';
import { getPersonPhotoUrl } from 'client/helpers/imageUrls';
import { ErrorAlert } from 'client/components/ErrorHelpers';

class ConnectedDataPagePerson extends React.Component {

  constructor(props) {
    super(props);
    this.fetchData = _.debounce(query => {
      return this.props.accountDataListPersons(query);
    }, 100);
  }

  componentDidMount() {
    const { query } = this.props;
    return this.fetchData(query);
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props;
    if(!_.isEqual(prevProps.query, query)) {
      return this.fetchData(query);
    }
  }

  // formats the table data
  getTableData() {
    const { persons = [] } = this.props;
    return persons.map(({...rest}) => ({
      ...rest,
      key: [rest.id, rest.groupId, rest.personId].join('_'),
    }));
  }

  render() {
    const {
      isLoading,
      error,
    } = this.props;

    const tableData = this.getTableData();

    return (
      <Page>
        <PageHeader title="Person Data" />
        <Card>
          <CardBody>
            <ErrorAlert error={error} />
            <SpinnerOverlay
              spinning={isLoading}
              color="primary"
              fadeIn="quarter"
              showChildren
              centerX
              centerY
            >
              <Table bordered className="mb-0">
                <thead className="bg-primary thead-colored">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Hidden?</th>
                    <th>Photo</th>
                  </tr>
                </thead>
                <tbody>
                  {!tableData.length && (
                    <tr>
                      <td colSpan="4">Nothing to show</td>
                    </tr>
                  )}
                  {tableData.map(row => (
                    <tr key={row.key}>
                      <td>
                        {row.id}
                      </td>
                      <td>
                        {row.name}
                      </td>
                      <td>
                        {row.isHidden ? 'Yes' : 'No'}
                      </td>
                      <td>
                        <Card style={{maxWidth: '200px'}}>
                          <CardImg
                            top
                            width="100%"
                            src={getPersonPhotoUrl(row.photo)}
                            alt={row.id}
                          />
                          <CardFooter className="bd-t tx-center">
                            {row.photo || '<none>'}
                          </CardFooter>
                        </Card>
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SpinnerOverlay>
          </CardBody>
        </Card>
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountData.persons,
});

export default connect(mapStateToProps, personsActions)(ConnectedDataPagePerson);
