import _ from 'lodash';
import { internalError, authError, forbiddenError } from 'client/redux/appActions';

const apiErrorInterceptor = store => dispatch => action => {
  if(action.error && action.payload instanceof Error) {
    const payload = action.payload;
    switch(payload.name) {
      default: return dispatch(action);
      case 'RequestError':
      case 'InvalidRSAA':
        return internalError(payload)(dispatch);
      case 'ApiError':
        if(isOnTV(store)) {
          return dispatch(action);
        }
        switch(payload.status) {
          default: return dispatch(action);
          case 403: return forbiddenError(payload)(dispatch);
          case 401: return authError(payload)(dispatch);
        }
    }
  }
  return dispatch(action);
};

export default apiErrorInterceptor;

function isOnTV(store) {
  const state = store.getState();
  const pathname = _.get(state, 'router.location.pathname', '');
  return pathname.startsWith('/tv');
}
