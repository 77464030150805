import React from 'react';
import propTypes from 'prop-types';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { ProgramDescription } from 'client/components/Program/ProgramHelpers';
import { SaveButton } from 'client/components/Buttons';
import { handleSubmitPromise } from 'client/helpers/formik';
import { ConnectedProgramListConsumer } from 'client/components/Program/ProgramListProviders';
import {
  FormGroup,
  FormText,
  Input ,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Formik, Form } from 'formik';

export default class ChannelSchedulerQueueFormModal extends React.Component {

  static propTypes = {
    isNew: propTypes.bool.isRequired,
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
  }

  static defaultProps = {
    isNew: false,
  }

  handleSubmit = (values, formikProps) => {
    handleSubmitPromise(this.props.onSubmit(values), formikProps).then(action => {
      this.props.toggle();
    }).catch(err => {
      formikProps.setStatus(err);
    });
  }

  render() {
    const { isNew, isOpen, toggle, initialValues } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{width: '500px'}}>
        <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
          {({handleBlur, handleChange, setFieldValue, values, isSubmitting, isValid, status}) => (
            <Form>
              {isNew && <ModalHeader toggle={toggle}>New Program Queue</ModalHeader>}
              {!isNew && <ModalHeader toggle={toggle}>Edit Program Queue</ModalHeader>}
              <ModalBody>
                {status instanceof Error && <ErrorAlert error={status} />}
                <FormGroup>
                  <Label for="channelScheduleQueueDurationInSeconds">Duration (sec)</Label>
                  <Input
                    type="number"
                    name="durationInSeconds"
                    id="channelScheduleQueueDurationInSeconds"
                    value={values.durationInSeconds || 0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="0"
                    required
                  />
                  <FormText color="muted">
                    Enter a value equal to or above 0
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="channelScheduleQueueProgramId">Program</Label>
                  <Input
                    type="select"
                    name="programId"
                    id="channelScheduleQueueProgramId"
                    value={values.programId || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Select program"
                    required
                  >
                    <option value="" disabled>Select a program</option>
                    <ConnectedProgramListConsumer>
                      {programs => programs.map(program => (
                        <option key={program.id} value={program.id}>
                          <ProgramDescription {...program} />
                        </option>
                      ))}
                    </ConnectedProgramListConsumer>
                  </Input>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <SaveButton
                  isSaving={isSubmitting}
                  type="submit"
                  disabled={!isValid}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }

}
