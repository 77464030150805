import * as constants from 'client/redux/accountSettings/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  error: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_SETTINGS_LIST, state, action, {
    requestProp: null,
    errorProp: 'error',
    successPickProps: ['settings', 'photo'],
  });

  // updating or reading settings also updates this stored object
  state = reduceByTypes(constants.ACCOUNT_SETTINGS_INDEX_LIST, state, action, {
    requestProp: null,
    errorProp: 'error',
    successPickProps: ['settings', 'photo'],
  });

  // updating or reading settings also updates this stored object
  state = reduceByTypes(constants.ACCOUNT_SETTINGS_INDEX_UPDATE, state, action, {
    requestProp: null,
    errorProp: null,
    successPickProps: ['settings', 'photo'],
  });

  return state;
};
