import * as constants from 'client/redux/accountData/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isLoadingPersonIds: false,
  errorPersonIds: null,
};

export default function(state = defaultState, action) {
  state = reduceByTypes(constants.ACCOUNT_DATA_LIST_PERSON_IDS, state, action, {
    requestProp: 'isLoadingPersonIds',
    errorProp: 'errorPersonIds',
    successPickProps: ['personIds'],
  });
  return state;
};
