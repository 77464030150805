import React from 'react';
import './ProgramPreviewControllers.css';
import propTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  ButtonGroup,
} from 'reactstrap';

const TransitionButton = props => {
  const { onChangeTransition, transitionState, setTransitionState } = props;
  const className = classNames('btn btn-primary', transitionState === setTransitionState ? 'active' : '');
  return (
    <Button
      className={className}
      type="button"
      onClick={() => onChangeTransition(setTransitionState)}
    >
      {setTransitionState}
    </Button>
  );
};

export class ProgramPreviewTransitionStateController extends React.Component {

  static propTypes = {
    transitionState: propTypes.string.isRequired,
    onChangeTransition: propTypes.func.isRequired,
  }

  render() {
    const { transitionState, onChangeTransition } = this.props;
    const buttonProps = {transitionState, onChangeTransition};
    return (
      <div className="program-preview-transition-state-controller">
        <ButtonGroup vertical size="sm">
          <TransitionButton {...buttonProps} setTransitionState="entering" />
          <TransitionButton {...buttonProps} setTransitionState="entered" />
          <TransitionButton {...buttonProps} setTransitionState="exiting" />
          <TransitionButton {...buttonProps} setTransitionState="exited" />
        </ButtonGroup>
      </div>
    );
  }

}
