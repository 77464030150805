import React from 'react';
import propTypes from 'prop-types';
import Icon from 'client/components/Icon';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import Spinner from 'react-spinkit';
import './Buttons.css';

/**
 * @desc Button with icon
 */
export class ButtonIcon extends React.Component {

  static propTypes = {
    icon: propTypes.string.isRequired,
    iconTag: propTypes.string,
  }

  render() {
    const { icon, iconTag, className, ...buttonProps } = this.props;
    const classes = classNames(className);
    return (
      <Button {...buttonProps} className={classes}>
        <Icon icon={icon} tag={iconTag} />
      </Button>
    );
  }
}

/**
 * @desc Round button containing an Icon
 */
export class ButtonIconRound extends React.Component {

  render() {
    const { className, ...props } = this.props;
    const classes = classNames(className || '', 'btn-icon', 'rounded-circle');
    return <ButtonIcon
      iconTag="div"
      className={classes}
      {...props}
    />;
  }

}

export class ButtonIconText extends React.Component {

  static propTypes = {
    iconPosition: propTypes.string,
    icon: propTypes.string.isRequired,
  }

  static defaultProps = {
    iconPosition: 'append',
  }

  render() {
    const { iconPosition, icon, className, children, ...buttonProps } = this.props;
    const buttonClasses = classNames(className, 'btn-icon-text');
    // const iconClasses = classNames(iconPosition === 'append' ? 'icon-append' : 'icon-prepend');
    return (
      <Button {...buttonProps} className={buttonClasses}>
        {iconPosition === 'append' && children}
        <Icon icon={icon} />
        {iconPosition === 'prepend' && children}
      </Button>
    );
  }

}

export class ButtonSpinner extends React.Component {

  static propTypes = {
    spinning: propTypes.bool.isRequired,
    spinnerColor: propTypes.string,
  }

  static defaultProps = {
    spinning: false,
    spinnerColor: 'rgba(255,255,255,1)',
  }

  render() {
    const {
      spinning,
      spinnerColor,
      className,
      children,
      ...buttonProps
    } = this.props;
    if(spinning) {
      return (
        <Button {...buttonProps} className={className}>
          <Spinner fadeIn="none" name="three-bounce" color={spinnerColor} />
        </Button>
      );
    }
    return (
      <Button {...buttonProps} className={className}>
        {children}
      </Button>
    );
  }

}

export class ButtonSpinnerPromise extends React.Component {

  static propTypes = {
    onClick: propTypes.func.isRequired,
  }

  state = {
    spinning: false,
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  handleClick = ev => {
    const promise = this.props.onClick(ev);
    this.setState({spinning: true});
    promise.then(() => {
      if(!this.willUnmount) {
        this.setState({spinning: false});
      }
    });
    return promise;
  }

  render() {
    const { children, ...props } = this.props;
    const { spinning } = this.state;
    return (
      <ButtonSpinner
        {...props}
        onClick={this.handleClick}
        spinning={spinning}
      >
        {children}
      </ButtonSpinner>
    );
  }

}

export function SaveButton(props) {
  const { children, disabled, isSaving, ...otherProps } = props;
  return (
    <ButtonSpinner
      color="primary"
      type="submit"
      spinning={isSaving}
      disabled={disabled || isSaving}
      {...otherProps}
    >
      Save
    </ButtonSpinner>
  );
}

export function ResetButton(props) {
  const { children, ...otherProps } = props;
  return (
    <Button
      type="reset"
      color="secondary"
      {...otherProps}
    >
      Reset
    </Button>
  );
}

export function FileUploadButton(props) {
  const {
    children,
    inputName,
    className,
    onChange,
    inputProps = {},
    ...otherProps
  } = props;
  const classes = classNames(className, 'btn-file');
  return (
    <div {...otherProps} className={classes}>
      {children}
      <input name={inputName || 'file'} {...inputProps} onChange={onChange} type="file" />
    </div>
  );
}
