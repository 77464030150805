import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'client/components/Spinners';
import FileUploadPhotoCard from 'client/components/FileUpload/FileUploadPhotoCard';
import { getProgramGalleryImageUrl } from 'client/helpers/imageUrls';
import * as editActions from 'client/redux/accountProgram/editActions';

class ConnectedProgramSettingGalleryImageUpload extends React.Component {

  static propTypes = {
    programId: propTypes.string.isRequired,
    image: propTypes.string,
  }

  handleUploaded = (err, body) => {
    this.props.accountProgramGalleryImageUploadResponse(err, body);
  }

  handleDelete = () => {
    const { programId } = this.props;
    this.props.accountProgramGalleryImageDelete(programId);
  }

  getUploadUrl() {
    const { programId } = this.props;
    return process.env.REACT_APP_URL + `/api/account/program/${programId}/gallery/settings/image`;
  }

  getImageUrl() {
    const { image } = this.props;
    return image ? getProgramGalleryImageUrl(image) : null;
  }

  render() {
    const { imageError, isDeletingImage } = this.props;
    return (
      <div style={{maxWidth: '300px'}}>
        <SpinnerOverlay spinning={isDeletingImage} color="primary" showChildren centerX centerY>
          <FileUploadPhotoCard
            error={imageError}
            uploadUrl={this.getUploadUrl()}
            onUploaded={this.handleUploaded}
            onDelete={this.handleDelete}
            imageUrl={this.getImageUrl()}
            helpText="Please upload a PNG or JPG image"
            uploadFieldName="image"
          />
        </SpinnerOverlay>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountProgram.edit.programGallery,
});

const actions = {
  ...editActions,
};

export default connect(mapStateToProps, actions)(ConnectedProgramSettingGalleryImageUpload);
