import { getApiActionTypes } from 'client/redux/apiHelpers';

export const ACCOUNT_SETTINGS_LIST = getApiActionTypes('ACCOUNT_SETTINGS_LIST');

export const ACCOUNT_SETTINGS_INDEX_LIST = getApiActionTypes('ACCOUNT_SETTINGS_INDEX_LIST');
export const ACCOUNT_SETTINGS_INDEX_UPDATE = getApiActionTypes('ACCOUNT_SETTINGS_INDEX_UPDATE');

export const ACCOUNT_SETTINGS_PHOTO_UPLOAD_DELETE = getApiActionTypes('ACCOUNT_SETTINGS_PHOTO_UPLOAD_DELETE');
export const ACCOUNT_SETTINGS_PHOTO_UPLOAD_READ = getApiActionTypes('ACCOUNT_SETTINGS_PHOTO_UPLOAD_READ');
export const ACCOUNT_SETTINGS_PHOTO_UPLOAD_RESPONSE = 'ACCOUNT_SETTINGS_PHOTO_UPLOAD_RESPONSE';
