import * as constants from 'client/redux/accountProgram/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  transitionState: 'entered',
  readError: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_PREVIEW_READ_SCENE, state, action, {
    requestProp: 'isReading',
    errorProp: 'readError',
    successPickProps: ['scene'],
  });

  switch(action.type) {
    default: return state;
    case constants.ACCOUNT_PROGRAM_PREVIEW_SET_TRANSITION_STATE: return {
      ...state,
      transitionState: action.transitionState,
    };
  }
};
