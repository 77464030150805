import React from 'react';
import propTypes from 'prop-types';
import { Col, FormGroup, FormText, Label, Input } from 'reactstrap';
import _ from 'lodash';
import { PickListInput } from 'client/components/FormHelpers';
import { ConnectedProgramListLoaderSpinner, ConnectedProgramListConsumer } from 'client/components/Program/ProgramListProviders';
import { ConnectedDataPersonListLoaderSpinner, ConnectedDataPersonListConsumer } from 'client/components/Data/DataProviders';
import { ProgramDescription } from 'client/components/Program/ProgramHelpers';

export default class ProgramSettingPersonfocus extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  getCompleteProgramList(programs) {
    return programs.map(program => ({
      id: program.id,
      label: ProgramDescription(_.omit(program, 'type')),
    }));
  }

  render() {
    const { formikProps } = this.props;
    const { values:program, handleChange, handleBlur, setFieldValue } = formikProps;
    return (
      <div className="py-1">
        <FormGroup row>
          <Label sm={2} for="programSettingPersonfocusPersonId">Person (optional)</Label>
          <Col sm={10}>
            <ConnectedDataPersonListLoaderSpinner>
              <ConnectedDataPersonListConsumer>
                {persons => (
                  <div>
                    <Input
                      type="select"
                      id="programSettingPersonfocusPersonId"
                      name="settings.personId"
                      value={program.settings.personId || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Noone</option>
                      {persons.map(({id}) => (
                        <option key={id} value={id}>{id}</option>
                      ))}
                    </Input>
                    <FormText color="muted">
                      If set the program will only show this person all the time
                    </FormText>
                  </div>
                )}
              </ConnectedDataPersonListConsumer>
            </ConnectedDataPersonListLoaderSpinner>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Leaderboards</Label>
          <Col sm="10">
            <ConnectedProgramListLoaderSpinner listKey="leaderboards" query={{type: 'leaderboard'}}>
              <ConnectedProgramListConsumer listKey="leaderboards"> 
                {programs => (
                  <PickListInput
                    leftLabel="Available programs"
                    rightLabel="Selected programs"
                    name="settings.leaderboardProgramIds"
                    completeList={this.getCompleteProgramList(programs)}
                    onChange={setFieldValue}
                    chosenValues={program.settings.leaderboardProgramIds || []}
                    canChangeOrder
                  />
                )}
              </ConnectedProgramListConsumer> 
            </ConnectedProgramListLoaderSpinner>
          </Col>
        </FormGroup>
      </div>
    );
  }

}
