import React from 'react';
import propTypes from 'prop-types';
import { Col, FormGroup, FormText, Label, Input } from 'reactstrap';
import ProgramSettingTimeseries from 'client/components/ProgramSetting/ProgramSettingTimeseries';
import ConnectedProgramSettingDataGroupIdField from 'client/components/ProgramSetting/ConnectedProgramSettingDataGroupIdField';
import { ConnectedProgramListLoaderSpinner, ConnectedProgramListConsumer } from 'client/components/Program/ProgramListProviders';
import { ProgramDescription } from 'client/components/Program/ProgramHelpers';
import _ from 'lodash';

export default class ProgramSettingLeaderboard extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  render() {
    const { formikProps } = this.props;
    const { values:program, handleChange, handleBlur } = formikProps;
    const formDataTypeSetting = _.get(formikProps, 'values.settings.dataType');
    return (
      <div className="py-1">
        <FormGroup row>
          <Label sm={2} for="programSettingLeaderboardSubTitle">Subtitle</Label>
          <Col sm={10}>
            <Input
              type="text"
              name="settings.subTitle"
              id="programSettingLeaderboardSubTitle"
              value={program.settings.subTitle || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingLeaderboardPersonfocusProgramId">Focus Program</Label>
          <Col sm={10}>
            <ConnectedProgramListLoaderSpinner listKey="personfocuses" query={{type: 'personfocus'}}>
              <ConnectedProgramListConsumer listKey="personfocuses"> 
                {programs => (
                  <Input 
                    type="select"
                    name="settings.personfocusProgramId"
                    value={program.settings.personfocusProgramId || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">No focus on persons</option>
                    {programs.map(program => (
                      <option key={program.id} value={program.id}>
                        <ProgramDescription {...program} />
                      </option>
                    ))}
                  </Input>
                )}
              </ConnectedProgramListConsumer> 
            </ConnectedProgramListLoaderSpinner>
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <legend className="col-form-label col-sm-2 py-0">Data type</legend>
          <Col sm={10}>
            <div className="row">
              <Col sm={2}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="settings.dataType"
                      value="timeSeries"
                      checked={program.settings.dataType === 'timeSeries'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />{' '}
                    Time series
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="settings.dataType"
                      value="board"
                      checked={program.settings.dataType === 'board'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />{' '}
                    Board
                  </Label>
                </FormGroup>
              </Col>
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingLeaderboardGroup">Data group</Label>
          <Col sm={10}>
            <ConnectedProgramSettingDataGroupIdField
              id="programSettingLeaderboardGroup"
              name="settings.groupId"
              value={program.settings.groupId}
              onChange={handleChange}
              onBlur={handleBlur}
              dataType={program.settings.dataType || ''}
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingLeaderboardPersons">Number of persons</Label>
          <Col sm={10}>
            <Input
              type="number"
              name="settings.numberOfPersons"
              id="programSettingLeaderboardPersons"
              min="1"
              max="25"
              value={program.settings.numberOfPersons || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormText color="muted">Any number between 1–14</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingLeaderboardUnit">Value unit</Label>
          <Col sm={10}>
            <Input
              type="string"
              name="settings.valueUnit"
              id="programSettingLeaderboardUnit"
              value={program.settings.valueUnit || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        {formDataTypeSetting === 'timeSeries' && <ProgramSettingTimeseries formikProps={formikProps} />}
      </div>
    );
  }

}
