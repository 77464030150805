import React from 'react';
import _ from 'lodash';
import { ucFirst } from 'client/helpers/strings';

export const ProgramType = ({type}) => <span>{ucFirst(type)}</span>;

export const ProgramDescription = ({title, type, settings}) => {
  if(!title) return null;
  const subTitle = _.get(settings, 'subTitle', '');
  const strType = type ? `${ucFirst(type)}: ` : '';
  return `${strType}${title}${subTitle ? ` <${subTitle}>` : ''}`;
};
