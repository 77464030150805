import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChannelSchedulerCalendarFormModal from 'client/components/ChannelScheduler/ChannelSchedulerCalendarFormModal';

export default class ChannelSchedulerCalendarAddButton extends React.Component {

  static propTypes = {
    onCreate: propTypes.func.isRequired,
  }

  state = {
    modalIsOpen: false,
  }

  initialValues = {
    fromDate: (new Date()).toISOString(),
    toDate: (new Date()).toISOString(),
    priority: 0,
  }

  handleToggleModal = ev => {
    this.setState(state => ({modalIsOpen: !state.modalIsOpen}));
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <div>
        <Button
          type="button"
          color="primary"
          size="sm"
          onClick={this.handleToggleModal}
        >
          New schedule
        </Button>
        <ChannelSchedulerCalendarFormModal
          isOpen={modalIsOpen}
          toggle={this.handleToggleModal}
          onSubmit={this.props.onCreate}
          initialValues={this.initialValues}
          isNew
        />
      </div>
    );
  }

}
