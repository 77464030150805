import React from 'react';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  Nav,
  NavItem,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import ConnectedChannelSchedulerCalendar from 'client/components/ChannelScheduler/ConnectedChannelSchedulerCalendar';
import ConnectedChannelSchedulerQueue from 'client/components/ChannelScheduler/ConnectedChannelSchedulerQueue';
import ConnectedChannelSchedulerPreview from 'client/components/ChannelScheduler/ConnectedChannelSchedulerPreview';
import { ConnectedProgramListLoader } from 'client/components/Program/ProgramListProviders';
import ConnectedChannelSchedulerPreviewNavButton from 'client/components/ChannelScheduler/ConnectedChannelSchedulerPreviewNavButton';

const ChannelSchedulerNavButton = props => {
  const { channelId, tabName, children } = props;
  return (
    <NavItem>
      <NavLink className="nav-link" to={`/channels/${channelId}/${tabName}`}>{children}</NavLink>
    </NavItem>
  );
};

export default class ChannelScheduler extends React.Component {

  static propTypes = {
    channelId: propTypes.string.isRequired,
  }

  render() {
    const { channelId, activeTab  } = this.props;
    const navButtonProps = {activeTab, channelId};
    return (
      <Card className="channel-scheduler">
        <CardHeader>
          <Nav tabs className="card-header-tabs">
            <ChannelSchedulerNavButton tabName="repeat" {...navButtonProps}>
              Always Repeating Queue
            </ChannelSchedulerNavButton>
            <ChannelSchedulerNavButton tabName="calendar" {...navButtonProps}>
              Scheduled Programs
            </ChannelSchedulerNavButton>
            <ConnectedChannelSchedulerPreviewNavButton tabName="preview" {...navButtonProps}>
              Preview Calendar
            </ConnectedChannelSchedulerPreviewNavButton>
          </Nav>
        </CardHeader>
        <CardBody className="py-2">
          <ConnectedProgramListLoader>
            {activeTab === 'repeat' && <ConnectedChannelSchedulerQueue channelId={channelId} />}
            {activeTab === 'calendar' && <ConnectedChannelSchedulerCalendar channelId={channelId} />}
            {activeTab === 'preview' && <ConnectedChannelSchedulerPreview channelId={channelId} />}
          </ConnectedProgramListLoader>
        </CardBody>
      </Card>
    );
  }

}
