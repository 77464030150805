import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

export class PositionIndicator extends React.Component {

  static propTypes = {
    position: propTypes.number,
    bright: propTypes.bool,
  }

  static defaultProps = {
    position: 1,
    bright: false,
  }

  render() {
    const { position, bright } = this.props;
    const className = classNames('position-indicator', `position-${position}`, bright ? 'position-bright' : '');
    return (
      <div className={className}>
        <div className="circle">{position || '?'}</div>
      </div>
    );
  }
}

export class UserNumber extends React.Component {

  static propTypes = {
    position: propTypes.number,
  }

  static defaultProps = {
    position: 1,
  }

  getCrownClass(position) {
    switch(position) {
      default: return '';
      case 1: return 'ltv_crowngold';
      case 2: return 'ltv_crownsilver';
      case 3: return 'ltv_crownbronze';
    }
  }

  render() {
    const { position } = this.props;
    const className = classNames('ltv_usernumber', this.getCrownClass(position));
    return (
      <div className={className}>
        <div className="ltv_wrapper">            
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.21 79.55">
            <path d="M76.17.01h17.86V22.7a119.2 119.2 0 0 1 16.29 2.31l6.06-22.3 17.28 4.26-6.15 23.04a117.78 117.78 0 0 1 14.44 6.58l12.64-21.7 15.62 8.24-30.64 56.4c-37.5-23.13-71.44-23.13-108.94 0L0 23.13l15.62-8.24 12.63 21.7a117.35 117.35 0 0 1 14.55-6.62l-6.26-23 17.29-4.26 6.11 22.3a119.05 119.05 0 0 1 16.28-2.31z" fill="#9a8374" fill-rule="evenodd">
            </path>
          </svg>
          <div className="ltv_wrapper">
            {position || '?'}
          </div>
        </div>
      </div> 
    );
  }

}

export class UserState extends React.Component {

  static propTypes = {
    direction: propTypes.oneOf(['up', 'down', 'stay']),
  }

  render() {
    const { direction } = this.props;
    const className = classNames('ltv_userstate', `ltv_${direction}`);
    return (
      <div className={className}>
        <div className="ltv_statecircle"></div>
      </div>
    );
  }

}
