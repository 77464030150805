import { apiAction } from 'client/redux/apiHelpers';

export const list = (types) => apiAction({
  endpoint: '/api/account/settings',
  method: 'GET',
  types,
});

export const update = (types, settingsUpdate) => apiAction({
  endpoint: `/api/account/settings`,
  method: 'PUT',
  json: settingsUpdate,
  types: types.map(type => ({type, meta: {settingsUpdate}})),
});

export const photoDelete = (types) => apiAction({
  endpoint: `/api/account/settings/photo`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {}})),
});

export const photoRead = (types) => apiAction({
  endpoint: `/api/account/settings/photo`,
  method: 'GET',
  types: types.map(type => ({type, meta: {}})),
});
