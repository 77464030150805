import React from 'react';
import propTypes from 'prop-types';
import { Button, InputGroup, Input, InputGroupAddon, FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import './ViewPortPasswordDialog.css';

export default class ViewPortPasswordDialog extends React.Component {

  static propTypes = {
    onSetPassword: propTypes.func.isRequired,
    transitionState: propTypes.oneOf(['entering', 'entered', 'exiting', 'exited']),
  }

  state = {
    password: '',
  }

  handleSubmit = ev => {
    ev.preventDefault();
    const { password } = this.state;
    return this.props.onSetPassword(password);
  }

  handleChange = ev => {
    this.setState({[ev.target.name]: ev.target.value});
  }

  render() {
    const { error, transitionState } = this.props;
    const { password } = this.state;
    const classes = classNames('viewport-password-dialog', transitionState);
    return (
      <form className={classes} onSubmit={this.handleSubmit}>
        <div className="inner">
          <InputGroup>
            <Input
              className="rounded-left"
              type="password"
              onChange={this.handleChange}
              name="password"
              value={password}
              invalid={Boolean(error)}
            />
            <InputGroupAddon addonType="append">
              <Button
                className="rounded-right"
                type="submit"
                color="info"
              >
                Login
              </Button>
            </InputGroupAddon>
            <FormFeedback>
              <span className="text-white">
                {error}
              </span>
            </FormFeedback>
          </InputGroup>
        </div>
      </form>
    );
  }
}
