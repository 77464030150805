import { apiAction } from 'client/redux/apiHelpers';

export const login = (types, json) => {
  return apiAction({
    endpoint: '/api/auth/login',
    method: 'POST',
    types,
    json,
  });
};

export const logout = types => {
  return apiAction({
    endpoint: '/api/auth/logout',
    method: 'POST',
    types,
    json: {}
  });
};
