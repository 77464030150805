import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewPortAlert from 'client/components/ViewPort/ViewPortAlert';
import { CSSTransition } from 'react-transition-group';
import './ConnectedViewPortWebsocketState.css';

class ConnectedViewPortWebsocketState extends React.Component {

  static propTypes = {
    connected: propTypes.bool,
    connectError: propTypes.object,
    reconnectionAttempts: propTypes.number,
  }

  render() {
    const { connected, connectError, reconnectionAttempts } = this.props;
    return (
      <CSSTransition
        in={Boolean(!connected && connectError)}
        timeout={200}
        classNames="viewport-websocket-state"
        unmountOnExit
        appear
      >
        <div className="viewport-websocket-state">
          <ViewPortAlert>
            {connectError && <span>Connection Error: {connectError.message} ({reconnectionAttempts})</span>}
          </ViewPortAlert>
        </div>
      </CSSTransition>
    );
  }

}


const mapStateToProps = (state, props) => ({
  ...state.websocket,
});

const actions = {
};

export default connect(mapStateToProps, actions)(ConnectedViewPortWebsocketState);
