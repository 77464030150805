import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { uploadTo } from 'client/helpers/fileUpload';
import {
  Card,
  CardFooter,
  CardText,
  CardImg,
  Progress,
} from 'reactstrap';
import { ButtonIcon, FileUploadButton } from 'client/components/Buttons';

export default class FileUploadPhotoCard extends React.Component {

  static propTypes = {
    error: propTypes.object,
    imageUrl: propTypes.string,
    uploadUrl: propTypes.string.isRequired,
    uploadFieldName: propTypes.string.isRequired,
    onUploaded: propTypes.func.isRequired,
    onDelete: propTypes.func,
    helpText: propTypes.string,
  }

  static defaultProps = {
    uploadFieldName: 'photo',
  }

  state = {
    progress: null,
  }

  handleUploadProgress = percentCompleted => {
    this.setState({progress: percentCompleted});
  }

  handleFileChange = ev => {
    const { uploadFieldName, uploadUrl, onUploaded } = this.props;
    const files = _.get(ev, 'target.files', []);
    if(!files.length) return;

    uploadTo(uploadUrl, uploadFieldName,files[0], this.handleUploadProgress)
      .then(res => onUploaded(null, res.data))
      .catch(err => onUploaded(err));

    ev.target.value = '';
  }

  render() {
    const { imageUrl, uploadFieldName, error, onDelete, helpText } = this.props;
    const { progress } = this.state;
    return (
      <Card>
        {imageUrl && <CardImg className="pt-2 px-2 pb-0 bg-light" src={imageUrl} />}
        <CardFooter className="p-2">
          {progress > 0 && progress <= 100 && (
            <div className="mb-2">
              <Progress value={progress} />
            </div>
          )}
          <ErrorAlert className="mb-2" error={error} />
          <div className="d-flex justify-content-center">
            <FileUploadButton
              className="btn btn-secondary btn-sm"
              onChange={this.handleFileChange}
              inputName={uploadFieldName}
            >
              {imageUrl && 'Replace image'}
              {!imageUrl && 'Upload image'}
            </FileUploadButton>
            {imageUrl && onDelete && (
              <ButtonIcon
                size="sm"
                title="Delete"
                icon="ion-md-trash"
                type="button"
                className="ml-2"
                onClick={onDelete}
              />
            )}
          </div>
          {helpText && (
            <CardText className="text-center mt-1 small">
              {helpText}
            </CardText>
          )}
        </CardFooter>
      </Card>
    );
  }

}
