import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewPortScene from 'client/components/ViewPort/ViewPortScene';
import ViewPortPasswordDialog from 'client/components/ViewPort/ViewPortPasswordDialog';
import { Transition } from 'react-transition-group';
import { viewportReadState } from 'client/redux/viewport/viewportActions';
import { tvOutputAuthSetPassword } from 'client/redux/tvOutput/tvOutputAuthActions';
import { responseToErrorMessage } from 'client/redux/apiHelpers';
import _ from 'lodash';

class ConnectedTvOutputPasswordDialogScene extends React.Component {

  static propTypes = {
    channelId: propTypes.string.isRequired,
  }

  transitionDelay =  300;

  state = {
    transitioningIn: false,
    error: null,
  }

  getErrorFromReadStateAction(action) {
    const payload = _.get(action, 'payload');
    if(payload) {
      return responseToErrorMessage(payload, true);
    }
    return _.get(action, 'error.message', 'Unknown error');
  }

  handleSetPassword = channelPassword => {
    const { channelId } = this.props;
    this.props.viewportReadState(channelId, channelPassword).then(action => {
      if(action && action.type === 'VIEWPORT_READ_STATE_SUCCESS') {
        this.setState({transitioningIn: false, error: null});
        setTimeout(() => {
          this.props.tvOutputAuthSetPassword(channelId, channelPassword);
        }, 300);
        return;
      }
      this.setState({error: this.getErrorFromReadStateAction(action)});
    });
  }

  componentDidMount() {
    this.setState({transitioningIn: true});
  }

  render() {
    const { transitioningIn, error } = this.state;
    return (
      <ViewPortScene>
        <Transition in={transitioningIn} timeout={this.transitionDelay} appear={true}>
          {transitionState => (
            <ViewPortPasswordDialog
              error={error}
              transitionState={transitionState}
              onSetPassword={this.handleSetPassword}
            />
          )}
        </Transition>
      </ViewPortScene>
    );
  }
}

const actions = {
  viewportReadState,
  tvOutputAuthSetPassword,
};

export default connect(null, actions)(ConnectedTvOutputPasswordDialogScene);
