import _ from 'lodash';
import { viewportReadStateTypes } from 'client/redux/viewport/viewportActions';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultChannelState = {
  isReading: false,
  error: null,
  channelTick: null, // contains the latest channel tick (nextScene and currentScene)
  transitioningIn: true,
  upcomingScene: null, // the upcoming scene is committed 10 seconds before it is due to be shown
  showingScene: null, // the currently showing scene
};

// gets the currently showing scene (only overrides a non-existing showing scene for intial setup)
function getShowingScene(channelState, channelTick) {
  return _.cloneDeep(channelState.showingScene || channelTick.currentScene || null);
}

const reduceChannel = (state = defaultChannelState, action) => {

  state = reduceByTypes(viewportReadStateTypes, state, action, {
    requestProp: 'isReading',
    errorProp: 'error',
    successProp: (state, action, payload) => {
      const { channelTick } = payload;
      return {
        channelTick,
        showingScene: getShowingScene(state, channelTick),
      };
    },
  });

  switch(action.type) {
    default: return state;

    case 'VIEWPORT_SCENE_COMMIT_UPCOMING': {
      const { currentScene, nextScene } = _.get(state, 'channelTick', {});
      return {
        ...state,
        // if nextScene is not set, currentScene might be a "non-cycling" scene, and should be used instead
        upcomingScene: _.cloneDeep(nextScene || currentScene),
      }
    }

    case 'VIEWPORT_SCENE_SHOW_UPCOMING': return {
      ...state,
      upcomingScene: null,
      showingScene: _.cloneDeep(state.upcomingScene),
    };

    case 'VIEWPORT_SCENE_OUT': return {
      ...state,
      transitioningIn: false,
    };

    case 'VIEWPORT_SCENE_IN': return {
      ...state,
      transitioningIn: true,
    };

    case 'VIEWPORT_CHANNEL_TICK_RECEIVED': {
      const channelTick = _.get(action, ['args', '0', 'tick'], {});
      const currentScene = _.get(channelTick, 'currentScene');
      const showingSceneOnEmptyScreen = !state.showingScene && currentScene;
      return {
        ...state,
        channelTick,
        showingScene: showingSceneOnEmptyScreen ? currentScene : state.showingScene,
        transitioningIn: showingSceneOnEmptyScreen ? true : state.transitioningIn,
      };
    }

  }
};

// the root state maps channel ids to their own respective channelReducer
const defaultRootState = {};

export default function(state = defaultRootState, action) {
  if(viewportReadStateTypes.includes(action.type)) {
    const channelId = action.meta.channelId;
    return {
      ...state,
      [channelId]: reduceChannel(state[channelId], action),
    };
  }
  switch(action.type) {
    default: return state;
    case 'VIEWPORT_SCENE_COMMIT_UPCOMING':
    case 'VIEWPORT_SCENE_SHOW_UPCOMING':
    case 'VIEWPORT_SCENE_IN':
    case 'VIEWPORT_SCENE_OUT': {
      const channelId = action.channelId;
      return {
        ...state,
        [channelId]: reduceChannel(state[channelId], action),
      };
    }
    case 'VIEWPORT_CHANNEL_TICK_RECEIVED': {
      const channelId = _.get(action, ['args', '0', 'channelId']);
      return {
        ...state,
        [channelId]: reduceChannel(state[channelId], action),
      };
    }
  }
}
