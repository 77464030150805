import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { getProgramGalleryImageUrl } from 'client/helpers/imageUrls';

export default class ViewPortAssetPreloader extends React.Component {

  static propTypes = {
    scene: propTypes.object,
  }

  componentDidMount() {
    const { scene } = this.props;
    const currSceneId = _.get(this.props, 'scene.id');
    if(currSceneId) {
      this.preloadAssets(scene);
    }
  }

  componentDidUpdate(prevProps) {
    const { scene } = this.props;
    const prevSceneId = _.get(prevProps, 'scene.id');
    const currSceneId = _.get(this.props, 'scene.id');
    if(currSceneId && currSceneId !== prevSceneId) {
      this.preloadAssets(scene);
    }
  }

  preloadAssets(scene) {
    // only gallery assets are preloaded
    if(scene.type === 'gallery') {
      const image = scene.state.image;
      const preloadImage = new Image();
      preloadImage.src = getProgramGalleryImageUrl(image);
    }
  }

  render() {
    return '';
  }

}
