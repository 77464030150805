import React from 'react';
import { connect } from 'react-redux';
import ViewPort from 'client/components/ViewPort/ViewPort';
import { Transition } from 'react-transition-group';
import propTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import ViewPortScene from 'client/components/ViewPort/ViewPortScene';
import ViewPortAssetPreloader from 'client/components/ViewPort/ViewPortAssetPreloader';
import {
  websocketEmit,
  websocketAddEmitOnConnect,
  websocketRemoveEmitOnConnect,
  websocketAddEmitOnConnectAck,
  websocketSubscribe,
  websocketUnsubscribe,
} from 'client/redux/websocket/websocketActions';
import * as viewportActions from 'client/redux/viewport/viewportActions';
import ConnectedViewPortLogoutButton from 'client/components/ViewPort/ConnectedViewPortLogoutButton';

const COMMIT_BEFORE_SECONDS = parseInt(process.env.REACT_APP_COMMIT_UPCOMING_SCENE_SECONDS_BEFORE, 10) || 0;

class ConnectedViewPort extends React.Component {

  static propTypes = {
    channelId: propTypes.string.isRequired,
    channelPassword: propTypes.string.isRequired,
    channelTick: propTypes.object,
    showingScene: propTypes.object,
    upcomingScene: propTypes.object,
  }

  transitionDelay = 300;

  state = {
    commitTimeoutId: null,
    outTimeoutId: null,
    showTimeoutId: null,
  }

  getNextSceneStartAt(props) {
    const { currentScene, nextScene } = _.get(props, 'channelTick', {});
    const nextSceneInLine = nextScene || currentScene;
    return _.get(nextSceneInLine, 'startAt', null);
  }

  getTimeouts = () => {
    const startAt = moment(this.getNextSceneStartAt(this.props));
    if(!startAt.isValid()) {
      return null;
    }

    const showTimeout = moment.duration(startAt.diff(moment())).asMilliseconds();
    const outTimeout = showTimeout - this.transitionDelay;
    const commitTimeout = showTimeout - (COMMIT_BEFORE_SECONDS * 1000);
    return {showTimeout, commitTimeout, outTimeout};
  }

  setTimeouts = () => {
    this.clearTimeouts();

    const { channelId } = this.props;
    const timeouts = this.getTimeouts();
    if(!timeouts) return;

    const commitTimeoutId = setTimeout(() => {
      this.props.viewportSceneCommitUpcoming(channelId);
    }, timeouts.commitTimeout);

    const outTimeoutId = setTimeout(() => {
      this.props.viewportSceneOut(channelId);
    }, timeouts.outTimeout);

    const showTimeoutId = setTimeout(() => {
      this.props.viewportSceneShowUpcoming(channelId);
      this.props.viewportSceneIn(channelId);
    }, timeouts.showTimeout);

    this.setState({commitTimeoutId, showTimeoutId, outTimeoutId});
  }

  clearTimeouts = () => {
    const { commitTimeoutId, showTimeoutId, outTimeoutId } = this.state;
    if(outTimeoutId) clearTimeout(outTimeoutId);
    if(commitTimeoutId) clearTimeout(commitTimeoutId);
    if(showTimeoutId) clearTimeout(showTimeoutId);
  }

  componentDidMount() {
    const { channelId, channelPassword } = this.props;
    this.props.websocketAddEmitOnConnect(`subscribeToChannel-${channelId}`, 'subscribeToChannel', channelId, channelPassword);
    this.props.websocketAddEmitOnConnectAck(`requestChannelTick-${channelId}`, 'requestChannelTick', 'VIEWPORT_CHANNEL_TICK_RECEIVED', channelId, channelPassword);
    this.props.websocketSubscribe('channelTick', 'VIEWPORT_CHANNEL_TICK_RECEIVED');
    this.setTimeouts();
  }

  componentWillUnmount() {
    const { channelId } = this.props;
    this.props.websocketRemoveEmitOnConnect(`subscribeToChannel-${channelId}`);
    this.props.websocketRemoveEmitOnConnect(`requestChannelTick-${channelId}`, 'requestChannelTick', channelId);
    this.props.websocketEmit('unsubscribeToChannel', channelId);
    this.props.websocketUnsubscribe('channelTick');
    this.clearTimeouts();
  }

  componentDidUpdate(prevProps) {
    // check if the start date for the next scene has been changed
    // if it has the timeouts are updated if the next scene is NOT yet committed
    const currNextSceneStartsAt = this.getNextSceneStartAt(this.props);
    const prevNextSceneStartsAt = this.getNextSceneStartAt(prevProps);
    const upcomingSceneExists = Boolean(_.get(this.props, 'upcomingScene'));
    if(currNextSceneStartsAt !== prevNextSceneStartsAt && !upcomingSceneExists) {
      this.setTimeouts();
    }
  }

  render() {
    const { upcomingScene, showingScene, transitioningIn } = this.props;
    return (
      <ViewPortScene>
        <ConnectedViewPortLogoutButton onClick={() => {}} />
        <ViewPortAssetPreloader scene={upcomingScene} />
        <Transition in={transitioningIn} timeout={this.transitionDelay} appear>
          {(transitionState) => (
            <ViewPort
              scene={showingScene}
              transitionState={transitionState}
            />
          )}
        </Transition>
      </ViewPortScene>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ..._.get(state, ['viewport', props.channelId], {}),
});

const actions = {
  websocketEmit,
  websocketAddEmitOnConnect,
  websocketAddEmitOnConnectAck,
  websocketRemoveEmitOnConnect,
  websocketSubscribe,
  websocketUnsubscribe,
  ...viewportActions,
};

export default connect(mapStateToProps, actions)(ConnectedViewPort);
