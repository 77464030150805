const defaultPersonPhoto = process.env.REACT_APP_PERSON_PHOTO_DEFAULT;
const personPhotoUrlBase = process.env.REACT_APP_PERSON_PHOTO_BASE_URL;
const accountPhotoUrlBase = process.env.REACT_APP_ACCOUNT_PHOTO_BASE_URL;
const defaultAccountPhoto = process.env.REACT_APP_ACCOUNT_PHOTO_DEFAULT;
const accountProgramLogoImageBase = process.env.REACT_APP_ACCOUNT_PROGRAM_LOGO_IMAGE_BASE_URL;
const accountProgramGalleryImageBase = process.env.REACT_APP_ACCOUNT_PROGRAM_GALLERY_IMAGE_BASE_URL;

export const getPersonPhotoUrl = personPhoto => `${personPhotoUrlBase}/${personPhoto || defaultPersonPhoto}`;

export const getAccountPhotoUrl = accountPhoto => `${accountPhotoUrlBase}/${accountPhoto || defaultAccountPhoto}`

export const getProgramLogoImageUrl = logoImage => `${accountProgramLogoImageBase}/${logoImage}`;

export const getProgramGalleryImageUrl = logoImage => `${accountProgramGalleryImageBase}/${logoImage}`;
