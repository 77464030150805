import { combineReducers } from 'redux';
import timeSeries from 'client/redux/accountData/timeSeriesReducer';
import boards from 'client/redux/accountData/boardsReducer';
import persons from 'client/redux/accountData/personsReducer';
import list from 'client/redux/accountData/listReducer';

const accountDataReducer = combineReducers({
  boards,
  list,
  persons,
  timeSeries,
});

export default accountDataReducer;
