import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { transitionStatePropType } from 'client/helpers/propTypes';
import { NumberFormatUnit } from 'client/components/Helpers';

export default class ViewPortProgramSumboard extends React.Component {

  static propTypes = {
    title: propTypes.string.isRequired,
    valueUnit: propTypes.string.isRequired,
    table: propTypes.array.isRequired,
    transitionState: transitionStatePropType().isRequired,
  }

  render() {
    const { transitionState, valueUnit, table, title } = this.props;
    const classes = classNames('ltv_sumboard', transitionState);
    return (
      <div className={classes}>
        <div className="ltv_sumboardcaption">
          {title}
        </div>
        {table && table.map(({subTitle, value}, index) => (
          <div className="ltv_sum" key={index}>
            <div className="ltv_sumboardcaption_sub">
              {subTitle}
            </div>
            <div className="ltv_orangebox">
              <div className="ltv_wrapper">
                <NumberFormatUnit className="value" value={value} unit={valueUnit} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

}
