import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ViewPort from 'client/components/ViewPort/ViewPort';
import ViewPortScene from 'client/components/ViewPort/ViewPortScene';
import * as previewActions from 'client/redux/accountProgram/programPreviewActions';
import { ProgramPreviewTransitionStateController } from 'client/components/ProgramPreview/ProgramPreviewControllers';

class ConnectedProgramPreview extends React.Component {

  getProgramId() {
    return _.get(this.props, 'match.params.programId');
  }

  componentDidMount() {
    this.props.accountProgramPreviewReadScene(this.getProgramId());
  }

  handleChangeTransition = transitionState => {
    this.props.accountProgramPreviewSetTransitionState(transitionState);
  }

  render() {
    const { scene, transitionState } = this.props;
    return (
      <ViewPortScene>
        <ProgramPreviewTransitionStateController
          transitionState={transitionState}
          onChangeTransition={this.handleChangeTransition}
        />
        {scene && <ViewPort
          scene={scene}
          transitionState={transitionState}
        />}
      </ViewPortScene>
    );
  }
}

const mapStateToProps = state => ({
  ...state.accountProgram.programPreview,
});

const actions = {
  ...previewActions,
};

export default connect(mapStateToProps, actions)(ConnectedProgramPreview);
