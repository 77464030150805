import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DefaultPage as Page } from 'client/components/Pages';
import PageHeader from 'client/components/PageHeader';
import ProgramList from 'client/components/ProgramIndexPage/ProgramList';
import * as indexActions from 'client/redux/accountProgram/indexActions';
import { push } from 'connected-react-router';
import { rejectOnActionError } from 'client/redux/apiHelpers';
import { ErrorAlert, ErrorModal } from 'client/components/ErrorHelpers';

class ConnectedProgramIndexPage extends React.Component {

  handleCreateProgram = programProps => {
    return this.props.accountProgramIndexCreate(programProps)
      .then(rejectOnActionError)
      .then(result => {
        const programId = _.get(result, 'payload.program.id');
        return this.props.push(`/programs/${programId}`);
      });
  }

  handleDeleteProgram = programId => {
    return this.props.accountProgramIndexDelete(programId);
  }

  handleCloneProgram = programId => {
    return this.props.accountProgramIndexClone(programId).then(() => {
      return this.props.accountProgramIndexList();
    });
  }

  handleUpdateFilter = filter => {
    return this.props.accountProgramIndexFilter(filter);
  }

  componentDidMount() {
    this.props.accountProgramIndexList();
  }

  render() {
    const {
      isLoading,
      programs,
      modalError,
      pageError,
      filter,
    } = this.props;
    return (
      <Page>
        <PageHeader title="Programs" />
        <ErrorModal error={modalError} />
        <ErrorAlert error={pageError} />
        <ProgramList
          programs={programs}
          isLoading={isLoading}
          filter={filter}
          onCreateProgram={this.handleCreateProgram}
          onDeleteProgram={this.handleDeleteProgram}
          onUpdateFilter={this.handleUpdateFilter}
          onCloneProgram={this.handleCloneProgram}
        />
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountProgram.index,
});

const actions = {
  ...indexActions,
  push,
};

export default connect(mapStateToProps, actions)(ConnectedProgramIndexPage);
