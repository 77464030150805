import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'client/components/Spinners';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import * as scheduleQueueActions from 'client/redux/accountChannel/scheduleQueueActions';
import { ChannelSchedulerQueueTable } from 'client/components/ChannelScheduler/ChannelSchedulerQueueTableHelpers';
import ChannelSchedulerQueueAddButton from 'client/components/ChannelScheduler/ChannelSchedulerQueueAddButton';

class ConnectedChannelSchedulerQueue extends React.Component {

  static propTypes = {
    channelId: propTypes.string.isRequired,
  }

  componentDidMount() {
    const { channelId } = this.props;
    return this.props.scheduleQueueChannelIndex(channelId);
  }

  handleDelete = (channelScheduleQueueId) => {
    return this.props.scheduleQueueDelete(channelScheduleQueueId);
  }

  handleUpdate = (channelScheduleQueueId, scheduleQueueProps) => {
    return this.props.scheduleQueueUpdate(channelScheduleQueueId, scheduleQueueProps);
  }

  handleMove = (channelScheduleQueueId, direction) => {
    return this.props.scheduleQueueMove(channelScheduleQueueId, direction);
  }

  handleCreate = (scheduleQueueProps) => {
    const { channelId } = this.props;
    return this.props.scheduleQueueCreate({...scheduleQueueProps, channelId});
  }


  render() {
    const { error, isReading, scheduleQueues } = this.props;
    return (
      <div className="p-2 pt-3">
        <SpinnerOverlay color="primary" spinning={isReading} centerX centerY showChildren>
          <ErrorAlert className="mt-4" error={error} />
          {scheduleQueues && 
              <ChannelSchedulerQueueTable
                scheduleQueues={scheduleQueues}
                onUpdate={this.handleUpdate}
                onDelete={this.handleDelete}
                onMove={this.handleMove}
              />
          }
          <div className="d-flex justify-content-end">
            <ChannelSchedulerQueueAddButton onCreate={this.handleCreate} />
          </div>
        </SpinnerOverlay>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.accountChannel.scheduleQueue,
});

const actions = {
  ...scheduleQueueActions,
};

export default connect(mapStateToProps, actions)(ConnectedChannelSchedulerQueue);
