import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'client/components/Spinners';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import * as scheduleCalendarActions from 'client/redux/accountChannel/scheduleCalendarActions';
import { ChannelSchedulerCalendarTable } from 'client/components/ChannelScheduler/ChannelSchedulerCalendarTableHelpers';
import ChannelSchedulerCalendarAddButton from 'client/components/ChannelScheduler/ChannelSchedulerCalendarAddButton';


class ConnectedChannelSchedulerCalendar extends React.Component {

  static propTypes = {
    channelId: propTypes.string.isRequired,
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { channelId } = this.props;
    return this.props.scheduleCalendarIndex(channelId);
  }

  handleUpdate = (channelScheduleCalendarId, scheduleCalendarProps) => {
    const { channelId } = this.props;
    return this.props.scheduleCalendarUpdate(channelId, channelScheduleCalendarId, scheduleCalendarProps);
  }

  handleDelete = (channelScheduleCalendarId) => {
    const { channelId } = this.props;
    return this.props.scheduleCalendarDelete(channelId, channelScheduleCalendarId);
  }

  handleCreate = (scheduleCalendarProps) => {
    const { channelId } = this.props;
    return this.props.scheduleCalendarCreate(channelId, scheduleCalendarProps);
  }

  render() {
    const { error, isReading, scheduleCalendars } = this.props;
    return (
      <div className="p-2 pt-3">
        <SpinnerOverlay color="primary" spinning={isReading} centerX centerY showChildren>
          <ErrorAlert error={error} />
          {scheduleCalendars &&
            <ChannelSchedulerCalendarTable
              onUpdate={this.handleUpdate}
              onDelete={this.handleDelete}
              onRefresh={this.getData}
              scheduleCalendars={scheduleCalendars}
            />
          }
          <div className="d-flex justify-content-end">
            <ChannelSchedulerCalendarAddButton onCreate={this.handleCreate} />
          </div>
        </SpinnerOverlay>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.accountChannel.scheduleCalendar,
});

const actions = {
  ...scheduleCalendarActions,
};

export default connect(mapStateToProps, actions)(ConnectedChannelSchedulerCalendar);
