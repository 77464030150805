import React from 'react';
import Icon from 'client/components/Icon';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import propTypes from 'prop-types';
import { responseToErrorMessage } from 'client/redux/apiHelpers';

export class ErrorAlert extends React.Component {

  static propTypes = {
    error: propTypes.oneOfType([propTypes.object, propTypes.string]),
  }

  render() {
    const { error, ...rest } = this.props;
    const errorMsg = responseToErrorMessage(error);
    if(errorMsg === null) return null;
    return (
      <Alert color="danger" {...rest}>
        {errorMsg}
      </Alert>
    );
  }

}

export class ErrorModal extends React.Component {

  static propTypes = {
    error: propTypes.oneOfType([propTypes.object, propTypes.string]),
  }

  state = {
    isOpen: false,
  }

  componentDidMount() {
    this.updateIsOpenState(this.props);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.error !== this.props.error) {
      this.updateIsOpenState(this.props);
    }
  }

  updateIsOpenState(props) {
    const errorMsg = responseToErrorMessage(props.error);
    this.setState(prevState => ({isOpen: Boolean(errorMsg)}));
  }

  toggle = () => {
    this.setState(prevState => ({isOpen: !prevState.isOpen}));
  }

  render() {
    const { isOpen } = this.state;
    const errorMsg = responseToErrorMessage(this.props.error);
    return (
      <Modal isOpen={isOpen} toggle={this.toggle}>
        {isOpen && (
          <div>
            <ModalBody className="tx-center pd-y-20 pd-x-20">
              <button aria-label="Close" className="close" onClick={this.toggle}>×</button>
              <Icon
                icon="ion-md-alert"
                className="tx-100 tx-danger lh-1 mg-t-20 d-inline-block"
                style={{position: 'relative', left: '5px'}}
              />
              <h4 className="tx-danger mg-b-20">Error</h4>
              <p className="mg-b-20 mg-x-20">{errorMsg}</p>
              <Button color="danger" onClick={this.toggle} className="pd-x-25" aria-label="Close">OK</Button>
            </ModalBody>
          </div>
        )}
      </Modal>
    );
  }

}
