import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem }  from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import qs from 'qs';

const ConnectedChannelSchedulerPreviewNavButton = props => {
  const { channelId, activeTab, tabName, lastQuery, children } = props;
  const queryString = qs.stringify(lastQuery, {addQueryPrefix: true});
  const linkClassName = classNames('nav-link', activeTab === tabName ? 'active' : '');
  return (
    <NavItem active={activeTab === tabName}>
      <Link className={linkClassName} to={`/channels/${channelId}/${tabName}${queryString}`}>{children}</Link>
    </NavItem>
  );
};



const mapStateToProps = state => ({
  lastQuery: state.accountChannel.schedulePreview.lastQuery,
});

export default connect(mapStateToProps)(ConnectedChannelSchedulerPreviewNavButton);
