import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const filter = (type, query) => ({type, query});

export const listTimeSeries = (types, query) => apiAction({
  endpoint: appendQuery('/api/account/data/timeSeries', query),
  method: 'GET',
  types,
});

export const listBoards = (types, query) => apiAction({
  endpoint: appendQuery('/api/account/data/boards', query),
  method: 'GET',
  types,
});

export const listPersons = (types, query) => apiAction({
  endpoint: appendQuery('/api/account/data/persons', query),
  method: 'GET',
  types,
});

export const readGroupIds = (types, dataType) => apiAction({
  endpoint: `/api/account/data/${dataType}/groupIds`,
  types: types.map(type => ({type, meta: {dataType}})),
  method: 'GET',
});

export const readPersonIds = (types) => apiAction({
  endpoint: `/api/account/data/personIds`,
  types: types.map(type => ({type, meta: {}})),
  method: 'GET',
});
