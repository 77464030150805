import React from 'react';
import propTypes from 'prop-types';
import ChannelCard from 'client/components/ChannelIndexPage/ChannelCard';
import { SpinnerOverlay } from 'client/components/Spinners';
import ChannelCardNew from 'client/components/ChannelIndexPage/ChannelCardNew';

export default class ChannelList extends React.Component {

  static propTypes = {
    channels: propTypes.array,
    isLoading: propTypes.bool.isRequired,
    onCreateChannel: propTypes.func.isRequired,
    onDeleteChannel: propTypes.func.isRequired,
  }

  handleDelete = channelId => {
    return this.props.onDeleteChannel(channelId);
  }

  render() {
    const { isLoading, channels, onCreateChannel } = this.props;
    return (
      <SpinnerOverlay color="primary" spinning={isLoading}>
        <div className="row">
          {channels && channels.map(channel => (
            <div key={channel.id} className="col-md-4 py-3 d-flex align-items-stretch">
              <ChannelCard
                {...channel}
                onDelete={this.handleDelete}
              />
            </div>
          ))}
          <div className="col-md-4 py-3 d-flex align-items-stretch">
            <ChannelCardNew onCreate={onCreateChannel} />
          </div>
        </div>
      </SpinnerOverlay>
    );
  }
}
