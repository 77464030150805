import * as constants from 'client/redux/accountChannel/constants';
import _ from 'lodash';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultScheduleQueueItemState = {
  isUpdating: false,
  isDeleting: false,
  isMoving: false,
  scheduleQueue: null,
};

const getSuccessPropForActionReturningScheduleQueue = progressProp => (state, action, payload) => {
  const { scheduleQueue } = payload;
  return {
    ...state,
    [progressProp]: false,
    scheduleQueue,
  };
};

export const reduceScheduleQueueItem = function(state = defaultScheduleQueueItemState, action) {

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_UPDATE, state, action, {
    requestProp: 'isUpdating',
    errorProp: null,
    successProp: getSuccessPropForActionReturningScheduleQueue('isUpdating'),
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_DELETE, state, action, {
    requestProp: 'isDeleting',
    errorProp: null,
    successProp: getSuccessPropForActionReturningScheduleQueue('isDeleting'),
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_MOVE, state, action, {
    requestProp: 'isMoving',
    errorProp: null,
    successProp: getSuccessPropForActionReturningScheduleQueue('isMoving'),
  });

  return state;
};

export const reduceScheduleQueuesMap = function(state = [], action) {
  const scheduleQueueId = _.get(action, 'meta.channelScheduleQueueId');
  const index = _.findIndex(state, obj => _.get(obj, 'scheduleQueue.id') === scheduleQueueId);

  if(index === -1) {
    return state;
  }

  const copy = state.slice(0);
  copy[index] = reduceScheduleQueueItem(state[index], action);
  return copy;
};

const getSuccessPropForActionReturningScheduleQueues = progressProp => (state, action, payload) => {
  const { scheduleQueues } = payload;
  const newState = {
    ...state,
    scheduleQueues: scheduleQueues.map(scheduleQueue => ({
      ...defaultScheduleQueueItemState,
      scheduleQueue,
    })),
  };
  if(progressProp) newState[progressProp] = false;
  return newState;
};

const defaultState = {
  isReading: false,
  isDeleting: false,
  isMoving: false,
  isCreating: false,
  error: null,
  scheduleQueues: [],
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CALENDAR_INDEX, state, action, {
    requestProp: 'isReading',
    errorProp: 'error',
    successProp: getSuccessPropForActionReturningScheduleQueues('isReading'),
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_MOVE, state, action, {
    requestProp: 'isMoving',
    errorProp: 'error',
    successProp: getSuccessPropForActionReturningScheduleQueues('isMoving'),
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_UPDATE, state, action, {
    requestProp: 'isUpdating',
    errorProp: 'error',
    successProp: getSuccessPropForActionReturningScheduleQueues('isUpdating'),
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_DELETE, state, action, {
    requestProp: 'isDeleting',
    errorProp: 'error',
    successProp: getSuccessPropForActionReturningScheduleQueues('isDeleting'),
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_CREATE, state, action, {
    requestProp: 'isCreating',
    errorProp: null,
    successProp: getSuccessPropForActionReturningScheduleQueues('isCreating'),
  });

  // all these actions relate to a channel schedule queue items
  const scheduleQueueItemActions = [
    ...constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_MOVE,
    ...constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_UPDATE,
    ...constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_QUEUE_DELETE,
  ];
  if(scheduleQueueItemActions.includes(action.type)) {
    return {
      ...state,
      scheduleQueues: reduceScheduleQueuesMap(state.scheduleQueues, action),
    };
  }

  return state;
};
