import { getApiActionTypes } from 'client/redux/apiHelpers';

export const ACCOUNT_PROGRAM_LIST = getApiActionTypes('ACCOUNT_PROGRAM_LIST');

export const ACCOUNT_PROGRAM_INDEX_CLONE = getApiActionTypes('ACCOUNT_PROGRAM_INDEX_CLONE');
export const ACCOUNT_PROGRAM_INDEX_DELETE = getApiActionTypes('ACCOUNT_PROGRAM_INDEX_DELETE');
export const ACCOUNT_PROGRAM_INDEX_CREATE = getApiActionTypes('ACCOUNT_PROGRAM_INDEX_CREATE');
export const ACCOUNT_PROGRAM_INDEX_LIST = getApiActionTypes('ACCOUNT_PROGRAM_INDEX_LIST');
export const ACCOUNT_PROGRAM_INDEX_FILTER = 'ACCOUNT_PROGRAM_INDEX_FILTER';

export const ACCOUNT_PROGRAM_EDIT_READ = getApiActionTypes('ACCOUNT_PROGRAM_EDIT_READ');
export const ACCOUNT_PROGRAM_EDIT_UPDATE = getApiActionTypes('ACCOUNT_PROGRAM_EDIT_UPDATE');
export const ACCOUNT_PROGRAM_EDIT_UPDATE_SETTINGS = getApiActionTypes('ACCOUNT_PROGRAM_EDIT_UPDATE_SETTINGS');
export const ACCOUNT_PROGRAM_EDIT_DELETE = getApiActionTypes('ACCOUNT_PROGRAM_EDIT_DELETE');
export const ACCOUNT_PROGRAM_LOGO_IMAGE_DELETE = getApiActionTypes('ACCOUNT_PROGRAM_LOGO_IMAGE_DELETE');
export const ACCOUNT_PROGRAM_LOGO_IMAGE_UPLOAD_RESPONSE = 'ACCOUNT_PROGRAM_LOGO_IMAGE_UPLOAD_RESPONSE';
export const ACCOUNT_PROGRAM_GALLERY_IMAGE_DELETE = getApiActionTypes('ACCOUNT_PROGRAM_GALLERY_IMAGE_DELETE');
export const ACCOUNT_PROGRAM_GALLERY_IMAGE_UPLOAD_RESPONSE = 'ACCOUNT_PROGRAM_GALLERY_IMAGE_UPLOAD_RESPONSE';

export const ACCOUNT_PROGRAM_DATA_READ_GROUP_IDS = getApiActionTypes('ACCOUNT_PROGRAM_DATA_READ_GROUP_IDS');

export const ACCOUNT_PROGRAM_PREVIEW_READ_SCENE = getApiActionTypes('ACCOUNT_PROGRAM_PREVIEW_READ_SCENE');
export const ACCOUNT_PROGRAM_PREVIEW_SET_TRANSITION_STATE = 'ACCOUNT_PROGRAM_PREVIEW_SET_TRANSITION_STATE';
