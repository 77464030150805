import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export function FieldDateStart({base, values, handleChange, handleBlur}) {
  return (
    <div className="col-lg-3">
      <FormGroup>
        <Label for={getId(base, 'dateStart')}>
          Start
        </Label>
        <Input
          id={getId(base, 'dateStart')}
          type="date"
          name="dateStart"
          value={values.dateStart}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormGroup>
    </div>
  );
};

export function FieldDateEnd({base, values, handleChange, handleBlur}) {
  return (
    <div className="col-lg-3">
      <FormGroup>
        <Label for={getId(base, 'dateEnd')}>
          End
        </Label>
        <Input
          id={getId(base, 'dateEnd')}
          type="date"
          name="dateEnd"
          value={values.dateEnd}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormGroup>
    </div>
  );
};

export function FieldGroupId({base, values, handleChange, handleBlur, allGroupIds}) {
  return (
    <div className="col-lg-2">
      <FormGroup>
        <Label for={getId('TimeSeries', 'groupId')}>
          Group ID
        </Label>
        <Input
          id={getId('TimeSeries', 'groupId')}
          type="select"
          name="groupId"
          value={values.groupId}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Every Group ID"
        >
          <option value="">Every Group ID</option>
          {(allGroupIds || []).map(groupId => (
            <option key={groupId} value={groupId}>{groupId}</option>
          ))}
        </Input>
      </FormGroup>
    </div>
  );
};

export function FieldPersonId({base, values, handleChange, handleBlur, allPersonIds}) {
  return (
    <div className="col-lg-2">
      <FormGroup>
        <Label for={getId('TimeSeries', 'personId')}>
          Person ID
        </Label>
        <Input
          id={getId('TimeSeries', 'personId')}
          type="select"
          name="personId"
          value={values.personId}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Every Person ID"
        >
          <option value="">Every Person ID</option>
          {(allPersonIds || []).map(personId => (
            <option key={personId} value={personId}>{personId}</option>
          ))}
        </Input>
      </FormGroup>
    </div>
  );
};

export function FieldGroup({base, values, handleChange, handleBlur}) {
  return (
    <div className="col-lg-2">
      <FormGroup>
        <Label for={getId('TimeSeries', 'group')}>
          Group By
        </Label>
        <Input
          id={getId('TimeSeries', 'group')}
          type="select"
          name="group"
          value={values.group}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="">Do not group</option>
          <option value="personId">Person ID</option>
          <option value="sum">Total</option>
        </Input>
      </FormGroup>
    </div>
  );
};

function getId(base, name) {
  return `dataPage${base}${name}`;
}
