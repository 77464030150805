import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Card, CardBody, Table } from 'reactstrap';
import { DefaultPage as Page } from 'client/components/Pages';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import PageHeader from 'client/components/PageHeader';
import { SpinnerOverlay } from 'client/components/Spinners';
import * as boardsActions from 'client/redux/accountData/boardsActions';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import DataPageBoardFilter from 'client/components/DataPage/DataPageBoardFilter';
import { format } from 'url';
import * as query from 'client/helpers/query';

class ConnectedDataPageBoard extends React.Component {

  constructor(props) {
    super(props);
    this.fetchData = _.debounce(query => {
      return this.props.accountDataListBoards(query);
    }, 100);
  }

  componentDidMount() {
    return this.fetchData(this.getQuery(this.props));
  }

  componentDidUpdate(prevProps) {
    const query = this.getQuery(this.props);
    if(!_.isEqual(this.getQuery(prevProps), query)) {
      return this.fetchData(query);
    }
  }

  getQuery(props) {
    return query.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
  }

  handleFilterUpdate = query => {
    const { pathname } = this.props.location;
    return this.props.push(format({
      pathname,
      query,
    }));
  }

  // formats the table data
  getTableData() {
    const { boards = [] } = this.props;
    return boards.map(({...rest}) => ({
      ...rest,
      key: [rest.id, rest.groupId, rest.personId].join('_'),
    }));
  }

  render() {
    const {
      isLoading,
      allPersonIds = [],
      allGroupIds = [],
      error,
    } = this.props;

    const tableData = this.getTableData();

    return (
      <Page>
        <PageHeader title="Board Data" />
        <Card>
          <CardBody>
            <DataPageBoardFilter
              initialValues={this.getQuery(this.props)}
              allPersonIds={allPersonIds}
              allGroupIds={allGroupIds}
              onUpdate={this.handleFilterUpdate}
            />
            <ErrorAlert error={error} />
            <SpinnerOverlay
              spinning={isLoading}
              color="primary"
              fadeIn="quarter"
              showChildren
              centerX
              centerY
            >
              <Table bordered className="mb-0">
                <thead className="bg-primary thead-colored">
                  <tr>
                    <th>Group ID</th>
                    <th>Key</th>
                    <th>Person</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {!tableData.length && (
                    <tr>
                      <td colSpan="5">Nothing to show</td>
                    </tr>
                  )}
                  {tableData.map(row => (
                    <tr key={row.key}>
                      <td>
                        {row.groupId}
                      </td>
                      <td>
                        {row.id}
                      </td>
                      <td>
                        {row.personId}
                      </td>
                      <td>{row.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SpinnerOverlay>
          </CardBody>
        </Card>
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountData.boards,
});

const actions = {
  ...boardsActions,
  push,
};

export default connect(mapStateToProps, actions)(withRouter(ConnectedDataPageBoard));
