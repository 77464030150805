import React from 'react';
import { connect } from 'react-redux';
import { DefaultPage as Page } from 'client/components/Pages';
import * as editActions from 'client/redux/accountChannel/editActions';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import PageHeader from 'client/components/PageHeader';
import { SpinnerOverlay } from 'client/components/Spinners';
import ChannelScheduler from 'client/components/ChannelScheduler/ChannelScheduler';
import ChannelEditor from 'client/components/ChannelEditPage/ChannelEditor';
import _ from 'lodash';

class ConnectedChannelEditPage extends React.Component {

  getChannelId() {
    return _.get(this.props, 'match.params.channelId');
  }

  getSchedulerTab() {
    return _.get(this.props, 'match.params.schedulerTab');
  }

  componentDidMount() {
    const channelId = this.getChannelId();
    this.props.accountChannelEditRead(channelId);
  }

  handleUpdate = channelProps => {
    const channelId = this.getChannelId();
    return this.props.accountChannelEditUpdate(channelId, channelProps);
  }

  render() {
    const { channel, isReading, isUpdating, error, readError } = this.props;
    const channelId = this.getChannelId();
    return (
      <Page>
        <PageHeader title="Channel Editor" />
        <SpinnerOverlay spinning={isReading} color="primary">
          <ErrorAlert error={readError} />
          <div>
            {channel && <ChannelEditor
              className="mb-4 "
              channel={channel}
              error={error}
              onUpdate={this.handleUpdate}
              isUpdating={isUpdating}
            />}
          </div>
          {channel && <ChannelScheduler
            channelId={channelId}
            activeTab={this.getSchedulerTab()}
          />}
        </SpinnerOverlay>
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountChannel.edit,
});

const actions = {
  ...editActions,
};

export default connect(mapStateToProps, actions)(ConnectedChannelEditPage);
