import React from 'react';
import propTypes from 'prop-types';
import {
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import ConnectedProgramSettingGalleryImageUpload from 'client/components/ProgramSetting/ConnectedProgramSettingGalleryImageUpload';

export default class ProgramSettingGallery extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  render() {
    const { formikProps } = this.props;
    const { values:program, handleChange, handleBlur } = formikProps;
    return (
      <div className="py-1">
        <FormGroup row>
          <legend className="col-form-label col-sm-2 py-0">Title display</legend>
          <Col sm={10}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="settings.showTitle"
                  checked={Boolean(program.settings.showTitle)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{' '}
                Show title
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingGalleryImageSizing">Image Sizing</Label>
          <Col sm={10}>
            <Input 
              type="select"
              name="settings.imageSize"
              value={program.settings.imageSize || 'auto'}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="auto">Auto</option>
              <option value="contain">Contain</option>
              <option value="cover">Cover</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Label sm={2} for="settingsImage">Upload Image</Label>
          <Col sm={10} className="pt-2">
            <ConnectedProgramSettingGalleryImageUpload
              programId={program.id}
              image={program.settings.image}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }

}

