import React from 'react';
import propTypes from 'prop-types';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { ProgramDescription } from 'client/components/Program/ProgramHelpers';
import { SaveButton } from 'client/components/Buttons';
import { handleSubmitPromise } from 'client/helpers/formik';
import { DateTimeInputInSettingsZone } from 'client/components/FormHelpers';
import { ConnectedProgramListConsumer } from 'client/components/Program/ProgramListProviders';
import {
  Col,
  FormGroup,
  FormText,
  Input ,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Formik, Form } from 'formik';
import { ChannelIntervalDescription } from 'client/components/Channel/ChannelHelpers';

export default class ChannelSchedulerCalendarFormModal extends React.Component {

  static propTypes = {
    isNew: propTypes.bool.isRequired,
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
  }

  static defaultProps = {
    isNew: false,
  }

  handleSubmit = (values, formikProps) => {
    handleSubmitPromise(this.props.onSubmit(values), formikProps).then(action => {
      this.props.toggle();
    }).catch(err => {
      formikProps.setStatus(err);
    });
  }

  render() {
    const { isNew, isOpen, toggle, initialValues } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{width: '500px'}}>
        <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
          {({handleBlur, handleChange, setFieldValue, values, isSubmitting, isValid, status}) => (
            <Form>
              {isNew && <ModalHeader toggle={toggle}>New Program Schedule</ModalHeader>}
              {!isNew && <ModalHeader toggle={toggle}>Edit Program Schedule</ModalHeader>}
              <ModalBody>
                {status instanceof Error && <ErrorAlert error={status} />}
                <FormGroup>
                  <Label for="channelScheduleCalendarFromDate">From</Label>
                  <DateTimeInputInSettingsZone
                    id="channelScheduleCalendarFromDate"
                    name="fromDate"
                    value={values.fromDate || ''}
                    setFieldValue={setFieldValue}
                    extraTimeProps={{format: 'HH:mm:ss'}}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="channelScheduleCalendarToDate">To</Label>
                  <DateTimeInputInSettingsZone
                    id="channelScheduleCalendarToDate"
                    name="toDate"
                    value={values.toDate || ''}
                    setFieldValue={setFieldValue}
                    extraTimeProps={{format: 'HH:mm:ss'}}
                  />
                </FormGroup>
                <div className="row">
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="channelScheduleCalendarPriority">Priority</Label>
                      <Input
                        type="number"
                        name="priority"
                        id="channelScheduleCalendarPriority"
                        value={values.priority || 0}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        min="0"
                        max="255"
                        required
                      />
                      <FormText color="muted">
                        Enter a value between 0 and 255
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="channelScheduleCalendarInterval">Repeating interval</Label>
                      <Input
                        type="select"
                        name="interval"
                        id="channelScheduleCalendarInterval"
                        value={values.interval || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Never repeats</option>
                        <option value="day"><ChannelIntervalDescription interval="day" date={values.fromDate} /></option>
                        <option value="week"><ChannelIntervalDescription interval="week" date={values.fromDate} /></option>
                        <option value="month"><ChannelIntervalDescription interval="month" date={values.fromDate} /></option>
                        <option value="year"><ChannelIntervalDescription interval="year" date={values.fromDate} /></option>
                      </Input>
                    </FormGroup>
                  </Col>
                </div>
                {isNew && <FormGroup>
                  <Label for="channelScheduleCalendarProgramId">Program</Label>
                  <Input
                    type="select"
                    name="programId"
                    id="channelScheduleCalendarProgramId"
                    value={values.programId || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  >
                    <option value="" disabled>Select the first program</option>
                    <ConnectedProgramListConsumer>
                      {programs => programs.map(program => (
                        <option key={program.id} value={program.id}>
                          <ProgramDescription {...program} />
                        </option>
                      ))}
                    </ConnectedProgramListConsumer>
                  </Input>
                </FormGroup>}
              </ModalBody>
              <ModalFooter>
                <SaveButton
                  isSaving={isSubmitting}
                  type="submit"
                  disabled={!isValid}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }

}
