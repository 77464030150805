import * as constants from 'client/redux/accountProgram/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

function getReducerForProgramWithOneImage(types) {
  const { ACTION_DELETE, ACTION_UPLOAD_RESPONSE } = types;
  const defaultProgramState = {
    isDeletingImage: false,
    imageError: null,
  };

  return function(state = defaultProgramState, action) {
    state = reduceByTypes(ACTION_DELETE, state, action, {
      requestProp: 'isDeletingImage',
      errorProp: 'imageError',
      successPickProps: [],
    });

    switch(action.type) {
      default: return state;
      case ACTION_UPLOAD_RESPONSE: return {
        ...state,
        imageError: action.error || null,
      };
    }
  };
}

const reduceProgramLogo = getReducerForProgramWithOneImage({
  ACTION_DELETE: constants.ACCOUNT_PROGRAM_LOGO_IMAGE_DELETE,
  ACTION_UPLOAD_RESPONSE: constants.ACCOUNT_PROGRAM_LOGO_IMAGE_UPLOAD_RESPONSE,
});

const reduceProgramGallery = getReducerForProgramWithOneImage({
  ACTION_DELETE: constants.ACCOUNT_PROGRAM_GALLERY_IMAGE_DELETE,
  ACTION_UPLOAD_RESPONSE: constants.ACCOUNT_PROGRAM_GALLERY_IMAGE_UPLOAD_RESPONSE,
});

const defaultState = {
  isReading: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
  readError: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_EDIT_READ, state, action, {
    requestProp: 'isReading',
    errorProp: 'readError',
    successPickProps: ['program'],
  });

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_EDIT_UPDATE, state, action, {
    requestProp: 'isUpdating',
    errorProp: 'error',
    successPickProps: ['program'],
  });

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_EDIT_UPDATE_SETTINGS, state, action, {
    requestProp: 'isUpdating',
    errorProp: 'error',
    successPickProps: ['program'],
  });

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_EDIT_DELETE, state, action, {
    requestProp: 'isDeleting',
    errorProp: 'error',
    successPickProps: [],
  });

  const programLogoActionTypes = [
    ...constants.ACCOUNT_PROGRAM_LOGO_IMAGE_DELETE,
    constants.ACCOUNT_PROGRAM_LOGO_IMAGE_UPLOAD_RESPONSE,
  ];
  if(programLogoActionTypes.includes(action.type)) {
    const responseBody = action.body || action.payload;
    return {
      ...state,
      programLogo: reduceProgramLogo(state.programLogo, action),
      program: responseBody && responseBody.program ? responseBody.program : state.program,
    };
  }

  // ugh, DRY with respect to programLogo
  const programGalleryActionTypes = [
    ...constants.ACCOUNT_PROGRAM_GALLERY_IMAGE_DELETE,
    constants.ACCOUNT_PROGRAM_GALLERY_IMAGE_UPLOAD_RESPONSE,
  ];
  if(programGalleryActionTypes.includes(action.type)) {
    const responseBody = action.body || action.payload;
    return {
      ...state,
      programGallery: reduceProgramGallery(state.programGallery, action),
      program: responseBody && responseBody.program ? responseBody.program : state.program,
    };
  }

  return state;
};
