import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { ButtonGroup } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { ButtonIcon } from 'client/components/Buttons';
import { ConfirmActionModalWrapper } from 'client/components/Modals';
import ProgramNew from 'client/components/ProgramIndexPage/ProgramNew';
import ProgramListFilterForm from 'client/components/ProgramIndexPage/ProgramListFilterForm';
import { ProgramType, ProgramDescription } from 'client/components/Program/ProgramHelpers';
import { SpinnerOverlay } from 'client/components/Spinners';

export default class ProgramList extends React.Component {

  static propTypes = {
    programs: propTypes.array,
    isLoading: propTypes.bool.isRequired,
    filter: propTypes.object.isRequired,
    onCreateProgram: propTypes.func.isRequired,
    onCloneProgram: propTypes.func.isRequired,
    onDeleteProgram: propTypes.func.isRequired,
    onUpdateFilter: propTypes.func.isRequired,
  }

  handleDelete = programId => {
    return this.props.onDeleteProgram(programId);
  }

  handleClone = programId => {
    return this.props.onCloneProgram(programId);
  }

  getFilteredPrograms() {
    const { programs, filter } = this.props;
    return (programs || []).filter(program => {
      switch(true) {
        default: return true;
        case filter.type && program.type !== filter.type: return false;
        case Boolean(filter.search): {
          const exp = new RegExp(filter.search, 'i');
          const matchesTitles = [
            _.get(program, 'title'),
            _.get(program, 'settings.subTitle'),
          ].filter(v => v).some(v => exp.test(v));
          if(!matchesTitles) return false;
        }
      }
      return true;
    });
  }

  render() {
    const {
      isLoading,
      onCreateProgram,
      onUpdateFilter,
      filter,
    } = this.props;
    const programs = this.getFilteredPrograms();
    return (
      <SpinnerOverlay color="primary" spinning={isLoading} showChildren>
        <div className="card card-default">
          <div className="card-body">
            <ProgramListFilterForm
              onUpdate={onUpdateFilter}
              filter={filter}
            />
            <table className="table table-striped table-bordered mb-0">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(!programs || programs.length < 1) && (
                  <tr>
                    <td colSpan="3">No programs created yet</td>
                  </tr>
                )}
                {programs && programs.map(program => (
                  <tr key={program.id}>
                    <td>
                      <NavLink to={`/programs/${program.id}`} exact>
                        <ProgramDescription {...program} type="" />
                      </NavLink>
                    </td>
                    <td><ProgramType type={program.type} /></td>
                    <td className="py-0 align-middle">
                      <div className="d-flex justify-content-end">
                        <ButtonGroup size="sm">
                          <ButtonIcon
                            title="Clone"
                            icon="ion-md-albums"
                            onClick={() => this.handleClone(program.id)}
                          />
                          <ConfirmActionModalWrapper
                            onConfirm={() => this.handleDelete(program.id)}
                            text="Are you sure you want to delete the program?"
                          >
                            {({handleOpen}) => (
                              <ButtonIcon
                                title="Delete"
                                icon="ion-md-trash"
                                onClick={handleOpen}
                              />
                            )}
                          </ConfirmActionModalWrapper>
                        </ButtonGroup>
                      </div>
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="bd-t d-flex justify-content-end card-footer">
            <ProgramNew onCreate={onCreateProgram} />
          </div>
        </div>
      </SpinnerOverlay>
    );
  }
}
