import React from 'react';
import { AlertCircle } from 'react-feather';
import './ViewPortAlert.css';

export default class ViewPortAlert extends React.Component {

  render() {
    const { children } = this.props;
    return (
      <div className="viewport-alert">
        <AlertCircle />
        <p>
          {children}
        </p>
      </div>
    );
  }

}
