import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DefaultPage as Page } from 'client/components/Pages';
import PageHeader from 'client/components/PageHeader';
import ChannelList from 'client/components/ChannelIndexPage/ChannelList';
import { ErrorAlert, ErrorModal } from 'client/components/ErrorHelpers';
import { ACCOUNT_CHANNEL_INDEX_CREATE } from 'client/redux/accountChannel/constants';
import * as indexActions from 'client/redux/accountChannel/indexActions';
import { push } from 'connected-react-router';

class ConnectedChannelIndexPage extends React.Component {

  handleCreateChannel = channelProps => {
    return this.props.accountChannelIndexCreate(channelProps).then(action => {
      if(action.type === ACCOUNT_CHANNEL_INDEX_CREATE.SUCCESS) {
        const newChannelId = _.get(action, 'payload.channel.id');
        return this.props.push(`/channels/${newChannelId}/repeat`);
      }
      return action;
    });
  }

  handleDeleteChannel = channelId => {
    return this.props.accountChannelIndexDelete(channelId);
  }

  componentDidMount() {
    this.props.accountChannelIndexList();
  }

  render() {
    const { isLoading, channels, modalError, pageError } = this.props;
    return (
      <Page>
        <PageHeader title="Channels" />
        <ErrorModal error={modalError} />
        <ErrorAlert error={pageError} />
        <ChannelList
          channels={channels}
          isLoading={isLoading}
          onCreateChannel={this.handleCreateChannel}
          onDeleteChannel={this.handleDeleteChannel}
        />
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountChannel.index,
});

const actions = {
  ...indexActions,
  push,
}

export default connect(mapStateToProps, actions)(ConnectedChannelIndexPage);
