import React from 'react';
import { Link } from 'react-router-dom';
import { SlimNavbar } from 'client/components/Slims';
import Icon from 'client/components/Icon';
import AppNavbarItem from 'client/components/App/AppNavbarItem';
import AppNavbarDropdownMenu from 'client/components/App/AppNavbarDropdownMenu';
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap';
import './AppNavbar.css';

export default class AppNavbar extends React.Component {

  render() {
    return (
      <SlimNavbar>
        <div className="container">
          <Nav>
            <AppNavbarItem exact to="/" icon="ion-ios-home">
              Dashboard
            </AppNavbarItem>
            <AppNavbarItem to="/channels" icon="ion-ios-tv">
              Channels
            </AppNavbarItem>
            <AppNavbarItem to="/programs" icon="ion-md-recording">
              Programs
            </AppNavbarItem>
            <UncontrolledDropdown nav className="with-sub">
              <DropdownToggle nav>
                <Icon icon="ion-ios-analytics" />
                Data
              </DropdownToggle>
              <AppNavbarDropdownMenu className="sub-item">
                <ul>
                  <li>
                    <Link to="/data/board">Board Data</Link>
                  </li>
                  <li>
                    <Link to="/data/timeSeries">Time Series Data</Link>
                  </li>
                  <li>
                    <Link to="/data/persons">Persons Data</Link>
                  </li>
                </ul>
              </AppNavbarDropdownMenu>
            </UncontrolledDropdown>
            <AppNavbarItem to="/log" icon="ion-ios-list">
              Log
            </AppNavbarItem>
            <AppNavbarItem to="/settings" icon="ion-ios-cog">
							Settings
            </AppNavbarItem>
          </Nav>
        </div>
      </SlimNavbar>
    );
  }
}
