import React from 'react';
import propTypes from 'prop-types';
import { SaveButton } from 'client/components/Buttons';
import momentTz from 'moment-timezone';
import { withFormik, Form } from 'formik';
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';
import ConnectedSettingsPhotoUpload from 'client/components/SettingsIndexPage/ConnectedSettingsPhotoUpload';

class SettingsForm extends React.Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
    isSubmitting: propTypes.bool.isRequired,
    initialValues: propTypes.object.isRequired,
  }

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      isSubmitting,
      dirty,
      invalid,
    } = this.props;
    const timezoneNames = momentTz.tz.names();
    return (
      <Form>
        <Card>
          <CardBody>
            <FormGroup row className="mb-0">
              <Label sm={2} for="settingsTimezone">Timezone</Label>
              <Col sm={10}>
                <Input
                  type="select"
                  required
                  name="timezone"
                  id="settingsTimezone"
                  value={values.timezone || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled value="">Select the timezone</option>
                  {timezoneNames.map(name => (
                    <option key={name} value={name}>{name}</option>
                  ))}
                </Input>
                <FormText color="muted">
                  Every time and date will be shown and entered into this timezone
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row className="mb-0">
              <Label sm={2} for="settingsPhoto">Company Logo</Label>
              <Col sm={10} className="pt-2">
                <ConnectedSettingsPhotoUpload />
              </Col>
            </FormGroup>
          </CardBody>
          <CardFooter className="bd-t d-flex justify-content-end">
            <div>
              <SaveButton
                size="sm"
                isSaving={isSubmitting}
                disabled={!dirty || invalid}
              />
            </div>
          </CardFooter>
        </Card>
      </Form>
    );
  }

}

const formikOptions = {
  displayName: 'SettingsForm',
  enableReinitialize: true,
  mapPropsToValues: props => ({
    timezone: '',
    ...(props.initialValues || {}),
  }),
  handleSubmit: (values, formikBag) => {
    const { props: { onSubmit } } = formikBag;
    onSubmit(values);
  },
};

export default withFormik(formikOptions)(SettingsForm);
