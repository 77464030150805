import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';
import { ButtonIcon } from 'client/components/Buttons';
import { ProgramDescription } from 'client/components/Program/ProgramHelpers';
import { rejectOnActionError } from 'client/redux/apiHelpers';
import { ConnectedProgramListConsumerOne } from 'client/components/Program/ProgramListProviders';
import ChannelSchedulerQueueFormModal from 'client/components/ChannelScheduler/ChannelSchedulerQueueFormModal';

export class ChannelSchedulerQueueTable extends React.Component {

  static propTypes = {
    onUpdate: propTypes.func.isRequired,
    onDelete: propTypes.func.isRequired,
    onMove: propTypes.func.isRequired,
    scheduleQueues: propTypes.array.isRequired,
  }

  static defaultProps = {
    scheduleQueues: [],
  }

  render() {
    const { scheduleQueues, onUpdate, onDelete, onMove } = this.props;
    return (
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Duration (sec)</th>
            <th>Program</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!scheduleQueues.length && (
            <tr>
              <td colSpan="5">There is nothing scheduled here</td>
            </tr>
          )}
          {scheduleQueues.map(({scheduleQueue}) => (
            <ChannelSchedulerQueueTableRow
              scheduleQueue={scheduleQueue}
              key={scheduleQueue.id}
              id={scheduleQueue.id}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onMove={onMove}
              totalScheduleQueuesLength={scheduleQueues.length}
            />
          ))}
        </tbody>
      </table>
    );
  }

}

export class ChannelSchedulerQueueTableRow extends React.Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    scheduleQueue: propTypes.object.isRequired,
    totalScheduleQueuesLength: propTypes.number.isRequired,
    onDelete: propTypes.func.isRequired,
    onUpdate: propTypes.func.isRequired,
    onMove: propTypes.func.isRequired,
  }

  state = {
    modalIsOpen: false,
  }

  handleToggleModal = ev => {
    this.setState(state => ({modalIsOpen: !state.modalIsOpen}));
  }

  handleSubmit = scheduleQueueProps => {
    const { id } = this.props;
    return this.props.onUpdate(id, scheduleQueueProps)
      .then(rejectOnActionError);
  }

  handleClickDelete = () => {
    const { id } = this.props;
    return this.props.onDelete(id);
  }

  handleClickMoveRight = () => {
    const { id } = this.props;
    return this.props.onMove(id, 'right');
  }

  handleClickMoveLeft = () => {
    const { id } = this.props;
    return this.props.onMove(id, 'left');
  }

  render() {
    const { modalIsOpen } = this.state;
    const { scheduleQueue, totalScheduleQueuesLength } = this.props;
    const {
      order,
      durationInSeconds,
      programId,
    } = scheduleQueue;
    const initialValues = _.pick(scheduleQueue, [
      'durationInSeconds',
      'programId',
    ]);
    return (
      <tr>
        <td>{order}</td>
        <td>{durationInSeconds}</td>
        <td>
          <ConnectedProgramListConsumerOne programId={programId}>
            {program => (
              <Link to={`/programs/${program.id}`}>
                <ProgramDescription {...program} />
              </Link>
            )}
          </ConnectedProgramListConsumerOne>
        </td>
        <td className="py-0 align-middle">
          <div className="d-flex justify-content-end">
            <ButtonGroup size="sm" className="mr-1">
              <ButtonIcon
                title="Move Up"
                icon="ion-md-arrow-round-up"
                disabled={order <= 1}
                onClick={this.handleClickMoveLeft}
              />
              <ButtonIcon
                title="Move Down"
                icon="ion-md-arrow-round-down"
                disabled={order >= totalScheduleQueuesLength}
                onClick={this.handleClickMoveRight}
              />
            </ButtonGroup>
            <ButtonGroup size="sm">
              <ButtonIcon
                title="Edit"
                icon="ion-md-create"
                onClick={this.handleToggleModal}
              />
              <ButtonIcon
                title="Delete"
                icon="ion-md-trash"
                onClick={this.handleClickDelete}
              />
            </ButtonGroup>
          </div>
          <ChannelSchedulerQueueFormModal
            isOpen={modalIsOpen}
            toggle={this.handleToggleModal}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
          />
        </td>
      </tr>
    );
  }

}
