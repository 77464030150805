import React from 'react';
import { Link } from 'react-router-dom';
import Copyright from 'client/components/Branding/Copyright';

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="page-error-wrapper">
        <div>
          <h1 className="error-title">404</h1>
          <h5 className="tx-sm-24 tx-normal">Oopps. The page you were looking for doesn't exist.</h5>
          <p className="mg-b-50">You may have mistyped the address or the page may have moved.</p>
          <p className="mg-b-50">
            <Link to="/" className="btn btn-error">Back to Home</Link>
          </p>
          <Copyright className="error-footer" />
        </div>
      </div> 
    );
  }
}
