import React from 'react';
import _ from 'lodash';
import { withFormik, Form } from 'formik';
import { FormikEffect } from 'client/components/FormHelpers';
import propTypes from 'prop-types';
import { Input, Label, FormGroup, Col } from 'reactstrap';
import { DateTimeInputInSettingsZone } from 'client/components/FormHelpers';

class ChannelSchedulerPreviewFilterForm extends React.Component {

  static propTypes = {
    query: propTypes.object.isRequired,
    onUpdate: propTypes.func.isRequired,
  }

  handleFormChange = (prevValues, nextValues) => {
    return this.props.onUpdate(nextValues);
  }

  render() {
    const {
      handleChange,
      handleBlur,
      setFieldValue,
      values,
    } = this.props;
    return (
      <Form>
        <FormikEffect onChangeValue={this.handleFormChange} />
        <div className="row">
          <Col md={6} sm={6}>
              <Label for="channelSchedulePreviewDateFrom">
                Date/Time
              </Label>
            <DateTimeInputInSettingsZone
              id="channelSchedulePreviewDateFrom"
              name="fromDate"
              value={values.fromDate || ''}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label
                for="channelSchedulePreviewMinutesAhead"
              >
                Minutes ahead to look
              </Label>
              <Input
                type="number"
                name="minutesAhead"
                id="channelSchedulePreviewMinutesAhead"
                value={values.minutesAhead || 0}
                onChange={handleChange}
                onBlur={handleBlur}
                min="0"
                max="60"
                required
              />
            </FormGroup>
          </Col>
        </div>
      </Form>
    );
  }

}

const formikProps = {
  displayName: 'ChannelSchedulerPreviewFilterForm',
  mapPropsToValues: props => ({
    fromDate: _.get(props, 'query.fromDate', (new Date()).toISOString()),
    minutesAhead: _.get(props, 'query.minutesAhead', 0),
  }),
};

export default withFormik(formikProps)(ChannelSchedulerPreviewFilterForm);
