import * as actionCreators from 'client/redux/accountProgram/actionCreators';
import * as constants from 'client/redux/accountProgram/constants';

export const accountProgramEditRead = (programId) => dispatch => dispatch(actionCreators.read(constants.ACCOUNT_PROGRAM_EDIT_READ, programId));

export const accountProgramEditUpdate = (programId, programUpdate) => dispatch => dispatch(actionCreators.update(constants.ACCOUNT_PROGRAM_EDIT_UPDATE, programId, programUpdate));

export const accountProgramEditUpdateSettings = (programId, programType, programUpdate) => dispatch => dispatch(actionCreators.updateSettings(constants.ACCOUNT_PROGRAM_EDIT_UPDATE_SETTINGS, programId, programType, programUpdate));

export const accountProgramEditDelete = (programId) => dispatch => dispatch(actionCreators.del(constants.ACCOUNT_PROGRAM_EDIT_DELETE, programId));

export const accountProgramLogoImageUploadResponse = (error, body) => dispatch => dispatch({
  type: constants.ACCOUNT_PROGRAM_LOGO_IMAGE_UPLOAD_RESPONSE,
  error,
  body,
});

export const accountProgramLogoImageDelete = (programId) => dispatch => dispatch(actionCreators.programLogoImageDelete(constants.ACCOUNT_PROGRAM_LOGO_IMAGE_DELETE, programId));

export const accountProgramGalleryImageUploadResponse = (error, body) => dispatch => dispatch({
  type: constants.ACCOUNT_PROGRAM_GALLERY_IMAGE_UPLOAD_RESPONSE,
  error,
  body,
});

export const accountProgramGalleryImageDelete = (programId) => dispatch => dispatch(actionCreators.programGalleryImageDelete(constants.ACCOUNT_PROGRAM_GALLERY_IMAGE_DELETE, programId));
