import { combineReducers } from 'redux';
import index from 'client/redux/accountProgram/indexReducer';
import data from 'client/redux/accountProgram/dataReducer';
import edit from 'client/redux/accountProgram/editReducer';
import list from 'client/redux/accountProgram/listReducer';
import programPreview from  'client/redux/accountProgram/programPreviewReducer';

const accountProgramReducer = combineReducers({
  data,
  edit,
  index,
  list,
  programPreview,
});

export default accountProgramReducer;
