import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class ConnectedAuthedRoute extends React.Component {

  render() {
    const { isLoggedIn, ...rest } = this.props;

    if(!isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return <Route {...rest} />
  }

}

const mapStateToProps = state => ({
  ...state.auth,
});

export default connect(mapStateToProps)(ConnectedAuthedRoute);
