import React from 'react';
import propTypes from 'prop-types';
import ViewPortProgramLeaderboard from 'client/components/ViewPortProgram/ViewPortProgramLeaderboard';
import ViewPortProgramQualificationboard from 'client/components/ViewPortProgram/ViewPortProgramQualificationboard';
import ViewPortProgramSumboard from 'client/components/ViewPortProgram/ViewPortProgramSumboard';
import ViewPortProgramLogo from 'client/components/ViewPortProgram/ViewPortProgramLogo';
import ViewPortProgramGallery from 'client/components/ViewPortProgram/ViewPortProgramGallery';
import ViewPortProgramPersonfocus from 'client/components/ViewPortProgram/ViewPortProgramPersonfocus';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import './ViewPort.css';

export default class ViewPort extends React.Component {

  static propTypes = {
    scene: propTypes.object,
    transitionState: propTypes.oneOf(['entering', 'entered', 'exiting', 'exited']),
  }

  renderScene() {
    const { scene, transitionState} = this.props;
    if(!scene) return null;
    const { type, state } = scene;
    const programProps = {...state, transitionState};
    switch(type) {
      default: return null;
      case 'gallery': return <ViewPortProgramGallery {...programProps} />
      case 'leaderboard': return <ViewPortProgramLeaderboard {...programProps} />
      case 'logo': return <ViewPortProgramLogo {...programProps} />
      case 'personfocus': return <ViewPortProgramPersonfocus {...programProps} />
      case 'qualificationboard': return <ViewPortProgramQualificationboard {...programProps} />
      case 'sumboard': return <ViewPortProgramSumboard {...programProps} />
    }
  }

  render() {
    const title = _.get(this.props, 'scene.state.title');
    return (
      <div className="viewport">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {this.renderScene()}
      </div>
    );
  }
}
