import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';
import { ButtonIcon } from 'client/components/Buttons';
import { ProgramDescription } from 'client/components/Program/ProgramHelpers';
import { rejectOnActionError } from 'client/redux/apiHelpers';
import { ConnectedProgramListConsumerOne } from 'client/components/Program/ProgramListProviders';
import ChannelSchedulerCalendarFormModal from 'client/components/ChannelScheduler/ChannelSchedulerCalendarFormModal';
import ChannelSchedulerCalendarQueueModal from 'client/components/ChannelScheduler/ChannelSchedulerCalendarQueueModal';
import { ChannelCompleteIntervalDescription } from 'client/components/Channel/ChannelHelpers';

export class ChannelSchedulerCalendarTable extends React.Component {

  static propTypes = {
    onUpdate: propTypes.func.isRequired,
    onDelete: propTypes.func.isRequired,
    onRefresh: propTypes.func.isRequired,
    scheduleCalendars: propTypes.array.isRequired,
  }

  render() {
    const { scheduleCalendars, onUpdate, onDelete, onRefresh } = this.props;
    return (
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Time</th>
            <th>Priority</th>
            <th>Program/Queue</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {scheduleCalendars.length < 1 && (
            <tr>
              <td colSpan="5">There is nothing scheduled here</td>
            </tr>
          )}
          {scheduleCalendars.map(scheduleCalendar => (
            <ChannelSchedulerCalendarTableRow
              key={scheduleCalendar.id}
              id={scheduleCalendar.id}
              scheduleCalendar={scheduleCalendar}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onRefresh={onRefresh}
            />
          ))}
        </tbody>
      </table>
    );
  }

}

export class ChannelSchedulerCalendarTableRow extends React.Component {

  static propTypes = {
    id: propTypes.string.isRequired,
    scheduleCalendar: propTypes.object.isRequired,
    onDelete: propTypes.func.isRequired,
    onUpdate: propTypes.func.isRequired,
    onRefresh: propTypes.func.isRequired,
  }

  state = {
    editModalIsOpen: false,
    queueModalIsOpen: false,
  }

  handleToggleEditModal = ev => {
    this.setState(state => ({
      editModalIsOpen: !state.editModalIsOpen,
      queueModalIsOpen: false,
    }));
  }

  handleToggleEditQueueModal = ev => {
    this.setState(state => ({
      queueModalIsOpen: !state.queueModalIsOpen,
      editModalIsOpen: false,
    }), () => {
      if(!this.state.queueModalIsOpen) {
        this.props.onRefresh();
      }
    });
  }

  handleSubmit = scheduleCalendarProps => {
    const { id } = this.props;
    return this.props.onUpdate(id, scheduleCalendarProps)
      .then(rejectOnActionError);
  }

  handleClickDelete = () => {
    const { id } = this.props;
    return this.props.onDelete(id);
  }

  render() {
    const { editModalIsOpen, queueModalIsOpen } = this.state;
    const { scheduleCalendar } = this.props;
    const {
      id,
      fromDate,
      toDate,
      interval,
      priority,
      ChannelScheduleQueues,
    } = scheduleCalendar;

    const initialValues = _.pick(scheduleCalendar, [
      'interval',
      'priority',
      'fromDate',
      'toDate',
    ]);

    return (
      <tr>
        <td className="align-middle">
          <ChannelSchedulerCalendarDuration
            from={fromDate}
            to={toDate}
            interval={interval}
          />
        </td>
        <td className="align-middle">{priority || '0'}</td>
        <td>
          <ChannelSchedulerCalendarTableQueueCell
            queues={ChannelScheduleQueues}
          />
        </td>
        <td className="py-0 align-middle">
          <ButtonGroup className="float-right" size="sm">
            <ButtonIcon
              title="Edit program queue"
              icon="ion-ios-list-box"
              onClick={this.handleToggleEditQueueModal}
            />
            <ButtonIcon
              title="Edit"
              icon="ion-md-create"
              onClick={this.handleToggleEditModal}
            />
            <ButtonIcon
              title="Delete"
              icon="ion-md-trash"
              onClick={this.handleClickDelete}
            />
          </ButtonGroup>
          <ChannelSchedulerCalendarFormModal
            isOpen={editModalIsOpen}
            toggle={this.handleToggleEditModal}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
          />
          <ChannelSchedulerCalendarQueueModal
            channelScheduleCalendarId={id}
            isOpen={queueModalIsOpen}
            toggle={this.handleToggleEditQueueModal}
          />
        </td>
      </tr>
    );
  }

}

export class ChannelSchedulerCalendarDuration extends React.Component {

  static propTypes = {
    from: propTypes.string.isRequired,
    to: propTypes.string.isRequired,
    interval: propTypes.string,
  }

  render() {
    const { from, to, interval } = this.props;
    return (
      <span>
        <ChannelCompleteIntervalDescription
          interval={interval}
          from={from}
          to={to}
        />
      </span>
    );
  }
}

export class ChannelSchedulerCalendarTableQueueCell extends React.Component {

  static propTypes = {
    queues: propTypes.array.isRequired,
  }

  static defaultProps = {
    queues: [],
  }

  render() {
    const { queues } = this.props;
    return (
      <ol className="pl-3 mb-0">
        {queues.map(({programId}) => (
          <li key={programId}>
            <ConnectedProgramListConsumerOne programId={programId}>
              {program => (
                <Link to={`/programs/${program.id}`}>
                  <ProgramDescription {...program} />
                </Link>
              )}
            </ConnectedProgramListConsumerOne>
          </li>
        ))}
      </ol>
    );
  }

}
