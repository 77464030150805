import React from 'react';
import propTypes from 'prop-types';
import Icon from 'client/components/Icon';
import { ButtonSpinnerPromise } from 'client/components/Buttons';
import {
  Modal,
  ModalBody,
} from 'reactstrap';

export class ModalCloseButton extends React.Component {

  static propTypes = {
    toggle: propTypes.func.isRequired,
  }

  render() {
    const { toggle } = this.props;
    return (
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggle}>
        <span aria-hidden="true">×</span>
      </button>
    );
  }
}

export class ConfirmActionModal extends React.Component {

  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    onConfirm: propTypes.func.isRequired,
    headerText: propTypes.string,
    text: propTypes.string,
  }

  static defaultProps = {
    headerText: 'Warning: This action is irreversible',
    text: 'Are you sure you wish to continue?',
  }

  handleClickConfirm = ev => {
    const { onConfirm, toggle } = this.props;
    const promise = onConfirm(ev);
    promise.then(() => toggle(), null);
    return promise;
  }

  render() {
    const { isOpen, toggle, headerText, text } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody className="tx-center pd-y-20 pd-x-20">
          <ModalCloseButton toggle={toggle} />
          <Icon icon="ion-ios-alert" className="tx-100 tx-warning lh-1 mg-t-20 d-inline-block" />
          <h4 className="tx-warning mg-b-20">{headerText}</h4>
          <p className="mg-b-20 mg-x-20">{text}</p>
          <ButtonSpinnerPromise
            color="warning"
            className="pd-x-25"
            aria-label="Close"
            onClick={this.handleClickConfirm}
          >
            Yes, I'm sure
          </ButtonSpinnerPromise>
        </ModalBody>
      </Modal>
    )
  }

}

export class ConfirmActionModalWrapper extends React.Component {

  static propTypes = {
    children: propTypes.func.isRequired,
  }

  state = {
    isOpen: false,
  }

  handleOpen = () => {
    this.setState({isOpen: true});
  }

  handleClose = () => {
    if(!this.willUnmount) {
      this.setState({isOpen: false});
    }
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  render() {
    const { isOpen } = this.state;
    const { children, ...confirmActionModalProps } = this.props;
    return (
      <>
        <ConfirmActionModal
          {...confirmActionModalProps}
          isOpen={isOpen}
          toggle={this.handleClose}
        />
        {children({handleOpen: this.handleOpen})}
      </>
    );
  }

}
