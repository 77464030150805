import rootReducer from './rootReducer';
import { createBrowserHistory } from 'history';
import { compose, createStore, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { apiMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import apiErrorInterceptorMiddleware from 'client/redux/apiErrorInterceptorMiddleware';
import errorMiddleware from 'client/redux/errorMiddleware';
import { createLogger } from 'redux-logger';
import socketio from 'socket.io-client';
import websocketMiddleware from 'client/redux/websocket/websocketMiddleware';

const io = socketio();

export const history = createBrowserHistory();

const middleware = [
  thunkMiddleware,
  apiMiddleware,
  apiErrorInterceptorMiddleware,
  errorMiddleware,
  createLogger({}),
  routerMiddleware(history),
  websocketMiddleware(io, 'websocket/'),
];

export default function configureStore(initialState = {}) {
  const middlewares = [
    applyMiddleware(...middleware),
  ];
  if(window.__REDUX_DEVTOOLS_EXTENSION__) {
    middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const persistedRootReducer = persistReducer({
    key: 'root',
    whitelist: ['tvOutputAuth', 'auth'],
    storage,
  }, rootReducer);

  const enhancer = compose(...middlewares);
  const store = createStore(connectRouter(history)(persistedRootReducer), {}, enhancer);

  const persistor = persistStore(store);

  return { persistor, store };
};
