import { apiAction } from 'client/redux/apiHelpers';

export const channelReadState = (types, channelId, channelPassword) => {
  return apiAction({
    endpoint: `/api/channel/${channelId}/state`,
    method: 'GET',
    headers: {
      'X-Channel': `${channelId}:${channelPassword}`,
    },
    types: types.map(type => ({type, meta: {channelId}})),
  });
};

