import React from 'react';
import propTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import _ from 'lodash';
import { stringify } from 'qs';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

export function LinkQuery({query = {}, to, children, ...rest}) {
  const toObject = _.isString(to) ? {pathname: to} : to;
  toObject.search = stringify(query);
  return <Link {...rest} to={toObject}>{children}</Link>;
};

export function NumberFormatUnit({value, unit, className}) {
  return (
    <NumberFormat
      isNumericString
      className={className}
      displayType="text"
      thousandSeparator=" "
      value={value}
      suffix={unit ? ' ' + unit : ''}
    />
  );
}

export class ComponentTransitionCycler extends React.Component {

  static propTypes = {
    cycles: propTypes.array.isRequired,
    children: propTypes.func.isRequired,
    initialIndex: propTypes.number,
    transitionTime: propTypes.number.isRequired,
    cycleTime: propTypes.number.isRequired,
  }

  static defaultProps = {
    initialIndex: 0,
    transitionTime: 500,
    cycleTime: 10000,
  }

  constructor(props) {
    super(props);
    this.state = {index: props.initialIndex, isIn: true};
  }

  cycleBegin = () => {
    this.setState({isIn: false});
  }

  cycleComplete = () => {
    const { cycleTime, cycles } = this.props;
    this.setState(prevState => ({
      index: (prevState.index + 1) % cycles.length,
      isIn: true,
    }), () => {
      this.cycleTimeoutId = setTimeout(this.cycleBegin, cycleTime);
    });
  }

  componentDidMount() {
    const { cycleTime, cycles } = this.props;
    if(cycles.length > 1) {
      this.cycleTimeoutId = setTimeout(this.cycleBegin, cycleTime);
    }
  }

  componentDidUpdate(prevProps) {
    const { cycleTime, cycles } = this.props;
    if(cycles.length !== prevProps.cycles.length) {
      if(cycles.length > 1) {
        this.cycleTimeoutId = setTimeout(this.cycleBegin, cycleTime);
      } else if(this.cycleTimeoutId) {
        this.setState({index: 0, isIn: true});
        clearTimeout(this.cycleTimeoutId);
      }
    }
  }

  componentWillUnmount() {
    if(this.cycleTimeoutId) clearTimeout(this.cycleTimeoutId);
  }

  render() {
    const { children, transitionTime, cycles } = this.props;
    const { index, isIn } = this.state;
    const active = cycles[index];
    return (
      <Transition
        timeout={transitionTime}
        mountOnEnter
        in={isIn}
        onExited={this.cycleComplete}
      >
        {transitionState => children({transitionState, active})}
      </Transition>
    );
  }

}
