import * as actionCreators from 'client/redux/accountProgram/actionCreators';
import * as constants from 'client/redux/accountProgram/constants';

export const accountProgramIndexFilter = (filter = {}) => dispatch => dispatch({
  type: constants.ACCOUNT_PROGRAM_INDEX_FILTER,
  filter,
});

export const accountProgramIndexList = () => dispatch => dispatch(actionCreators.list(constants.ACCOUNT_PROGRAM_INDEX_LIST));

export const accountProgramIndexDelete = (programId) => dispatch => dispatch(actionCreators.del(constants.ACCOUNT_PROGRAM_INDEX_DELETE, programId));

export const accountProgramIndexCreate = (programProps) => dispatch => dispatch(actionCreators.create(constants.ACCOUNT_PROGRAM_INDEX_CREATE, programProps));

export const accountProgramIndexClone = (programId) => dispatch => dispatch(actionCreators.clone(constants.ACCOUNT_PROGRAM_INDEX_CLONE, programId));
