import _ from 'lodash';
import axios from 'axios'
import { responseToErrorMessage } from 'client/redux/apiHelpers';

export function uploadTo(url, fileName, fileHandle, progressCallback) {
  const config = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      progressCallback(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = new FormData();
  data.append(fileName, fileHandle);

  return axios.post(url, data, config).then(res => {
    progressCallback(null);
    return res;
  }).catch(err => {
    progressCallback(null);
    const errorBody = _.get(err, 'response.data');
    const errorMsg = responseToErrorMessage(errorBody, true);
    throw new Error(errorMsg);
  });
}
