import * as constants from 'client/redux/accountProgram/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  programs: null,
  pageError: null,
  modalError: null,
  filter: {},
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_INDEX_LIST, state, action, {
    requestProp: 'isLoading',
    errorProp: 'pageError',
    successPickProps: ['programs'],
  });

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_INDEX_CREATE, state, action, {
    requestProp: 'isCreating',
    errorProp: null, // errors are dealt with in the creation modal
    successProp: null,
  });

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_INDEX_DELETE, state, action, {
    requestProp: 'isDeleting',
    errorProp: 'modalError',
    successProp: null,
  });

  state = reduceByTypes(constants.ACCOUNT_PROGRAM_INDEX_CLONE, state, action, {
    requestProp: 'isCloning',
    errorProp: 'pageError',
    successProp: null,
  });

  switch(action.type) {
    default: return state;
    case constants.ACCOUNT_PROGRAM_INDEX_FILTER: return {
      ...state,
      filter: action.filter || {},
    };
    case constants.ACCOUNT_PROGRAM_INDEX_DELETE.SUCCESS: return {
      ...state,
      programs: state.programs.filter(({id}) => id !== action.meta.programId),
    };
  }
};
