import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { SaveButton } from 'client/components/Buttons';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { Formik, Form } from 'formik';
import ChannelForm from 'client/components/ChannelEditPage/ChannelForm';

export default class ChannelEditor extends React.Component {

  static propTypes = {
    channel: propTypes.object.isRequired,
    error: propTypes.object,
    onUpdate: propTypes.func.isRequired,
    isUpdating: propTypes.bool.isRequired,
  }

  render() {
    const { error, channel, onUpdate, isUpdating, className } = this.props;
    const initialValues = _.pick(channel, 'name', 'isActive', 'password', 'id');
    return (
      <Formik initialValues={initialValues} onSubmit={onUpdate} enableReinitialize>
        {formikProps => (
          <Form>
            <Card className={className}>
              <CardBody>
                <ErrorAlert error={error} />
                <ChannelForm
                  formikProps={formikProps}
                />
              </CardBody>
              <CardFooter className="bd-t d-flex justify-content-end">
                <div>
                  <SaveButton
                    size="sm"
                    isSaving={isUpdating}
                    disabled={!formikProps.dirty}
                    onClick={formikProps.handleSubmit}
                  />
                </div>
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}
