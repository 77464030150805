import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as settingsActions from 'client/redux/accountSettings/settingsActions';

const defaultSettings = {
  timezone: 'UTC',
};

const SettingsContext = React.createContext(defaultSettings);

export default SettingsContext;

class SettingsContextProvider extends React.Component {

  componentDidMount() {
    const { isLoggedIn } = this.props;
    if(isLoggedIn) {
      this.props.accountSettingsList();
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn } = this.props;
    if(isLoggedIn && prevProps.isLoggedIn !== isLoggedIn) {
      this.props.accountSettingsList();
    }
  }

  render() {
    const { settings = defaultSettings, children } = this.props;
    return (
      <SettingsContext.Provider value={settings}>
        {children}
      </SettingsContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: _.get(state, 'auth.isLoggedIn', false),
  // ugh...
  settings: {
    ..._.get(state, 'accountSettings.settings.settings', {}),
    photo: _.get(state, 'accountSettings.settings.photo'),
  },
});

const actions = {
  ...settingsActions,
};

export const ConnectedSettingsContextProvider = connect(mapStateToProps, actions)(SettingsContextProvider);
