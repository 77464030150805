import * as constants from 'client/redux/accountChannel/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isUpdating: false,
  error: null,
  readError: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_EDIT_READ, state, action, {
    requestProp: 'isReading',
    errorProp: 'readError',
    successPickProps: ['channel'],
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_EDIT_UPDATE, state, action, {
    requestProp: 'isUpdating',
    errorProp: 'error',
    successPickProps: ['channel'],
  });

  return state;
};
