const defaultState = {
  connected: false,
  connectError: null,
  reconnectionAttempts: 0,
  error: null,
};

export default function(state = defaultState, action) {
  switch(action.type) {
    case 'WEBSOCKET_CONNECTED': return {
      ...state,
      connected: true,
      connectError: null,
      reconnectionAttempts: 0,
    };

    case 'WEBSOCKET_RECONNECT_ATTEMPT': return {
      ...state,
      reconnectionAttempts: state.reconnectionAttempts + 1,
    };

    case 'WEBSOCKET_ERROR': return {
      ...state,
      error: action.error,
    };

    case 'WEBSOCKET_DISCONNECTED': return {
      ...state,
      connected: false,
    };
    
    case 'WEBSOCKET_CONNECT_ERROR': return {
      ...state,
      connected: false,
      connectError: action.error,
    };

    default: return state;
  }
};
