import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { Col, FormGroup, FormText, Label, Input } from 'reactstrap';

export default class ProgramSettingTimeseries extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  getValues() {
    return _.get(this.props, 'formikProps.values.settings.timeSeries', {});
  }

  renderRelativeForm() {
    const { handleChange, handleBlur } = this.props.formikProps;
    const timeSeries = this.getValues();
    return (
      <FormGroup row>
        <Label sm={2} for="programSettingTimeSeriesPeriod">Period</Label>
        <Col sm={10} className="row">
          <Col sm={6}>
            <Input
              type="select"
              name="settings.timeSeries.period"
              id="programSettingTimeSeriesPeriod"
              value={timeSeries.period || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" disabled>Choose Period</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </Input>
          </Col>
          <Col sm={6}>
            <Input
              type="number"
              name="settings.timeSeries.offset"
              min="0"
              id="programSettingTimeSeriesOffset"
              onChange={handleChange}
              onBlur={handleBlur}
              value={timeSeries.offset || 0}
            />
            <FormText color="muted">0 is the current {timeSeries.period}, 1 is the previous {timeSeries.period} and so on</FormText>
          </Col>
        </Col>
      </FormGroup>
    );
  }

  renderDateForm() {
    const { handleChange, handleBlur } = this.props.formikProps;
    const timeSeries = this.getValues();
    return (
      <FormGroup row>
        <Label sm={2} for="programSettingTimeSeriesFrom">Date range</Label>
        <Col sm={10} className="row">
          <Col sm={6}>
            <Input
              type="date"
              name="settings.timeSeries.dateFrom"
              id="programSettingTimeSeriesFrom"
              onChange={handleChange}
              onBlur={handleBlur}
              value={timeSeries.dateFrom || ''}
            />
            <FormText color="muted">From this date (inclusive)</FormText>
          </Col>
          <Col sm={6}>
            <Input
              type="date"
              name="settings.timeSeries.dateTo"
              id="programSettingTimeSeriesTo"
              onChange={handleChange}
              onBlur={handleBlur}
              value={timeSeries.dateTo || ''}
            />
            <FormText color="muted">To this date (exclusive)</FormText>
          </Col>
        </Col>
      </FormGroup>
    );
  }

  render() {
    const { handleChange, handleBlur } = this.props.formikProps;
    const timeSeries = this.getValues();
    return (
      <div>
        <FormGroup row>
          <legend className="col-form-label col-sm-2 py-0">Time series type</legend>
          <Col sm={10}>
            <div className="row">
              <Col sm={2}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="settings.timeSeries.range"
                      value="relative"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={timeSeries.range === 'relative'}
                    />{' '}
                    Relative period
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="settings.timeSeries.range"
                      value="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={timeSeries.range === 'date'}
                    />{' '}
                    Date range
                  </Label>
                </FormGroup>
              </Col>
            </div>
          </Col>
        </FormGroup>
        {timeSeries.range === 'relative' && this.renderRelativeForm()}
        {timeSeries.range === 'date' && this.renderDateForm()}
      </div>
    );
  }

}
