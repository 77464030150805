import React from 'react';
import propTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import ConnectedChannelSchedulerCalendarQueueEditor from 'client/components/ChannelScheduler/ConnectedChannelSchedulerCalendarQueueEditor';

export default class ChannelSchedulerCalendarQueueModal extends React.Component {

  static propTypes = {
    channelScheduleCalendarId: propTypes.string.isRequired,
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
  }

  render() {
    const { channelScheduleCalendarId, isOpen, toggle } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{maxWidth: 'none'}}>
        <ModalHeader toggle={toggle}>Edit Calendar Queue</ModalHeader>
        <ModalBody>
          <ConnectedChannelSchedulerCalendarQueueEditor
            channelScheduleCalendarId={channelScheduleCalendarId}
          />
        </ModalBody>
      </Modal>
    );
  }

}
