export const websocketAddEmitOnConnect = (emitId, socketEventName, ...args) => dispatch => {
  return dispatch({type: 'WEBSOCKET_ADD_EMIT_ON_CONNECT', emitId, socketEventName, args});
};

export const websocketAddEmitOnConnectAck = (emitId, socketEventName, onAckEventName, ...args) => dispatch => {
  return dispatch({type: 'WEBSOCKET_ADD_EMIT_ON_CONNECT', emitId, onAckEventName, socketEventName, args});
};

export const websocketRemoveEmitOnConnect = (emitId) => dispatch => {
  return dispatch({type: 'WEBSOCKET_REMOVE_EMIT_ON_CONNECT', emitId});
};

export const websocketEmit = (socketEventName, ...args) => dispatch => {
  return dispatch({type: 'WEBSOCKET_EMIT', socketEventName, args});
};

export const websocketSubscribe = (socketEventName, onMessageEventName) => dispatch => {
  return dispatch({type: 'WEBSOCKET_SUBSCRIBE', socketEventName, onMessageEventName});
};

export const websocketUnsubscribe = (socketEventName) => dispatch => {
  return dispatch({type: 'WEBSOCKET_UNSUBSCRIBE', socketEventName});
};
