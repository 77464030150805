import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'client/components/Spinners';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import * as previewActions from 'client/redux/accountChannel/schedulePreviewActions';
import ChannelSchedulerPreviewFilterForm from 'client/components/ChannelScheduler/ChannelSchedulerPreviewFilterForm';
import { ChannelSchedulerPreviewTable } from 'client/components/ChannelScheduler/ChannelSchedulerPreviewTableHelpers';
import { format } from 'url';
import * as query from 'client/helpers/query';

class ConnectedChannelSchedulerPreview extends React.Component {

  static propTypes = {
    channelId: propTypes.string.isRequired,
  }

  fetchData = _.debounce(query => {
    const { channelId } = this.props;
    const { fromDate:rawFromDate, minutesAhead } = query;
    const fromDate = moment(rawFromDate).startOf('minute').toISOString();
    const toDate = moment(fromDate).startOf('minute').add(minutesAhead || 0, 'minutes').toISOString();
    return this.props.schedulePreviewRead(channelId, {fromDate, toDate});
  }, 300, {leading: true})

  componentDidMount() {
    return this.fetchData(this.getQuery(this.props));
  }

  componentDidUpdate(prevProps) {
    const prevQuery = this.getQuery(prevProps);
    const nextQuery = this.getQuery(this.props);
    if(!_.isEqual(prevQuery, nextQuery)) {
      return this.fetchData(nextQuery);
    }
  }

  getQuery(props) {
    return query.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
  }

  handleFilterUpdate = query => {
    const { pathname } = this.props.location;
    this.props.push(format({
      pathname,
      query,
    }));
    this.props.schedulePreviewSetFilter(query);
  }

  render() {
    const { isReading, error, channelSchedule } = this.props;
    return (
      <div className="pt-3">
        <ErrorAlert error={error} />
        <ChannelSchedulerPreviewFilterForm
          onUpdate={this.handleFilterUpdate}
          query={this.getQuery(this.props)}
        />
        <SpinnerOverlay color="primary" spinning={isReading} centerX centerY showChildren>
          {channelSchedule && <ChannelSchedulerPreviewTable channelSchedule={channelSchedule} />}
        </SpinnerOverlay>
      </div>
    );
  }

}


const mapStateToProps = state => ({
  ...state.accountChannel.schedulePreview,
});

const actions = {
  ...previewActions,
  push,
};

export default withRouter(connect(mapStateToProps, actions)(ConnectedChannelSchedulerPreview));
