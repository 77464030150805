import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const list = (types, query, meta = {}) => apiAction({
  endpoint: appendQuery('/api/account/program', query),
  method: 'GET',
  types: types.map(type => ({type, meta})),
});

export const create = (types, programProps) => apiAction({
    endpoint: '/api/account/program',
    method: 'POST',
    json: programProps,
    types: types.map(type => ({type, meta: {programProps}})),
});

export const clone = (types, programId) => apiAction({
  endpoint: `/api/account/program/${programId}/clone`,
    method: 'POST',
    json: {},
    types: types.map(type => ({type, meta: {programId}})),
});

export const del = (types, programId) => apiAction({
  endpoint: `/api/account/program/${programId}`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {programId}})),
});

export const update = (types, programId, programUpdate) => apiAction({
  endpoint: `/api/account/program/${programId}`,
  method: 'PATCH',
  json: programUpdate,
  types: types.map(type => ({type, meta: {programId, programUpdate}})),
});

export const updateSettings = (types, programId, programType, programSettingsUpdate) => apiAction({
  endpoint: `/api/account/program/${programId}/${programType}/settings`,
  method: 'PUT',
  json: programSettingsUpdate,
  types: types.map(type => ({type, meta: {programId, programType, programSettingsUpdate}})),
});

export const read = (types, programId) => apiAction({
  endpoint: `/api/account/program/${programId}`,
  method: 'GET',
  types: types.map(type => ({type, meta: {programId}})),
});

export const readScene = (types, programId) => apiAction({
  endpoint: `/api/account/program/${programId}/scene`,
  method: 'GET',
  types: types.map(type => ({type, meta: {programId}})),
});

export const programLogoImageDelete = (types, programId) => apiAction({
  endpoint: `/api/account/program/${programId}/logo/settings/logoImage`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {programId}})),
});

export const programGalleryImageDelete = (types, programId) => apiAction({
  endpoint: `/api/account/program/${programId}/gallery/settings/image`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {programId}})),
});
