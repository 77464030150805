import React from 'react';
import propTypes from 'prop-types';
import SpinkitSpinner from 'react-spinkit';
import classNames from 'classnames';
import './Spinners.css';

const COLORS = {
  primary: '#1b84e7',
  secondary: '#6c757d',
  success: '#23BF08',
  info: '#5B93D3',
  warning: '#F49917',
  danger: '#dc3545',
  light: '#f8f9fa',
  dark: '#343a40',
  white: '#fff',
};

export class SpinnerOverlay extends React.Component {

  static propTypes = {
    showChildren: propTypes.bool,
    fadeIn: propTypes.string,
    centerX: propTypes.bool,
    centerY: propTypes.bool,
    spinning: propTypes.bool,
    color: propTypes.string,
  }

  static defaultProps = {
    showChildren: false,
    fadeIn: 'quarter',
    centerX: false,
    centerY: false,
    spinning: false,
    color: 'rgba(255,255,255,1)',
  }

  getColor() {
    const { color } = this.props;
    return color in COLORS ? COLORS[color] : color;
  }

  renderChildren() {
    const { spinning, showChildren, children } = this.props;
    if(!spinning) return children;
    return showChildren ? children : null;
  }

  renderSpinner() {
    const { centerX, centerY, fadeIn } = this.props;
    const classes = classNames('spinner-container', centerX ? 'center-x' : null, centerY ? 'center-y' : null);
    return (
      <div className={classes}>
        <SpinkitSpinner
          fadeIn={fadeIn}
          name="three-bounce"
          color={this.getColor()}
        />
      </div>
    )
  }

  handleClick = ev => {
    const { spinning } = this.props;
    if(spinning) ev.preventDefault();
  }

  render() {
    const { className, spinning } = this.props;
    const classes = classNames(className, 'spinner-overlay-container', spinning ? 'active' : null);
    return (
      <div className={classes} onClick={this.handleClick}>
        {spinning && this.renderSpinner()}
        <div className="spinner-children">
          {this.renderChildren()}
        </div>
      </div>
    );
  }

}
