import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChannelSchedulerQueueFormModal from 'client/components/ChannelScheduler/ChannelSchedulerQueueFormModal';

export default class ChannelSchedulerQueueAddButton extends React.Component {

  static propTypes = {
    onCreate: propTypes.func.isRequired,
  }

  state = {
    modalIsOpen: false,
  }

  initialValues = {
    durationInSeconds: 0,
  }

  handleToggleModal = ev => {
    this.setState(state => ({modalIsOpen: !state.modalIsOpen}));
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <div>
        <Button
          type="button"
          color="primary"
          size="sm"
          onClick={this.handleToggleModal}
        >
          New queue item
        </Button>
        <ChannelSchedulerQueueFormModal
          isOpen={modalIsOpen}
          toggle={this.handleToggleModal}
          onSubmit={this.props.onCreate}
          initialValues={this.initialValues}
          isNew
        />
      </div>
    );
  }

}
