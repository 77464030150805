import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ProgramDescription } from 'client/components/Program/ProgramHelpers';
import { TimeInSettingsZone } from 'client/components/TimeHelpers';
import { ConnectedProgramListConsumerOne } from 'client/components/Program/ProgramListProviders';

export class ChannelSchedulerPreviewTable extends React.Component {

  static propTypes = {
    channelSchedule: propTypes.array.isRequired,
  }

  render() {
    const { channelSchedule } = this.props;
    return (
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Starts</th>
            <th>Ends</th>
            <th>Program</th>
          </tr>
        </thead>
        <tbody>
          {channelSchedule.length < 1 && (
            <tr>
              <td colSpan="3">There is nothing here</td>
            </tr>
          )}
          {channelSchedule.map((channelSchedule, index) => (
            <ChannelSchedulerPreviewTableRow
              key={index}
              channelSchedule={channelSchedule}
            />
          ))}
        </tbody>
      </table>
    );
  }

}

export class ChannelSchedulerPreviewTableRow extends React.Component {

  static propTypes = {
    channelSchedule: propTypes.object,
  }

  render() {
    const { channelSchedule } = this.props;
    if(!channelSchedule) return (
      <tr>
        <td colSpan="3">Empty</td>
      </tr>
    );

    const { start, end, programId } = channelSchedule;
    return (
      <tr>
        <td>
          <TimeInSettingsZone
            time={start}
            format="HH:mm:ss"
          />
        </td>
        <td>
          <TimeInSettingsZone
            time={end}
            format="HH:mm:ss"
          />
        </td>
        <td>
          <ConnectedProgramListConsumerOne programId={programId}>
            {program => (
              <Link to={`/programs/${program.id}`}>
                <ProgramDescription {...program} />
              </Link>
            )}
          </ConnectedProgramListConsumerOne>
        </td>
      </tr>
    );
  }

}
