import React from 'react';
import './ViewPortScene.css';
import ConnectedViewPortWebsocketState from 'client/components/ViewPort/ConnectedViewPortWebsocketState';

export default class ViewPortScene extends React.Component {

  render() {
    const { children } = this.props;
    return (
      <div className="viewport-scene">
        <div className="viewport-container">
          {children}
        </div>
        <ConnectedViewPortWebsocketState />
      </div>
    );
  }

}
