export const internalError = error => dispatch => {
  return dispatch({type: 'INTERNAL_ERROR', error});
};

export const authError = error => dispatch => {
  return dispatch({type: 'AUTH_ERROR', error});
};

export const forbiddenError = error => dispatch => {
  return dispatch({type: 'FORBIDDEN_ERROR', error});
};
