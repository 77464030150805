import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { transitionStatePropType } from 'client/helpers/propTypes';
import { NumberFormatUnit } from 'client/components/Helpers';
import {
  QualificationBoardUpcomingList,
  QualificationBoardGrid,
} from 'client/components/ViewPortProgram/ViewPortProgramQualificationboardHelpers';

export default class ViewPortProgramQualificationboard extends React.Component {

  static propTypes = {
    title: propTypes.string.isRequired,
    goalTitle: propTypes.string.isRequired,
    valueUnit: propTypes.string.isRequired,
    maxQualifiedPersons: propTypes.number.isRequired,
    minValueToQualify: propTypes.number.isRequired,
    table: propTypes.array.isRequired,
    transitionState: transitionStatePropType().isRequired,
    personFocus: propTypes.object,
  }

  render() {
    const {
      maxQualifiedPersons,
      minValueToQualify,
      goalTitle,
      table,
      title,
      transitionState,
      valueUnit,
    } = this.props;

    const {
      qualifiedTable,
      currentMinValueToQualify,
      unqualifiedTable,
    } = divideTable(table, minValueToQualify, maxQualifiedPersons);

    const containerClasses = classNames('ltv_qualificationboard', transitionState);
    return (
      <div className={containerClasses}>
        <div className="ltv_up">
          <div className="ltv_caption">
            {title}
          </div>
          <QualificationBoardGrid
            table={qualifiedTable}
            valueUnit={valueUnit}
            maxQualifiedPersons={maxQualifiedPersons}
          />
        </div>
        <div className="ltv_down">
          <div className="ltv_goal">
            <div className="ltv_text">
              {goalTitle}
            </div>
            <div className="ltv_greybox">
              <div className="wrapper">
                <div className="greybox_border">
                  {currentMinValueToQualify > 0 && <NumberFormatUnit
                    className="value"
                    value={currentMinValueToQualify}
                    unit={valueUnit}
                  />}
                  {(currentMinValueToQualify === 0 || !currentMinValueToQualify) && (
                    <span className="value">&gt; 1 {valueUnit}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <QualificationBoardUpcomingList
            valueUnit={valueUnit}
            className="ltv_userslist"
            numberOfPositions={3}
            table={unqualifiedTable}
          />
        </div>
      </div>
    );
  }
}

// divides table into a qualified and an unqualified group
function divideTable(table, minValueToQualify, maxQualifiedPersons) {
  const qualifiedTable = table
    .filter(({value}) => parseInt(value, 10) >= minValueToQualify)
    .slice(0, maxQualifiedPersons);
  const unqualifiedTable = table.filter(item => !qualifiedTable.includes(item)).slice(0, 3);
  const currentMinValueToQualify = qualifiedTable.length >= maxQualifiedPersons ? Math.max(minValueToQualify, parseInt(_.get(_.last(qualifiedTable), 'value'), 10) || 0) : minValueToQualify;
  return {qualifiedTable, currentMinValueToQualify, unqualifiedTable};
}
