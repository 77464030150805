import { combineReducers } from 'redux';
import index from 'client/redux/accountChannel/indexReducer';
import edit from 'client/redux/accountChannel/editReducer';
import scheduleQueue from 'client/redux/accountChannel/scheduleQueueReducer';
import scheduleCalendar from 'client/redux/accountChannel/scheduleCalendarReducer';
import scheduleCalendarQueue from 'client/redux/accountChannel/scheduleCalendarQueueReducer';
import schedulePreview from 'client/redux/accountChannel/schedulePreviewReducer';

const accountChannelReducer = combineReducers({
  index,
  edit,
  scheduleQueue,
  scheduleCalendar,
  scheduleCalendarQueue,
  schedulePreview,
});

export default accountChannelReducer;
