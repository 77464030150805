const defaultState = {
  channelPassword: null,
  channelIdForPassword: null,
};

export default function(state = defaultState, action) {
  switch(action.type) {
    default: return state;
    case 'TV_OUTPUT_CLEAR_PASSWORD': return {
      ...state,
      channelPassword: null,
    };
    case 'TV_OUTPUT_SET_PASSWORD': return {
      ...state,
      channelPassword: action.channelPassword,
      channelIdForPassword: action.channelIdForPassword,
    };
  }
}
