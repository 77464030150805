import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

// see https://github.com/icd2k3/react-router-breadcrumbs-hoc

const Breadcrumbs = ({ breadcrumbs }) => (
  <Breadcrumb listClassName="slim-breadcrumb">
    {breadcrumbs.map((breadcrumb, index) => (
      <BreadcrumbItem key={breadcrumb.key}>
        <NavLink to={breadcrumb.props.match.url}>
          {breadcrumb}
        </NavLink>
      </BreadcrumbItem>
    ))}
  </Breadcrumb>
);

const ProgramBreadcrumb = ({match: {params: {programId}}, programTitle}) => {
  return <span>{programTitle || programId}</span>;
};

const ChannelBreadcrumb = ({match: {params: {channelId}}, channelName}) => {
  return <span>{channelName || channelId}</span>;
};

const ConnectedProgramBreadcrumb = connect(state => ({programTitle: _.get(state, 'accountProgram.edit.program.title')}))(ProgramBreadcrumb);
const ConnectedChannelBreadcrumb = connect(state => ({channelName: _.get(state, 'accountChannel.edit.channel.name')}))(ChannelBreadcrumb);

const routes = [
  { path: '/programs/:programId', breadcrumb: ConnectedProgramBreadcrumb },
  { path: '/channels/:channelId', breadcrumb: ConnectedChannelBreadcrumb },
];

export default withBreadcrumbs(routes, { excludePaths: ['/data'] })(Breadcrumbs);
