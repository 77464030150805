import * as constants from 'client/redux/accountData/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isLoading: false,
  error: null,
};

export default function(state = defaultState, action) {
  state = reduceByTypes(constants.ACCOUNT_DATA_LIST_BOARDS, state, action, {
    requestProp: 'isLoading',
    errorProp: 'error',
    successPickProps: ['boards', 'allGroupIds', 'allPersonIds'],
  });
  return state;
};
