import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import momentTz from 'moment-timezone';
import SettingsContext from 'client/components/AccountSettings/SettingsContext';

const validDatePropType = propTypes.oneOfType([
  propTypes.string,
  propTypes.number,
  propTypes.instanceOf(Date),
]);

export default class Time extends React.Component {

  static propTypes = {
    time: validDatePropType,
    format: propTypes.string,
  }

  static defaultProps = {
    format: 'YYYY-MM-DD HH:mm:ss'
  }

  render() {
    const { time, format, className } = this.props;
    const date = moment(time);
    if(!date.isValid()) return '<invalid date>';
    return (
      <time
        className={className}
        dateTime={date.toString()}>
        {date.format(format)}
      </time>
    );
  }

}

export class TimeRelative extends React.Component {

  static propTypes = {
    time: validDatePropType
  }

  render() {
    const { time } = this.props;
    const date = moment(time);
    return <time dateTime={date.toString()}>{date.fromNow()}</time>
  }

}

export class TimeInZone extends React.Component {

  static propTypes = {
    time: validDatePropType,
    zone: propTypes.string.isRequired,
    format: propTypes.string,
    showZone: propTypes.bool,
  }

  static defaultProps = {
    format: 'YYYY-MM-DD HH:mm:ss',
    showZone: false,
  }

  render() {
    const { time, format, zone, showZone } = this.props;
    const date = momentTz(time);
    if(!date.isValid()) return '<invalid date>';
    const zoneRef = date.tz(zone);
    if(!zoneRef) return '<invalid zone>';
    return (
      <time dateTime={date.toString()}>
        {date.tz(zone).format(format)}{' '}
        {showZone && date.format('Z')}
      </time>
    );
  }

}

export class TimeInSettingsZone extends React.Component {
  render() {
    return (
      <SettingsContext.Consumer>
        {settings => (
          <TimeInZone
            {...this.props}
            zone={(settings || {}).timezone}
          />
        )}
      </SettingsContext.Consumer>
    );
  }
}
