import * as constants from 'client/redux/accountProgram/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultGroupIdsState = {
  isReading: false,
  error: null,
  dataType: undefined,
  allGroupIds: undefined,
};
function reduceGroupIds(state = defaultGroupIdsState, action) {
  return reduceByTypes(constants.ACCOUNT_PROGRAM_DATA_READ_GROUP_IDS, state, action, {
    requestProp: 'isReading',
    errorProp: 'error',
    successPickProps: ['allGroupIds', 'dataType'],
  });
}

const defaultState = {
  groupIds: {},
};
export default function(state = defaultState, action) {
  if(constants.ACCOUNT_PROGRAM_DATA_READ_GROUP_IDS.includes(action.type)) {
    return {...state, groupIds: reduceGroupIds(state.groupIds, action)};
  }
  return state;
};
