import { getApiActionTypes } from 'client/redux/apiHelpers';
import { channelReadState } from 'client/redux/channel/actionCreators';

export const viewportReadStateTypes = getApiActionTypes('VIEWPORT_READ_STATE');

export const viewportReadState = (channelId, channelPassword) => dispatch => {
  return dispatch(channelReadState(viewportReadStateTypes, channelId, channelPassword));
};

export const viewportSceneOut = channelId => dispatch => {
  return dispatch({type: 'VIEWPORT_SCENE_OUT', channelId});
};

export const viewportSceneIn = channelId => dispatch => {
  return dispatch({type: 'VIEWPORT_SCENE_IN', channelId});
};

export const viewportSceneCommitUpcoming = channelId => dispatch => {
  return dispatch({type: 'VIEWPORT_SCENE_COMMIT_UPCOMING', channelId});
};

export const viewportSceneShowUpcoming = channelId => dispatch => {
  return dispatch({type: 'VIEWPORT_SCENE_SHOW_UPCOMING', channelId});
};
