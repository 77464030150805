import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { NumberFormatUnit } from 'client/components/Helpers';

export default class ViewPortProgramUserbox extends React.Component {

  static propTypes = {
    photoUrl: propTypes.string,
    color: propTypes.oneOf(['', 'orange', 'dark']),
    size: propTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    displayName: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.number]),
    valueUnit: propTypes.string,
  }

  static defaultProps = {
    value: 0,
    valueUnit: '',
    displayName: '',
    color: '',
    size: 'md',
  }

  render() {
    const {
      displayName,
      photoUrl,
      color,
      value,
      valueUnit,
      className,
      size,
    } = this.props;
    const containerClasses = classNames('ltv_userbox', className, `ltv_${color}`, `ltv_userbox_${size}`);
    return (
      <div className={containerClasses}>
        <div className="ltv_avatar">
          <img src={photoUrl} alt={displayName} />
        </div>
        {['lg', 'md', 'sm'].includes(size) && (<div className="ltv_name">
          <div className="ltv_wrapper">
            {formatDisplayName(displayName, size)}
          </div>
        </div>)}
        {(size === 'lg' || size === 'md') && (<div className="ltv_st">
          <div className="ltv_wrapper">
            <div className="ltv_wrapper">
              <NumberFormatUnit className="value" value={value} unit={valueUnit} />
            </div>
          </div>
        </div>)}
      </div>
    );
  }

}

function formatDisplayName(displayName, size) {
  switch(size) {
    default: return displayName;
    case 'sm': return (displayName || '').split(' ')[0];
  }
}
