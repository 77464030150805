import React from 'react';
import propTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';

const getChannelUrl = channelId => `${process.env.REACT_APP_URL}/tv/${channelId}`;

export default class ChannelForm extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  render() {
    const { formikProps } = this.props;
    const { values:channel, handleChange, handleBlur } = formikProps;
    return (
      <div className="py-1">
        <FormGroup row>
          <Label sm={2} for="channelFormName">Channel Name</Label>
          <Col sm={10}>
            <Input
              type="text"
              min="1"
              max="255"
              required
              name="name"
              id="channelFormName"
              value={channel.name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="channelFormPassword">Channel Password</Label>
          <Col sm={10}>
            <Input
              type="text"
              min="1"
              max="255"
              required
              name="password"
              id="channelFormPassword"
              value={channel.password || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={{size: 10, offset: 2}}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="channelFormIsActive"
                  name="isActive"
                  checked={Boolean(channel.isActive)}
                  value={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{' '}
                Channel is active
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="channelUrl">Channel URL</Label>
          <Col sm={10}>
            <InputGroup>
              <Input
                id="channelUrl"
                type="text"
                value={getChannelUrl(channel.id)}
                readOnly
              />
              <InputGroupAddon addonType="append">
                <CopyToClipboard text={getChannelUrl(channel.id)}>
                  <Button type="button">Copy URL</Button>
                </CopyToClipboard>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      </div>
    );
  }

}
