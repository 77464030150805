import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'client/components/Spinners';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { ChannelSchedulerQueueTable } from 'client/components/ChannelScheduler/ChannelSchedulerQueueTableHelpers';
import ChannelSchedulerQueueAddButton from 'client/components/ChannelScheduler/ChannelSchedulerQueueAddButton';
import * as scheduleCalendarQueueActions from 'client/redux/accountChannel/scheduleCalendarQueueActions';

class ConnectedChannelSchedulerCalendarQueueEditor extends React.Component {

  static propTypes = {
    channelScheduleCalendarId: propTypes.string.isRequired,
  }

  componentDidMount() {
    const { channelScheduleCalendarId } = this.props;
    return this.props.scheduleCalendarQueueChannelIndex(channelScheduleCalendarId);
  }

  handleDelete = (channelScheduleQueueId) => {
    return this.props.scheduleCalendarQueueDelete(channelScheduleQueueId);
  }

  handleUpdate = (channelScheduleQueueId, scheduleQueueProps) => {
    return this.props.scheduleCalendarQueueUpdate(channelScheduleQueueId, scheduleQueueProps);
  }

  handleMove = (channelScheduleQueueId, direction) => {
    return this.props.scheduleCalendarQueueMove(channelScheduleQueueId, direction);
  }

  handleCreate = (scheduleQueueProps) => {
    const { channelScheduleCalendarId } = this.props;
    return this.props.scheduleCalendarQueueCreate({...scheduleQueueProps, channelScheduleCalendarId});
  }

  render() {
    const { error, isReading, scheduleQueues } = this.props;
    return (
      <div className="p-2 pt-3">
        <SpinnerOverlay color="primary" spinning={isReading} centerX centerY showChildren>
          <ErrorAlert className="mt-4" error={error} />
          {scheduleQueues && 
            <ChannelSchedulerQueueTable
              scheduleQueues={scheduleQueues}
              onUpdate={this.handleUpdate}
              onDelete={this.handleDelete}
              onMove={this.handleMove}
            />
          }
          <div className="d-flex justify-content-end">
            <ChannelSchedulerQueueAddButton onCreate={this.handleCreate} />
          </div>
        </SpinnerOverlay>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  ...state.accountChannel.scheduleCalendarQueue,
});

const actions = {
  ...scheduleCalendarQueueActions,
};

export default connect(mapStateToProps, actions)(ConnectedChannelSchedulerCalendarQueueEditor);
