import React from 'react';
import { connect } from 'react-redux';
import Logo from 'client/components/Branding/Logo';
import Icon from 'client/components/Icon';
import { getAccountPhotoUrl } from 'client/helpers/imageUrls';
import SettingsContext from 'client/components/AccountSettings/SettingsContext';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { SlimHeader } from 'client/components/Slims';
import * as authActions from 'client/redux/auth/authActions';


class ConnectedAppHeader extends React.Component {

  handleLogout = () => {
    return this.props.authLogout();
  }

  render() {
    const self = this.props.self || {};
    const { name } = self;
    return (
      <SlimHeader>
        <div className="container">
          <div className="slim-header-left">
            <Logo />
          </div>
          <div className="slim-header-right">
            <UncontrolledDropdown className="dropdown-c">
              <DropdownToggle className="logged-user" tag="a" href="#">
                <SettingsContext.Consumer>
                  {settings => (
                    <img src={getAccountPhotoUrl(settings.photo)} alt="Account Logo" />
                  )}
                </SettingsContext.Consumer>
                <span>{name}</span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  className="nav-link btn btn-default"
                  onClick={this.handleLogout}
                >
                  <Icon icon="ion-forward" />
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </SlimHeader>
    );
  }
}

const mapStateToProps = state => ({
  ...state.auth,
});

export default connect(mapStateToProps, authActions)(ConnectedAppHeader);
