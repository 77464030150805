import React from 'react';
import propTypes from 'prop-types';
import { ButtonIconRound } from 'client/components/Buttons';
import { Card, CardBody } from 'reactstrap';
import ChannelCardNewModal from 'client/components/ChannelIndexPage/ChannelCardNewModal';

export default class ChannelCardNew extends React.Component {

  static propTypes = {
    onCreate: propTypes.func.isRequired,
  }

  state = {
    modalIsOpen: false,
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  handleToggleModal = ev => {
    if(!this.willUnmount) {
      this.setState(state => ({modalIsOpen: !state.modalIsOpen}));
    }
  }

  render() {
    const { onCreate } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <Card className="w-100">
        <CardBody className="d-flex align-items-center justify-content-center p-0">
          <ChannelCardNewModal
            isOpen={modalIsOpen}
            toggle={this.handleToggleModal}
            onSubmit={onCreate}
          />
          <ButtonIconRound
            color="primary"
            icon="ion-ios-add-circle-outline"
            onClick={this.handleToggleModal}
          />
        </CardBody>
      </Card>
    );
  }

}
