import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'client/components/Spinners';
import * as listActions from 'client/redux/accountProgram/listActions';

const mapStateToListProp = (state, props) => ({
  ..._.get(state, ['accountProgram', 'list', props.listKey || 'all'], {}),
});

class ProgramListLoader extends React.Component {

  static propTypes = {
    query: propTypes.object,
    listKey: propTypes.string,
  }

  static defaultProps = {
    query: {},
    listKey: 'all',
  }

  componentDidMount() {
    const { query, listKey } = this.props;
    this.props.accountProgramList(query, {listKey});
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const ConnectedProgramListLoader = connect(null, listActions)(ProgramListLoader);

class ProgramListLoaderSpinner extends React.Component {

  static propTypes = {
    listKey: propTypes.string,
  }

  static defaultProps = {
    listKey: 'all',
  }

  render() {
    const { isReading, children, ...otherProps } = this.props;
    return (
      <SpinnerOverlay spinning={isReading} color="primary" showChildren>
        <ConnectedProgramListLoader {...otherProps}>
          {children}
        </ConnectedProgramListLoader>
      </SpinnerOverlay>
    );
  }

}

export const ConnectedProgramListLoaderSpinner = connect(mapStateToListProp)(ProgramListLoaderSpinner);

class ProgramListConsumer extends React.Component {

  static propTypes = {
    listKey: propTypes.string,
    children: propTypes.func.isRequired,
    programs: propTypes.array,
  }

  static defaultProps = {
    listKey: 'all',
    programs: [],
  }

  render() {
    const { programs, children } = this.props;
    return children(programs);
  }

}

export const ConnectedProgramListConsumer = connect(mapStateToListProp)(ProgramListConsumer);

class ProgramListConsumerOne extends React.Component {

  static propTypes = {
    listKey: propTypes.string,
    programId: propTypes.string,
    children: propTypes.func.isRequired,
    programs: propTypes.array,
  }

  static defaultProps = {
    listKey: 'all',
    programs: [],
  }

  render() {
    const { programs, programId, children } = this.props;
    const program = (programs || []).find(({id}) => id === programId);
    if(!program) return null;
    return children(program);
  }

}

export const ConnectedProgramListConsumerOne = connect(mapStateToListProp)(ProgramListConsumerOne);
