import React from 'react';
import propTypes from 'prop-types';
import { Col, FormGroup, FormText, Label, Input } from 'reactstrap';
import ProgramSettingTimeseries from 'client/components/ProgramSetting/ProgramSettingTimeseries';
import ConnectedProgramSettingDataGroupIdField from 'client/components/ProgramSetting/ConnectedProgramSettingDataGroupIdField';

export default class ProgramSettingQualificationboard extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  render() {
    const { formikProps } = this.props;
    const { values:program, handleChange, handleBlur } = formikProps;
    return (
      <div className="py-1">
        <FormGroup row>
          <Label sm={2} for="programSettingQualificationboardGoalTitle">Goal Title</Label>
          <Col sm={10}>
            <Input
              type="text"
              name="settings.goalTitle"
              id="programSettingQualificationboardGoalTitle"
              value={program.settings.goalTitle || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label sm={2} for="programSettingQualificationboardGroup">Data group</Label>
          <Col sm={10}>
            <ConnectedProgramSettingDataGroupIdField
              id="programSettingQualificationboardGroup"
              name="settings.groupId"
              value={program.settings.groupId}
              onChange={handleChange}
              onBlur={handleBlur}
              dataType="timeSeries"
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingQualificationboardMaxQualifiedPersons">Max qualified persons</Label>
          <Col sm={10}>
            <Input
              type="number"
              name="settings.maxQualifiedPersons"
              id="programSettingQualificationboardMaxQualifiedPersons"
              min="1"
              max="30"
              value={program.settings.maxQualifiedPersons || '0'}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormText color="muted">Any number between 1–30. Anything 8 or less will show empty slots.</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingQualificationboardMinValueToQualify">Min value to qualify</Label>
          <Col sm={10}>
            <Input
              type="number"
              name="settings.minValueToQualify"
              id="programSettingQualificationboardMinValueToQualify"
              min="0"
              value={program.settings.minValueToQualify || '0'}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormText color="muted">Any positive integer or zero for no limit</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingQualificationboardUnit">Value unit</Label>
          <Col sm={10}>
            <Input
              type="string"
              name="settings.valueUnit"
              id="programSettingQualificationboardUnit"
              value={program.settings.valueUnit || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        <ProgramSettingTimeseries formikProps={formikProps} />
      </div>
    );
  }

}
