import React from 'react';
import { withFormik, Form } from 'formik';
import { FormikEffect } from 'client/components/FormHelpers';
import propTypes from 'prop-types';
import { Input, Label, FormGroup, Col } from 'reactstrap';

class ProgramListFilterForm extends React.Component {

  static propTypes = {
    onUpdate: propTypes.func.isRequired,
    filter: propTypes.object.isRequired,
  }

  handleFormChange = (prevValues, nextValues) => {
    return this.props.onUpdate(nextValues);
  }

  render() {
    const {
      handleChange,
      handleBlur,
      values,
    } = this.props;
    return (
      <Form>
        <FormikEffect onChangeValue={this.handleFormChange} />
        <div className="row">
          <Col md={3} sm={6}>
            <FormGroup>
              <Label for="programListFormType">
                Type
              </Label>
              <Input
                type="select"
                name="type"
                id="programListFormType"
                value={values.type || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">All types</option>
                <option value="gallery">Gallery</option>
                <option value="leaderboard">Leaderboard</option>
                <option value="logo">Logo</option>
                <option value="personfocus">Personfocus</option>
                <option value="qualificationboard">Qualificationboard</option>
                <option value="sumboard">Sumboard</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label for="programListSearch">
                Search
              </Label>
              <Input
                type="input"
                name="search"
                id="programListSearch"
                value={values.search || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Col>
        </div>
      </Form>
    );
  }

}

const formikProps = {
  displayName: 'ProgramListFilterForm',
  mapPropsToValues: ({filter = {}}) => filter,
};

export default withFormik(formikProps)(ProgramListFilterForm);
