import React from 'react';
import propTypes from 'prop-types';
import {
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import ConnectedProgramSettingLogoPhotoUpload from 'client/components/ProgramSetting/ConnectedProgramSettingLogoPhotoUpload';

export default class ProgramSettingLogo extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  render() {
    const { formikProps } = this.props;
    const { values:program, handleChange, handleBlur } = formikProps;
    return (
      <div className="py-1">
        <FormGroup row>
          <legend className="col-form-label col-sm-2 py-0">Clock</legend>
          <Col sm={10}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="settings.showClock"
                  checked={Boolean(program.settings.showClock)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{' '}
                Show clock
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Label sm={2} for="settingsPhoto">Company Logo</Label>
          <Col sm={10} className="pt-2">
            <ConnectedProgramSettingLogoPhotoUpload
              programId={program.id}
              logoImage={program.settings.logoImage}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }

}
