import React from 'react';
import { Formik, Form } from 'formik';
import { FormikEffect } from 'client/components/FormHelpers';
import { FieldGroupId, FieldPersonId, FieldGroup } from 'client/components/DataPage/DataPageFilterFields';
import propTypes from 'prop-types';

export default class DataPageBoardFilter extends React.Component {

  static propTypes = {
    initialValues: propTypes.object.isRequired,
    onUpdate: propTypes.func.isRequired,
    allPersonIds: propTypes.array,
    allGroupIds: propTypes.array,
  }

  static defaultProps = {
    allPersonIds: [],
    allGroupIds: [],
  }

  handleFormChange = (prevValues, nextValues) => {
    return this.props.onUpdate(nextValues);
  }

  renderFields = ({handleChange, handleBlur, values}) => {
    const { allPersonIds, allGroupIds } = this.props;
    const fieldBaseProps = {
      base: 'Board',
      values,
      handleChange,
      handleBlur,
    };
    return (
      <Form>
        <FormikEffect onChangeValue={this.handleFormChange} />
        <div className="row">
          <FieldPersonId
            {...fieldBaseProps}
            allPersonIds={allPersonIds}
          />
          <FieldGroupId
            {...fieldBaseProps}
            allGroupIds={allGroupIds}
          />
          <FieldGroup
            {...fieldBaseProps}
          />
        </div>
      </Form>
    );
  }

  render() {
    const { initialValues } = this.props;
    return (
      <Formik initialValues={initialValues}>
        {this.renderFields}
      </Formik>
    );
  }

}
