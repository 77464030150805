import { FLASH_ALERT, FLASH_SEEN } from './constants';
import { LOCATION_CHANGE } from 'connected-react-router';

const defaultState = {
  seen: false,
  alert: null,
};

export default function(state = defaultState, action) {
  switch(action.type) {
    case LOCATION_CHANGE: return {
      seen: false,
      alert: state.seen ? null : state.alert
    };
    case FLASH_SEEN: return {
      ...state,
      seen: true
    };
    case FLASH_ALERT: return {
      seen: false,
      alert: action.payload
    };
    default:
      return state
  }
};
