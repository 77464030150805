import React from 'react';
import ConnectedViewPort from 'client/components/ViewPort/ConnectedViewPort';
import ConnectedTvOutputPasswordDialogScene from 'client/components/TvOutput/ConnectedTvOutputPasswordDialogScene';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { websocketSubscribe, websocketUnsubscribe } from 'client/redux/websocket/websocketActions';
import _ from 'lodash';

class ConnectedTvOutput extends React.Component {

  static propTypes = {
    pageReloadRequested: propTypes.bool,
    channelPassword: propTypes.string,
  }

  static defaultProps = {
    pageReloadRequested: false,
  }

  componentDidMount() {
    this.props.websocketSubscribe('pageReload', 'TV_OUTPUT_PAGE_RELOAD_REQUESTED');
  }

  componentWillUnmount() {
    this.props.websocketUnsubscribe('pageReload');
  }

  componentDidUpdate() {
    const { pageReloadRequested = false } = this.props;
    if(pageReloadRequested) {
      window.location.reload(true);
    }
  }

  render() {
    const channelId = _.get(this.props, 'match.params.channelId');
    const { channelPassword, channelIdForPassword } = this.props;
    if(!channelPassword || !channelId || channelId !== channelIdForPassword) {
      return (
        <ConnectedTvOutputPasswordDialogScene
          channelId={channelId}
        />
      );
    }
    return (
      <ConnectedViewPort
        channelId={channelId}
        channelPassword={channelPassword}
      />
    );
  }

}

const actions = {
  websocketSubscribe,
  websocketUnsubscribe,
};

const mapStateToProps = state => ({
  ...state.tvOutput,
  channelIdForPassword: _.get(state, 'tvOutputAuth.channelIdForPassword'),
  channelPassword: _.get(state, 'tvOutputAuth.channelPassword'),
});

export default connect(mapStateToProps, actions)(ConnectedTvOutput);
