import React from 'react';
import propTypes from 'prop-types';
import ProgramSettingLeaderboard from 'client/components/ProgramSetting/ProgramSettingLeaderboard';
import ProgramSettingSumboard from 'client/components/ProgramSetting/ProgramSettingSumboard';
import ProgramSettingGallery from 'client/components/ProgramSetting/ProgramSettingGallery';
import ProgramSettingLogo from 'client/components/ProgramSetting/ProgramSettingLogo';
import ProgramSettingPersonfocus from 'client/components/ProgramSetting/ProgramSettingPersonfocus';
import ProgramSettingQualificationboard from 'client/components/ProgramSetting/ProgramSettingQualificationboard';

export default class ProgramSettingsForm extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
    programType: propTypes.string.isRequired,
  }

  render() {
    const { programType, formikProps } = this.props;
    switch(programType) {
      default: return <p>No settings for program {programType}</p>
      case 'gallery': return <ProgramSettingGallery formikProps={formikProps} />
      case 'leaderboard': return <ProgramSettingLeaderboard formikProps={formikProps} />
      case 'logo': return <ProgramSettingLogo formikProps={formikProps} />
      case 'personfocus': return <ProgramSettingPersonfocus formikProps={formikProps} />
      case 'qualificationboard': return <ProgramSettingQualificationboard formikProps={formikProps} />
      case 'sumboard': return <ProgramSettingSumboard formikProps={formikProps} />
    }
  }

}
