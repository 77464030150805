import { flash } from 'client/redux/flashAlert/actions';
import { push } from 'connected-react-router';

const errorRouter = store => dispatch => action => {
  switch(action.type) {
    default: return dispatch(action);
    case 'AUTH_ERROR':
      dispatch(action);
      flash({
        color: 'warning',
        strong: 'Unauthenticated',
        msg: 'Please login to see this resource',
      })(dispatch);
      return dispatch(push('/login'));
  }
};

export default errorRouter;
