import React from 'react';
import propTypes from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import ProgramSettingTimeseries from 'client/components/ProgramSetting/ProgramSettingTimeseries';
import ConnectedProgramSettingDataGroupIdField from 'client/components/ProgramSetting/ConnectedProgramSettingDataGroupIdField';
import _ from 'lodash';

export default class ProgramSettingSumboard extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  render() {
    const { formikProps } = this.props;
    const { values:program, handleChange, handleBlur } = formikProps;
    const formDataTypeSetting = _.get(formikProps, 'values.settings.dataType');
    return (
      <div className="py-1">
        <FormGroup row>
          <Label sm={2} for="programSettingSumboardSubTitle">Subtitle</Label>
          <Col sm={10}>
            <Input
              type="text"
              name="settings.subTitle"
              id="programSettingSumboardSubTitle"
              value={program.settings.subTitle || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <legend className="col-form-label col-sm-2 py-0">Data type</legend>
          <Col sm={10}>
            <div className="row">
              <Col sm={2}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="settings.dataType"
                      value="timeSeries"
                      checked={program.settings.dataType === 'timeSeries'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />{' '}
                    Time series
                  </Label>
                </FormGroup>
              </Col>
              {/* <Col sm={2}> */}
              {/*   <FormGroup check> */}
              {/*     <Label check> */}
              {/*       <Input */}
              {/*         type="radio" */}
              {/*         name="settings.dataType" */}
              {/*         value="board" */}
              {/*         checked={program.settings.dataType === 'board'} */}
              {/*         onChange={handleChange} */}
              {/*         onBlur={handleBlur} */}
              {/*       />{' '} */}
              {/*       Board */}
              {/*     </Label> */}
              {/*   </FormGroup> */}
              {/* </Col> */}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingSumboardGroup">Data group</Label>
          <Col sm={10}>
            <ConnectedProgramSettingDataGroupIdField
              id="programSettingSumboardGroup"
              name="settings.groupId"
              value={program.settings.groupId}
              onChange={handleChange}
              onBlur={handleBlur}
              dataType={program.settings.dataType || ''}
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} for="programSettingSumboardUnit">Value unit</Label>
          <Col sm={10}>
            <Input
              type="string"
              name="settings.valueUnit"
              id="programSettingSumboardUnit"
              value={program.settings.valueUnit || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
        {formDataTypeSetting === 'timeSeries' && <ProgramSettingTimeseries formikProps={formikProps} />}
      </div>
    );
  }

}

