import * as constants from 'client/redux/accountChannel/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  lastQuery: {},
  isReading: false,
  error: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_PREVIEW_READ, state, action, {
    requestProp: 'isReading',
    errorProp: 'error',
    successPickProps: ['channelSchedule'],
  });

  switch(action.type) {
    default: return state;
    case constants.ACCOUNT_CHANNEL_SCHEDULE_PREVIEW_SET_FILTER: return {
      ...state,
      lastQuery: action.query,
    };
  }
};
