import * as actionCreators from 'client/redux/accountSettings/actionCreators';
import * as constants from 'client/redux/accountSettings/constants';

export const accountSettingsPhotoOnUpload = (error, body) => dispatch => dispatch({
  type: constants.ACCOUNT_SETTINGS_PHOTO_UPLOAD_RESPONSE,
  error,
  body,
});

export const accountSettingsPhotoRead = () => dispatch => dispatch(actionCreators.photoRead(constants.ACCOUNT_SETTINGS_PHOTO_UPLOAD_READ));

export const accountSettingsPhotoDelete = () => dispatch => dispatch(actionCreators.photoDelete(constants.ACCOUNT_SETTINGS_PHOTO_UPLOAD_DELETE));
