import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { getProgramLogoImageUrl } from 'client/helpers/imageUrls';
import Time from 'client/components/TimeHelpers';

export default class ViewPortProgramLogo extends React.Component {

  static propTypes = {
    showClock: propTypes.bool.isRequired,
    logoImage: propTypes.string,
    transitionState: propTypes.string,
  }

  render() {
    const { showClock, logoImage, transitionState } = this.props;
    return (
      <div className={classNames('ltv_logoboard', transitionState)}>
        {logoImage && (
          <div className="ltv_logo">
            <img src={getProgramLogoImageUrl(logoImage)} alt="" />
          </div>
        )}
        {showClock && <Clock transitionState={transitionState} />}
      </div>
    );
  }
}

class Clock extends React.Component {

  state = {
    time: new Date(),
  }

  updateClockState = () => {
    this.setState({time: new Date()});
  }

  componentDidMount() {
    this.renderIntervalId = setInterval(this.updateClockState, 500);
  }

  componentWillUnmount() {
    clearInterval(this.renderIntervalId);
  }

  render() {
    const { time } = this.state;
    return (
      <div className="ltv_clock">
        <div className="ltv_time">
          {time && <Time className="clock-time" time={time} format="HH:mm:ss" />}
        </div>
        <div className="ltv_date">
          {time && <Time className="clock-date" time={time} format="Do MMMM YYYY" />}
        </div>
      </div>
    );
  }
}
