import _ from 'lodash';
import * as constants from 'client/redux/accountSettings/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  photo: null,
  isDeleting: false,
  isReading: false,
  error: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_SETTINGS_PHOTO_UPLOAD_READ, state, action, {
    requestProp: 'isReading',
    errorProp: 'error',
    successPickProps: ['photo'],
  });

  state = reduceByTypes(constants.ACCOUNT_SETTINGS_PHOTO_UPLOAD_DELETE, state, action, {
    requestProp: 'isDeleting',
    errorProp: 'error',
    successPickProps: ['photo'],
  });

  switch(action.type) {
    default: return state;
    case constants.ACCOUNT_SETTINGS_PHOTO_UPLOAD_RESPONSE: return {
      ...state,
      error: action.error || null,
      photo: _.get(action, 'body.photo') || state.photo || null,
    };
  }
};
