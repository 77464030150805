import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'formik';
import { Input, Col, Button, Label } from 'reactstrap';
import SettingsContext from 'client/components/AccountSettings/SettingsContext';

/**
 * @desc Updated formik-effect (allows a form to be submitted onChange)
 * @see https://github.com/jaredpalmer/formik-effect
 */
class UnconnectedFormikEffect extends React.Component {

  static propTypes = {
    onChange: propTypes.func,
    onChangeValue: propTypes.func,
  }

  componentWillReceiveProps(nextProps) {
    const { values, touched, errors, isSubmitting } = this.props.formik;
    const {
      values: nextValues,
      touched: nextTouched,
      errors: nextErrors,
      isSubmitting: nextIsSubmitting,
    } = nextProps.formik;
    if(this.props.onChange && nextProps.formik !== this.props.formik) {
      this.props.onChange(
        {
          values,
          touched,
          errors,
          isSubmitting,
        },
        {
          values: nextValues,
          touched: nextTouched,
          errors: nextErrors,
          isSubmitting: nextIsSubmitting,
        },
      );
    }

    if(this.props.onChangeValue && values !== nextValues) {
      this.props.onChangeValue(values, nextValues);
    }
  }

  // eslint-disable-next-line
  render() {
    return null;
  }
}

export const FormikEffect = connect(UnconnectedFormikEffect);

export class DateInput extends React.PureComponent {

  static propTypes = {
    value: propTypes.string.isRequired,
    onValueChange: propTypes.func.isRequired,
    zone: propTypes.string,
  }

  handleChange = ev => {
    const { value, zone } = this.props;
    const valueStr = ev.target.value || '2000-01-01';
    const date = zone ? moment(value).tz(zone) : moment(value);
    const [year, month, day] = valueStr.split('-');
    const set = {
      year: parseInt(year, 10),
      month: parseInt(month, 10) - 1,
      date: parseInt(day, 10)
    };
    date.set(set);
    const newValue = date.toISOString();
    this.props.onValueChange(newValue);
  }

  render() {
    const { value, zone, ...props } = this.props;
    const valueStr = zone ? moment(value).tz(zone).format('YYYY-MM-DD') : moment(value).format('YYYY-MM-DD');
    return (
      <Input {..._.omit(props, 'onValueChange')}
        type="date"
        value={valueStr}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }

}

export class TimeInput extends React.PureComponent {

  static propTypes = {
    value: propTypes.string.isRequired,
    onValueChange: propTypes.func.isRequired,
    zone: propTypes.string,
    format: propTypes.string,
  }

  static defaultProps = {
    format: 'HH:mm',
  }

  handleChange = ev => {
    const { value, zone, format } = this.props;
    const valueStr = ev.target.value || moment(0).format(format);
    const date = zone ? moment(value).tz(zone) : moment(value);
    const [hour, minute, second] = valueStr.split(':');
    const set = {
      hour: parseInt(hour, 10) || 0,
      minute: parseInt(minute, 10) || 0,
      second: parseInt(second, 10) || 0,
    };
    date.set(set);
    const newValue = date.toISOString();
    this.props.onValueChange(newValue);
  }

  render() {
    const { value, zone, format, ...props } = this.props;
    let valueStr;
    if(zone) {
      valueStr = moment(value).tz(zone).format(format);
    } else {
      valueStr = moment(value).format(format);
    }
    return (
      <Input
        {..._.omit(props, 'onValueChange')}
        type="time"
        value={valueStr}
        step="1"
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }

}

export class TimeInputInSettingsZone extends React.Component {
  render() {
    return (
      <SettingsContext.Consumer>
        {settings => (
          <TimeInput
            {...this.props}
            zone={(settings || {}).timezone}
          />
        )}
      </SettingsContext.Consumer>
    );
  }
}

export class DateInputInSettingsZone extends React.Component {
  render() {
    return (
      <SettingsContext.Consumer>
        {settings => (
          <DateInput
            {...this.props}
            zone={(settings || {}).timezone}
          />
        )}
      </SettingsContext.Consumer>
    );
  }
}

export class DateTimeInputInSettingsZone extends React.Component {

  static propTypes = {
    id: propTypes.string,
    name: propTypes.string.isRequired,
    value: propTypes.string.isRequired,
    setFieldValue: propTypes.func.isRequired,
    extraTimeProps: propTypes.object,
  }

  handleFieldChange = (value) => {
    const { name } = this.props;
    this.props.setFieldValue(name, value);
  }

  render() {
    const { id, name, value, extraTimeProps = {} } = this.props;
    return (
      <div className="row">
        <Col sm={6}>
          <DateInputInSettingsZone
            id={id}
            name={`${name}Date`}
            value={value}
            onValueChange={this.handleFieldChange}
          />
        </Col>
        <Col sm={6}>
          <TimeInputInSettingsZone
            name={`${name}Time`}
            value={value}
            onValueChange={this.handleFieldChange}
            {...extraTimeProps}
          />
        </Col>
      </div>
    );
  }

}

export class PickListInput extends React.Component {

  static propTypes = {
    leftLabel: propTypes.string,
    rightLabel: propTypes.string,
    name: propTypes.string.isRequired,
    completeList: propTypes.array.isRequired,
    chosenValues: propTypes.array.isRequired,
    onChange: propTypes.func.isRequired,
    canChangeOrder: propTypes.bool,
  }

  static defaultProps = {
    completeList: [],
    chosenValues: [],
    canChangeOrder: false,
  }

  state = {
    selectedLeft: [],
    selectedRight:[],
  }

  handleChangeLeft = ev => {
    const { options } = ev.target;
    const selectedLeft = [...options].filter(o => o.selected).map(o => o.value);
    this.setState({selectedLeft});
  }

  handleChangeRight = ev => {
    const { options } = ev.target;
    const selectedRight = [...options].filter(o => o.selected).map(o => o.value);
    this.setState({selectedRight});
  }

  handleMoveRight = () => {
    const { onChange, name, chosenValues } = this.props;
    const { selectedLeft } = this.state;
    onChange(name, chosenValues.concat(selectedLeft));
    this.setState({selectedLeft: [], selectedRight: []});
  }

  handleMoveLeft = () => {
    const { onChange, name, chosenValues } = this.props;
    const { selectedRight } = this.state;
    onChange(name, _.difference(chosenValues, selectedRight));
    this.setState({selectedLeft: [], selectedRight: []});
  }

  getLeftList = () => {
    const { completeList, chosenValues } = this.props;
    return completeList.filter(({id}) => !chosenValues.includes(id));
  }

  getRightList = () => {
    const { completeList, chosenValues } = this.props;
    return (chosenValues || []).map(id => completeList.find(obj => obj.id === id)).filter(v => v);
  }

  handleMoveVertical = direction => {
    const { onChange, name, chosenValues } = this.props;
    const selected = _.get(this.state, 'selectedRight.0');
    const indexOfSelected = chosenValues.indexOf(selected);

    let newChosenValues = chosenValues.slice(0);
    newChosenValues.splice(indexOfSelected, 1);
    newChosenValues.splice(indexOfSelected - direction, 0, selected);
    onChange(name, newChosenValues);
  }

  canMoveVertical(direction) {
    const { chosenValues } = this.props;
    const { selectedRight } = this.state;
    if(!selectedRight || selectedRight.length !== 1) return false;
    switch(direction) {
      default: return false;
      case -1: return selectedRight[0] !== _.last(chosenValues);
      case 1: return selectedRight[0] !== _.first(chosenValues);
    }
  }

  render() {
    const { canChangeOrder, leftLabel, rightLabel } = this.props;
    const { selectedLeft, selectedRight } = this.state;
    return (
      <div>
        <div className="row">
          {leftLabel && (
            <Label sm="5">{leftLabel}</Label>
          )}
          {rightLabel && (
            <Label className="offset-sm-2" sm="5">{rightLabel}</Label>
          )}
        </div>
        <div className="row">
          <Col sm="5">
            <Input
              type="select"
              value={selectedLeft}
              onChange={this.handleChangeLeft}
              multiple
              style={{minHeight: '200px'}}
            >
              {this.getLeftList().map(item => (
                <option key={item.id} value={item.id}>
                  {item.label}
                </option>
              ))}
            </Input>
          </Col>
          <Col sm="2">
            <Button
              type="button"
              color="primary"
              className="btn-block rounded"
              disabled={selectedLeft.length < 1}
              onClick={this.handleMoveRight}
            >
              →
            </Button>
            <Button
              type="button"
              color="primary"
              className="btn-block rounded"
              disabled={selectedRight.length < 1}
              onClick={this.handleMoveLeft}
            >
              ←
            </Button>
          </Col>
          <Col sm="5">
            <Input
              type="select"
              value={selectedRight}
              multiple
              style={{minHeight: '200px'}}
              onChange={this.handleChangeRight}
            >
              {this.getRightList().map(item => (
                <option key={item.id} value={item.id}>
                  {item.label}
                </option>
              ))}
            </Input>
            {canChangeOrder && (
              <div className="d-flex mt-2">
                <Button
                  size="sm"
                  color="primary"
                  type="button"
                  className="w-50 mr-1 rounded"
                  disabled={!this.canMoveVertical(1)}
                  onClick={() => this.handleMoveVertical(1)}
                >
                  ↑
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  type="button"
                  className="w-50 ml-1 rounded"
                  disabled={!this.canMoveVertical(-1)}
                  onClick={() => this.handleMoveVertical(-1)}
                >
                  ↓
                </Button>
              </div>
            )}
          </Col>
        </div>

      </div>
    )
  }
}
