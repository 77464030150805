import React from 'react';

// TODO use a generator function for all these classes

export class SlimHeader extends React.Component {
  render() {
    return (
      <div className="slim-header">
        {this.props.children}
      </div>
    );
  }
}

export class SlimNavbar extends React.Component {
  render() {
    return (
      <div className="slim-navbar">
        {this.props.children}
      </div>
    );
  }
}

export class SlimMainPanel extends React.Component {
  render() {
    return (
      <div className="slim-mainpanel">
        {this.props.children}
      </div>
    );
  }
}

export class SlimLogo extends React.Component {
  render() {
    return (
      <h2 className="slim-logo">
        {this.props.children}
      </h2>
    );
  }
}

export class SlimPageHeader extends React.Component {
  render() {
    return (
      <div className="slim-pageheader">
        {this.props.children}
      </div>
    );
  }
}

export class SlimPageTitle extends React.Component {
  render() {
    return (
      <h6 className="slim-pagetitle">
        {this.props.children}
      </h6>
    );
  }
}

export class SlimFooter extends React.Component {
  render() {
    return (
      <h6 className="slim-footer mb-0">
        {this.props.children}
      </h6>
    );
  }
}
