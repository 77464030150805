import { stringify } from 'querystring';
import { RSAA } from 'redux-api-middleware';
import qs from 'qs';
import _ from 'lodash';

export const getApiActionTypes = stem => {
  const REQUEST = stem + '_REQUEST';
  const SUCCESS = stem + '_SUCCESS';
  const ERROR = stem + '_ERROR';

  const arr = [REQUEST, SUCCESS, ERROR];

  arr.REQUEST = REQUEST;
  arr.SUCCESS = SUCCESS;
  arr.ERROR = ERROR;

  return arr;
};

export const apiAction = (outerProps) => {
  const props = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'same-origin',
    ...outerProps
  };

  if(props.json) {
    props.body = JSON.stringify(props.json);
    delete props.json;
  }

  return {
    [RSAA]: props
  };
};

/**
 * @description Appends a querystring to the end of the URL if given
 */
export const appendQuery = (url, query) => {
  if(_.keys(query).length > 0) {
    url = url + '?' + stringify(query);
  }
  return url;
};

/**
 * @desc Returns current search string parsed from the state
 */
export const getStateSearch = state => {
  const search = _.get(state, 'router.location.search', '');
  if(!search) return {};
  // slice because "?" is the first char
  return qs.parse(search.slice(1));
};

/**
 * @description Returns true if the passed api response is a validation result error
 */
export const isApiValidationResultError = result => {
  const error = (result || {}).response;
  return Boolean(error && !error.ok && 'validationResult' in error);
};

/**
 * @desc Returns an error message from a response object from the API
 * @param {Object} responseBody
 * @param {Boolean} [alwaysReturnSomething] When true will return an "unknown error string" when nothing could be found, else null
 * @return {String}
 */
export const responseToErrorMessage = (responseBody, alwaysReturnSomething = false) => {
  if(responseBody instanceof Error && responseBody.response) {
    responseBody = responseBody.response;
  }
  const responseReason = _.get(responseBody, 'reason');
  const responseErrorName = _.get(responseBody, 'error.name');
  const responseErrorMessage = _.get(responseBody, 'error.message');
  const responseMessage = _.get(responseBody, 'message');
  const tokens = [responseErrorName, responseReason, responseErrorMessage, responseMessage];

  if(tokens.some(token => token)) {
    // if any of the tokens exists
    return tokens.filter(token => token).join(' - ');
  }

  if(responseBody && responseBody.message) {
    return responseBody.message;
  }

  return alwaysReturnSomething ? 'No specific reason given / unknown error format' : null;
};

export const rejectOnActionError = action => {
  if(action && action.error) {
    const body = _.get(action, 'payload.response', {});
    switch(true) {
      // can't deal with validationResult yet
      // case _.keys(body.validationResult).length > 0: {
      //   const fields = _.keys(body.validationResult).reduce((fields, key) => {
      //     const resultKey = key;
      //     _.set(fields, resultKey, body.validationResult[key].msg);
      //     return fields;
      //   }, {});
      //   return Promise.reject(new Error(fields));
      // }
      case Boolean(body.ok === false && body.message):
        return Promise.reject(new Error(body.message));
      case Boolean(body.error && body.error.message):
        return Promise.reject(new Error(body.error.message));
      case Boolean(body.reason):
        return Promise.reject(new Error(body.reason));
      default:
        return Promise.reject(new Error('Unknown error'));
    }
  }
  return action;
};

/**
 * @todo Fix null as setting for successProp
 * @description Helper for request/success/failure reducer
 * @param {Array} types request, succces, failure
 * @param {Object} state
 * @param {Object} action
 * @param {Object} [options]
 * @param {String|Function[null]} [options.requestProp='isRequesting'] If string, property in state set to true when requesting, if function, passed state and function
 * @param {Boolean} [options.successSpread=false] If true, success value will be spread over the state, not assigned to a specific variable
 * @param {String|Function} [options.successProp='result'] If string, property in state set to successful result, if function, passed state and function
 * @param {String|Function|null} [options.errorProp='error'] If string, property in state set to error result, if function, passed state and function. if null, ignored
 * @param {Array<String>} [options.successPickProps] Pick these props from the success result and set them on the state object
 * TODO option för successSpread = true som gör att properties som redan finns definierade i state är de enda som ingår
 */
export const reduceByTypes = (types, state, action, { requestProp, successProp, errorProp, successSpread, successPickProps } = {}) => {
  if(!state || !action) {
    return state;
  }

  const [REQUEST, SUCCESS, FAILURE] = types;

  if(!_.isFunction(requestProp)) {
    if(requestProp !== null) {
      const requestPropKey = requestProp || 'isRequesting';
      requestProp = (state, action, isRequesting) => ({
        [requestPropKey]: isRequesting,
      });
    } else {
      requestProp = (state, action, isRequesting) => ({});
    }
  }

  if(!_.isFunction(successProp)) {
    const successPropKey = successProp || 'result';
    successProp = (state, action, value) => {
      let successResult = {[successPropKey]: value};
      if(successSpread) successResult = {...value};
      if(successPickProps) successResult = _.pick(value, successPickProps);
      return successResult;
    };
  }

  if(!_.isFunction(errorProp)) {
    if(errorProp !== null) {
      const errorPropKey = errorProp || 'error';
      errorProp = (state, action, error) => ({
        [errorPropKey]: error
      });
    } else {
      errorProp = (state, action, error) => ({});
    }
  }

  const mergeRequest = (state, action, isRequesting) => ({
    ...state,
    ...requestProp(state, action, isRequesting)
  });

  const mergeSuccess = (state, action, value) => ({
    ...state,
    ...requestProp(state, action, false),
    ...errorProp(state, action, null),
    ...successProp(state, action, value)
  });

  const mergeError = (state, action, error) => ({
    ...state,
    ...requestProp(state, action, false),
    ...errorProp(state, action, error)
  });

  switch(action.type) {
    case REQUEST: return mergeRequest(state, action, true);
    case SUCCESS: return mergeSuccess(state, action, action.payload || {});
    case FAILURE: return mergeError(state, action, action.payload || {});
    default: return state;
  }
};
