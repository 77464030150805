import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { getPersonPhotoUrl } from 'client/helpers/imageUrls';
import { transitionStatePropType } from 'client/helpers/propTypes';
import { UserNumber } from 'client/components/ViewPortProgram/ViewPortProgramHelpers';
import { NumberFormatUnit } from 'client/components/Helpers';
import _ from 'lodash';

const Sum = ({value, programValueUnit}) => (
  <div className="ltv_summ">
    <div className="ltv_wrapper">
      <div className="ltv_wrapper">
        <NumberFormatUnit className="value" value={value || 0} unit={programValueUnit} />
      </div>
    </div>
  </div>
);

class Statistic extends React.Component {

  static propTypes = {
    program: propTypes.object.isRequired,
    value: propTypes.string,
    rank: propTypes.number,
    total: propTypes.number,
  }

  static defaultProps = {
    value: 0,
  }

  render() {
    const { program, rank, value } = this.props;
    const subTitle = _.get(program, 'settings.subTitle', '');
    const programValueUnit = _.get(program, 'settings.valueUnit');
    return (
      <>
        <div className="ltv_colcaption">{subTitle}</div>
        <Sum value={value} programValueUnit={programValueUnit} />
        <UserNumber position={rank} />
      </>
    );
  }


}

export default class ViewPortProgramPersonfocus extends React.Component {

  static propTypes = {
    personId: propTypes.string,
    personName: propTypes.string,
    personPhoto: propTypes.string,
    statistics: propTypes.array.isRequired,
    transitionState: transitionStatePropType().isRequired,
    isPluginProgram: propTypes.bool.isRequired,
  }

  static defaultProps = {
    statistics: [],
    isPluginProgram: false,
  }

  getPersonPhotoUrl() {
    const { personPhoto } = this.props;
    return getPersonPhotoUrl(personPhoto);
  }

  render() {
    const {
      isPluginProgram,
      personName,
      statistics,
      transitionState,
    } = this.props;
    const classes = classNames('ltv_program', 'ltv_personfocus', transitionState, isPluginProgram ? 'plugin-program' : '');
    return (
      <div className={classes}>
        <div className="ltv_userdetailed">
          <div className="ltv_up">
            <div className="ltv_avatar">
              <img src={this.getPersonPhotoUrl()} alt={personName} />
            </div>
            <div className="ltv_name">
              <div className="ltv_wrapper">
                {personName}
              </div>
            </div>
          </div>
          <div className="ltv_down">
            {statistics && statistics.map((statistic, index) => (
              <div key={statistic.program.id} className={getStatisticContainerClassName(index)}>
                <Statistic {...statistic} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function getStatisticContainerClassName(index) {
  switch(index) {
    default: return '';
    case 0: return 'ltv_left';
    case 1: return 'ltv_center';
    case 2: return 'ltv_right';
  }
}
