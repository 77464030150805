import * as actionCreators from 'client/redux/accountChannel/actionCreators';
import * as constants from 'client/redux/accountChannel/constants';

export const scheduleQueueChannelIndex = channelId => dispatch => dispatch(actionCreators.scheduleQueueChannelIndex(constants.ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CHANNEL_INDEX, channelId));

export const scheduleQueueCreate = scheduleQueueProps => dispatch => dispatch(actionCreators.scheduleQueueCreate(constants.ACCOUNT_CHANNEL_SCHEDULE_QUEUE_CREATE, scheduleQueueProps));

export const scheduleQueueUpdate = (channelScheduleQueueId, scheduleQueueProps) => dispatch => dispatch(actionCreators.scheduleQueueUpdate(constants.ACCOUNT_CHANNEL_SCHEDULE_QUEUE_UPDATE, channelScheduleQueueId, scheduleQueueProps));

export const scheduleQueueDelete = (channelScheduleQueueId) => dispatch => dispatch(actionCreators.scheduleQueueDelete(constants.ACCOUNT_CHANNEL_SCHEDULE_QUEUE_DELETE, channelScheduleQueueId));

export const scheduleQueueMove = (channelScheduleQueueId, direction) => dispatch => dispatch(actionCreators.scheduleQueueMove(constants.ACCOUNT_CHANNEL_SCHEDULE_QUEUE_MOVE, channelScheduleQueueId, direction));
