import * as actionCreators from 'client/redux/accountChannel/actionCreators';
import * as constants from 'client/redux/accountChannel/constants';

export const scheduleCalendarIndex = channelId => dispatch => dispatch(actionCreators.scheduleCalendarIndex(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_INDEX, channelId));

export const scheduleCalendarCreate = (channelId, scheduleCalendarProps) => dispatch => dispatch(actionCreators.scheduleCalendarCreate(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_CREATE, channelId, scheduleCalendarProps));

export const scheduleCalendarUpdate = (channelId, channelScheduleCalendarId, scheduleCalendarProps) => dispatch => dispatch(actionCreators.scheduleCalendarUpdate(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_UPDATE, channelId, channelScheduleCalendarId, scheduleCalendarProps));

export const scheduleCalendarDelete = (channelId, channelScheduleCalendarId) => dispatch => dispatch(actionCreators.scheduleCalendarDelete(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_DELETE, channelId, channelScheduleCalendarId));
