import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import ViewPortProgramUserbox from 'client/components/ViewPortProgram/ViewPortProgramUserbox';
import _ from 'lodash';
import { getPersonPhotoUrl } from 'client/helpers/imageUrls';
import { transitionStatePropType } from 'client/helpers/propTypes';
import { UserNumber, UserState } from 'client/components/ViewPortProgram/ViewPortProgramHelpers';
import ViewPortProgramPersonfocus from 'client/components/ViewPortProgram/ViewPortProgramPersonfocus';
import { ComponentTransitionCycler } from 'client/components/Helpers';
import './ViewPortProgramStyles.css';

const LeaderboardTableList = ({
  className,
  valueUnit,
  numberOfPositions,
  table,
  positionStartsAt = 1,
}) => (
  <div className={className}>
    {_.zip(_.range(numberOfPositions), table).map(([index, item]) => (
      <div key={index} className="ltv_userboxrow">
        <UserState direction="stay" />
        <UserNumber position={index+positionStartsAt} />
        {item && <ViewPortProgramUserbox
          color={index+positionStartsAt <= 3 ? 'orange' : ''}
          displayName={item.name || item.personId}
          photoUrl={getPersonPhotoUrl(item.personPhoto)}
          value={item.value}
          valueUnit={valueUnit}
        />}
      </div>
    ))}
  </div>
);

export default class ViewPortProgramLeaderboard extends React.Component {

  static propTypes = {
    title: propTypes.string.isRequired,
    subTitle: propTypes.string.isRequired,
    valueUnit: propTypes.string.isRequired,
    numberOfPersons: propTypes.number.isRequired,
    table: propTypes.array.isRequired,
    transitionState: transitionStatePropType().isRequired,
    personFocus: propTypes.object,
  }

  render() {
    const {
      numberOfPersons,
      personFocus,
      subTitle,
      table,
      title,
      transitionState,
      valueUnit,
    } = this.props;
    const containerClasses = classNames('ltv_program', 'ltv_leaderboard', transitionState);
    const cycleNames = [
      numberOfPersons > 7 ? 'list' : null,
      personFocus ? 'personfocus' : null,
    ].filter(v => v);
    return (
      <div className={containerClasses}>
        <div className="ltv_caption_container">
          <div className="ltv_caption_up">
            {title}
          </div>
          {subTitle && <div className="ltv_caption">
            {subTitle}
          </div>}
        </div>
        <div className="ltv_twocolumns">
          <LeaderboardTableList
            valueUnit={valueUnit}
            className="ltv_twocolumns_column ltv_left"
            numberOfPositions={7}
            positionStartsAt={1}
            table={table.slice(0, 7)}
          />
          {cycleNames.length > 0 && (
            <ComponentTransitionCycler cycles={cycleNames} cycleTime={10000}>
              {({active, transitionState}) => (
                <div className={classNames('ltv_right ltv_twocolumns_column transition-container', transitionState)}>
                  {active === 'list' && (
                    <LeaderboardTableList
                      valueUnit={valueUnit}
                      numberOfPositions={7}
                      positionStartsAt={7}
                      table={table.slice(7, 14)}
                    />
                  )}
                  {active === 'personfocus' && (
                    <ViewPortProgramPersonfocus
                      {...personFocus}
                      transitionState="entered"
                      isPluginProgram
                    />
                  )}
                </div>
              )}
            </ComponentTransitionCycler>
          )}
        </div>
      </div>
    );
  }
}
