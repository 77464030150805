import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SigninPage as Page } from 'client/components/Pages';
import LoginForm from 'client/components/LoginPage/LoginForm';
import * as authActions from 'client/redux/auth/authActions';

class ConnectedLoginPage extends React.Component {

  handleLogin = (values, extra) => {
    const { setSubmitting } = extra;
    const { accountId, password } = values;
    setSubmitting(true);
    return this.props.authLogin(accountId, password).then(() => {
      setSubmitting(false);
    });
  }

  render() {
    const { loginError, isLoggedIn } = this.props;
    return (
      <Page>
        {isLoggedIn && <Redirect to="/" push />}
        <LoginForm
          onSubmit={this.handleLogin}
          formError={loginError}
        />
      </Page>
    );
  }

}

const mapStateToProps = state => ({
  ...state.auth,
});

export default connect(mapStateToProps, authActions)(ConnectedLoginPage);
