import React from 'react';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import Icon from 'client/components/Icon';
import { withRouter, matchPath } from 'react-router';
import { NavItem } from 'reactstrap';

class AppNavbarItem extends React.Component {

  static propTypes = {
    exact: propTypes.bool,
    location: propTypes.object.isRequired,
    icon: propTypes.string
  }

  render() {
    const { index, exact, to, children, icon, location, ...props } = this.props;
    const active = Boolean(matchPath(location.pathname, {path: to, exact}));
    delete props.staticContext;
    delete props.exact;
    return (
      <NavItem active={active}>
        <NavLink className="nav-link" exact={exact} to={to} {...props}>
          {icon && <Icon icon={icon} />}{' '}
          <span>{children}</span>
        </NavLink>
      </NavItem>
    )
  }

}

export default withRouter(AppNavbarItem);

