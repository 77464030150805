import { apiAction, appendQuery } from 'client/redux/apiHelpers';

export const list = (types) => apiAction({
  endpoint: '/api/account/channel',
  method: 'GET',
  types,
});

export const create = (types, channelProps) => apiAction({
    endpoint: '/api/account/channel',
    method: 'POST',
    json: channelProps,
    types: types.map(type => ({type, meta: {channelProps}})),
});

export const del = (types, channelId) => apiAction({
  endpoint: `/api/account/channel/${channelId}`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {channelId}})),
});

export const update = (types, channelId, channelUpdate) => apiAction({
  endpoint: `/api/account/channel/${channelId}`,
  method: 'PATCH',
  json: channelUpdate,
  types: types.map(type => ({type, meta: {channelId, channelUpdate}})),
});

export const read = (types, channelId) => {
  return apiAction({
    endpoint: `/api/account/channel/${channelId}`,
    method: 'GET',
    types: types.map(type => ({type, meta: {channelId}})),
  });
};

export const readState = (types, channelId) => {
  return apiAction({
    endpoint: `/api/account/channel/${channelId}/state`,
    method: 'GET',
    types: types.map(type => ({type, meta: {channelId}})),
  });
};

export const readSchedule = (types, channelId, readScheduleQuery) => {
  return apiAction({
    endpoint: appendQuery(`/api/account/channel/${channelId}/scheduleBetween`, readScheduleQuery),
    method: 'GET',
    types: types.map(type => ({type, meta: {channelId}})),
  });
};

export const scheduleQueueChannelIndex = (types, channelId) => apiAction({
  endpoint: `/api/account/channel/schedule/queue/channel/${channelId}`,
  method: 'GET',
  types: types.map(type => ({type, meta: {channelId}})),
});

export const scheduleQueueCalendarIndex = (types, channelScheduleCalendarId) => apiAction({
  endpoint: `/api/account/channel/schedule/queue/calendar/${channelScheduleCalendarId}`,
  method: 'GET',
  types: types.map(type => ({type, meta: {channelScheduleCalendarId}})),
});

export const scheduleQueueCreate = (types, scheduleQueueProps = {}) => apiAction({
  endpoint: `/api/account/channel/schedule/queue`,
  method: 'POST',
  json: scheduleQueueProps,
  types: types.map(type => ({type, meta: {}})),
});

export const scheduleQueueUpdate = (types, channelScheduleQueueId, scheduleQueueProps) => apiAction({
  endpoint: `/api/account/channel/schedule/queue/${channelScheduleQueueId}`,
  method: 'PATCH',
  json: scheduleQueueProps,
  types: types.map(type => ({type, meta: {channelScheduleQueueId}})),
});

export const scheduleQueueDelete = (types, channelScheduleQueueId) => apiAction({
  endpoint: `/api/account/channel/schedule/queue/${channelScheduleQueueId}`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {channelScheduleQueueId}})),
});

export const scheduleQueueMove = (types, channelScheduleQueueId, direction) => apiAction({
  endpoint: `/api/account/channel/schedule/queue/${channelScheduleQueueId}/order`,
  method: 'POST',
  json: {move: direction},
  types: types.map(type => ({type, meta: {channelScheduleQueueId}})),
});

export const scheduleCalendarIndex = (types, channelId) => apiAction({
  endpoint: `/api/account/channel/schedule/calendar/${channelId}`,
  method: 'GET',
  types: types.map(type => ({type, meta: {channelId}})),
});

export const scheduleCalendarCreate = (types, channelId, scheduleCalendarProps) => apiAction({
  endpoint: `/api/account/channel/schedule/calendar/${channelId}`,
  method: 'POST',
  json: scheduleCalendarProps,
  types: types.map(type => ({type, meta: {channelId}})),
});

export const scheduleCalendarUpdate = (types, channelId, channelScheduleCalendarId, scheduleCalendarProps) => apiAction({
  endpoint: `/api/account/channel/schedule/calendar/${channelId}/${channelScheduleCalendarId}`,
  method: 'PATCH',
  json: scheduleCalendarProps,
  types: types.map(type => ({type, meta: {channelId, channelScheduleCalendarId}})),
});

export const scheduleCalendarDelete = (types, channelId, channelScheduleCalendarId) => apiAction({
  endpoint: `/api/account/channel/schedule/calendar/${channelId}/${channelScheduleCalendarId}`,
  method: 'DELETE',
  types: types.map(type => ({type, meta: {channelId, channelScheduleCalendarId}})),
});
