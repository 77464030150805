import React from 'react';
import { SlimPageHeader, SlimPageTitle } from 'client/components/Slims';
import Helmet from 'react-helmet';
import PageBreadcrumbs from 'client/components/PageBreadcrumbs';
import propTypes from 'prop-types';

export default class PageHeader extends React.Component {

  static propTypes = {
    title: propTypes.string.isRequired,
  }

  render() {
    const { title } = this.props;
    return (
      <SlimPageHeader>
        <Helmet>
          <title>STV - {title}</title>
        </Helmet>
        <PageBreadcrumbs />
        <SlimPageTitle>{title}</SlimPageTitle>
      </SlimPageHeader>
    );
  }

}

