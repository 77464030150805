import * as constants from 'client/redux/accountChannel/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isReading: false,
  isDeleting: false,
  isCreating: false,
  error: null,
  scheduleCalendars: [],
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_INDEX, state, action, {
    requestProp: 'isReading',
    errorProp: 'error',
    successPickProps: ['scheduleCalendars'],
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_UPDATE, state, action, {
    requestProp: 'isUpdating',
    errorProp: null, // errors dealt with in modal form
    successPickProps: ['scheduleCalendars'],
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_DELETE, state, action, {
    requestProp: 'isDeleting',
    errorProp: 'error',
    successPickProps: ['scheduleCalendars'],
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_SCHEDULE_CALENDAR_CREATE, state, action, {
    requestProp: 'isCreating',
    errorProp: null, // errors dealt with in modal form
    successPickProps: ['scheduleCalendars'],
  });

  return state;
};
