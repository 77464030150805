import * as constants from 'client/redux/accountChannel/constants';
import { reduceByTypes } from 'client/redux/apiHelpers';

const defaultState = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  channels: null,
  pageError: null,
  modalError: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_INDEX_LIST, state, action, {
    requestProp: 'isLoading',
    errorProp: 'pageError',
    successPickProps: ['channels'],
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_INDEX_DELETE, state, action, {
    requestProp: 'isDeleting',
    errorProp: 'modalError',
    successProp: null,
  });

  state = reduceByTypes(constants.ACCOUNT_CHANNEL_INDEX_CREATE, state, action, {
    requestProp: 'isCreating',
    errorProp: null, // handled by the creation modal
    successProp: null,
  });

  return state;
};
