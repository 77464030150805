import React from 'react';
import { SlimFooter } from 'client/components/Slims';
import Copyright from 'client/components/Branding/Copyright';

export default class AppFooter extends React.Component {
  render() {
    return (
      <SlimFooter>
        <div className="container">
          <Copyright />
        </div>
      </SlimFooter>
    );
  }
}

