import { AUTH_LOGIN, AUTH_LOGOUT } from 'client/redux/auth/authActions';
import { reduceByTypes, responseToErrorMessage } from 'client/redux/apiHelpers';

const defaultState = {
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  self: null,
  loginError: null,
  logoutError: null,
};

export default function(state = defaultState, action) {

  state = reduceByTypes(AUTH_LOGIN, state, action, {
    requestProp: 'isLoggingIn',
    errorProp: (state, action, errorResponse) => ({
      ...state,
      loginError: responseToErrorMessage(errorResponse),
    }),
    successProp: (state, action, successResponse) => ({
      ...state,
      isLoggingIn: false,
      isLoggedIn: true,
      loginError: null,
      self: successResponse.self,
    }),
  });

  state = reduceByTypes(AUTH_LOGOUT, state, action, {
    requestProp: 'isLoggingOut',
    errorProp: (state, action, errorResponse) => ({
      ...state,
      logoutError: responseToErrorMessage(errorResponse),
    }),
    successProp: (state, action, successResponse) => ({
      ...state,
      isLoggingOut: false,
      isLoggedIn: false,
      logoutError: null,
      self: null,
    }),
  });

  switch(action.type) {
    default: return state;
    case 'AUTH_ERROR': return {
      ...state,
      isLoggedIn: false,
    };
  }
}
