import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { Formik } from 'formik';
import { Alert } from 'reactstrap';
import Logo from 'client/components/Branding/Logo';
import propTypes from 'prop-types';
import { ButtonSpinner } from 'client/components/Buttons';
import ConnectedFlashAlert from 'client/components/App/ConnectedFlashAlert';

export default class LoginForm extends React.Component {

  static propTypes = {
    onSubmit: propTypes.func.isRequired,
    formError: propTypes.string,
  }

  render() {
    const { onSubmit, formError } = this.props;
    const initialValues = {accountId: '', password: ''};
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({handleSubmit, values, errors, isSubmitting, handleChange, handleBlur}) => (
          <form className="signin-box" onSubmit={handleSubmit}>
            <Logo />
            <ConnectedFlashAlert />
            <h3 className="signin-title-secondary">Sign in to continue.</h3>
            <FormGroup>
              <Input
                type="text"
                name="accountId"
                values={values.accountId}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your account id"
              />
            </FormGroup>
            <FormGroup className="mg-b-50">
              <Input
                type="password"
                name="password"
                values={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your password"
              />
            </FormGroup>
            <ButtonSpinner
              spinning={isSubmitting}
              className="btn btn-primary btn-block btn-signin"
              type="submit"
            >
              Sign in
            </ButtonSpinner>
            {formError && (<Alert color="danger">{formError}</Alert>)}
          </form>
        )}
      </Formik>
    );
  }

}
