import React from 'react';
import ReactDOM from 'react-dom';
import RouterRoot from 'client/components/Router/RouterRoot';
import { Provider } from 'react-redux';
import configureStore, { history } from 'client/redux/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/es/integration/react'
import { ConnectedSettingsContextProvider } from 'client/components/AccountSettings/SettingsContext';
import registerServiceWorker from './registerServiceWorker';

const { persistor, store } = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<span>loading</span>}>
      <ConnectedSettingsContextProvider>
        <ConnectedRouter history={history}>
          <RouterRoot />
        </ConnectedRouter>
      </ConnectedSettingsContextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
