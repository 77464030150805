import React from 'react';
import propTypes from 'prop-types';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { SaveButton } from 'client/components/Buttons';
import { handleSubmitPromise } from 'client/helpers/formik';
import {
  FormGroup,
  Input ,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Formik, Form } from 'formik';

export default class ProgramNewModal extends React.Component {

  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
  }

  handleSubmit = (values, formikProps) => {
    handleSubmitPromise(this.props.onSubmit(values), formikProps).then(() => {
      this.props.toggle();
    }).catch(err => {
      formikProps.setStatus(err);
    });
  }

  render() {
    const { isOpen, toggle } = this.props;
    const initialValues = {title: '', type: ''};
    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{width: '500px'}}>
        <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
          {({handleBlur, handleChange, values, isSubmitting, isValid, status}) => (
            <Form>
              <ModalHeader toggle={toggle}>New Program</ModalHeader>
              <ModalBody>
                {status instanceof Error && <ErrorAlert error={status} />}
                <FormGroup>
                  <Label for="programNewTitle">Program Title</Label>
                  <Input
                    type="text"
                    name="title"
                    id="programNewTitle"
                    value={values.title || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    max="255"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="programNewType">Program Type</Label>
                  <Input
                    type="select"
                    name="type"
                    id="programNewType"
                    value={values.type || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Select"
                    required
                  >
                    <option value="" disabled>Select a type</option>
                    <option value="gallery">Gallery</option>
                    <option value="leaderboard">Leaderboard</option>
                    <option value="logo">Logo</option>
                    <option value="personfocus">Personfocus</option>
                    <option value="qualificationboard">Qualificationboard</option>
                    <option value="sumboard">Sumboard</option>
                  </Input>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <SaveButton
                  isSaving={isSubmitting}
                  type="submit"
                  disabled={!isValid}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }

}
