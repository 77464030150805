import React from 'react';
import propTypes from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';

export default class ProgramForm extends React.Component {

  static propTypes = {
    formikProps: propTypes.object.isRequired,
  }

  render() {
    const { formikProps } = this.props;
    const { values:program, handleChange, handleBlur } = formikProps;
    return (
      <div className="py-1">
        <FormGroup row className="mb-0">
          <Label sm={2} for="title">Title</Label>
          <Col sm={10}>
            <Input
              type="text"
              min="1"
              max="255"
              required
              name="title"
              id="title"
              value={program.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }

}
