import { getApiActionTypes } from 'client/redux/apiHelpers';
import { flash } from 'client/redux/flashAlert/actions';
import { login, logout } from 'client/redux/auth/actionCreators';

export const AUTH_LOGIN = getApiActionTypes('AUTH_LOGIN');

export const authLogin = (id, password) => dispatch => dispatch(login(AUTH_LOGIN, {id, password}));

export const AUTH_LOGOUT = getApiActionTypes('AUTH_LOGOUT');

export const authLogout = () => dispatch => dispatch(logout(AUTH_LOGOUT)).then(() => {
  return flash({
    alertType: 'info',
    msg: 'You are now logged out'
  })(dispatch);
});
