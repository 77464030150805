/**
 * @desc Returns a function that submits formik data to a function yielding a promise
 * @param {Promise} promise
 * @param {Object} formikProps
 * @return {Promise}
 */
export const handleSubmitPromise = (promise, formikProps) => {
  const { setSubmitting } = formikProps;
  setSubmitting(true);

  if(!(promise instanceof Promise)) {
    promise = Promise.resolve(promise);
  }

  return promise.then(value => {
    setSubmitting(false);
    return value;
  }).catch(err => {
    setSubmitting(false);
    return Promise.reject(err);
  });
};
