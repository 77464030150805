import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { tvOutputAuthClearPassword } from 'client/redux/tvOutput/tvOutputAuthActions';
import './ConnectedViewPortLogoutButton.css';

class ConnectedViewPortLogoutButton extends React.Component {

  handleLogout = () => {
    return this.props.tvOutputAuthClearPassword();
  }

  render() {
    return (
      <div className="viewport-logout">
        <Button
          onClick={this.handleLogout}
          type="button"
          className="btn btn-primary rounded"
          size="sm"
        >
          X
        </Button>
      </div>
    );
  }

}

const actions = {
  tvOutputAuthClearPassword,
};

export default connect(null, actions)(ConnectedViewPortLogoutButton);
