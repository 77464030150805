import React from 'react';
import propTypes from 'prop-types';
import { ErrorAlert } from 'client/components/ErrorHelpers';
import { SaveButton } from 'client/components/Buttons';
import { handleSubmitPromise } from 'client/helpers/formik';
import { rejectOnActionError } from 'client/redux/apiHelpers';
import {
  FormGroup,
  Input ,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Formik, Form } from 'formik';

export default class ChannelCardNewModal extends React.Component {

  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
  }

  handleSubmit = (values, formikProps) => {
    handleSubmitPromise(this.props.onSubmit(values), formikProps)
      .then(rejectOnActionError)
      .then(() => {
        this.props.toggle();
      }).catch(err => {
        formikProps.setStatus(err);
      });
  }

  renderForm() {
    const { toggle } = this.props;
    const initialValues = {name: ''};
    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({handleBlur, handleChange, values, isSubmitting, isValid, status}) => (
          <Form>
            <ModalHeader toggle={toggle}>New Channel</ModalHeader>
            <ModalBody>
              {status instanceof Error && <ErrorAlert error={status} />}
              <FormGroup>
                <Label for="channelNewName">Channel Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="channelNewName"
                  value={values.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  max="255"
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <SaveButton
                isSaving={isSubmitting}
                type="submit"
                disabled={!isValid}
              />
            </ModalFooter>
          </Form>
        )}
    </Formik>
    );
  }

  render() {
    const { isOpen, toggle } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} style={{width: '500px'}}>
        {isOpen && this.renderForm()}
      </Modal>
    );
  }

}
