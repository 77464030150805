import { combineReducers } from 'redux';
import accountChannel from 'client/redux/accountChannel/rootReducer';
import accountData from 'client/redux/accountData/rootReducer';
import accountProgram from 'client/redux/accountProgram/rootReducer';
import accountSettings from 'client/redux/accountSettings/rootReducer';
import auth from 'client/redux/auth/authReducer';
import flashAlert from 'client/redux/flashAlert/reducer';
import tvOutput from 'client/redux/tvOutput/tvOutputReducer';
import tvOutputAuth from 'client/redux/tvOutput/tvOutputAuthReducer';
import viewport from 'client/redux/viewport/viewportReducer';
import websocket from 'client/redux/websocket/websocketReducer';

const appReducer = combineReducers({
  accountChannel,
  accountData,
  accountProgram,
  accountSettings,
  auth,
  flashAlert,
  tvOutput,
  tvOutputAuth,
  viewport,
  websocket,
});

export default appReducer;
