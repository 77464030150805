import React from 'react';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import { ButtonIconRound } from 'client/components/Buttons';
import { ConfirmActionModal } from 'client/components/Modals';
import './ChannelCard.css';

export default class ChannelCard extends React.Component {

  static propTypes = {
    onDelete: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
    id: propTypes.string.isRequired,
  }

  state = {
    closeModalIsOpen: false,
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  handleToggleCloseModal = () => {
    const { closeModalIsOpen } = this.state;
    if(!this.willUnmount) {
      this.setState({closeModalIsOpen: !closeModalIsOpen});
    }
  }

  handleDelete = () => {
    return this.props.onDelete(this.props.id);
  }

  render() {
    const { name, id } = this.props;
    const { closeModalIsOpen } = this.state;
    return (
      <Card className="channel-card w-100">
        <CardHeader>
          <ConfirmActionModal
            isOpen={closeModalIsOpen}
            toggle={this.handleToggleCloseModal}
            onConfirm={this.handleDelete}
          />
          <ButtonIconRound
            className="channel-card-delete-btn"
            color="danger"
            icon="ion-ios-trash"
            onClick={this.handleToggleCloseModal}
          />
          <CardTitle className="mb-0">
            <NavLink to={`/channels/${id}/repeat`} exact>{name}</NavLink>
          </CardTitle>
        </CardHeader>
      </Card>
    );
  }

}
