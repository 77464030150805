import React from 'react';
import { Link } from 'react-router-dom';
import { SlimLogo } from 'client/components/Slims';

export default class Logo extends React.Component {
  render() {
    return (
      <SlimLogo>
        <Link to="/">STV</Link>
      </SlimLogo>
    );
  }
}
